import { useState } from "react";
import { Apipost } from "../../helpers/apiCall";
import { errorMessageNodify, successMessageNodify } from "../../helpers/notify";

function InputBox({initialValue , id , endPoint , keyValue , values, setValues ,getKeyByValue, setKeyByValue}) {
    const [value, setValue] = useState(initialValue);

    const updateState = (data, id, value1,  key1) => {
        const newState = data.map(obj => {
          if (obj[getKeyByValue] === id) {
            return { ...obj, [key1]: value1};
          }
          return obj;
        });
        setValues(newState);
      }

const handleUpdate = ()=>{
    if(!(initialValue.length == value.length) ){
        Apipost(endPoint, { id ,[keyValue]:value  }, 'application/x-www-form-urlencoded', true)
        .then((success) => {
            successMessageNodify(success.data.msg)
            updateState(values ,id, value, setKeyByValue )
        })
        .catch((err) => {
            errorMessageNodify(err.message)
            console.error("Error updating payment:", err);
        });
    }
    
}

    return ( <input value={value}  onChange={e=>setValue(e.target.value)}   className="text-center"  onBlur={()=>handleUpdate()}  /> );
}

export default InputBox;