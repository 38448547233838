import { useDispatch } from "react-redux";
import RoutesComponent from "./routes";
import { ToastContainer } from "react-toastify";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "@ckbox/components/dist/styles/ckbox.css";


function App() {
  return (
    <div className="App">
        <RoutesComponent />
        <ToastContainer />
    </div>
  );
}

export default App;
