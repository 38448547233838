
import React, { useState, useEffect } from "react";
import flag1 from '../../../assets/images/no-image.png'
import flag2 from '../../../assets/images/no-image.png'
import editw from '../../../assets/images/edit-white.png'
import CalendarImage from '../../../assets/images/calendar_icon.png';
import {NavLink} from 'react-router-dom';
import { ApiGetAuth } from "../../../helpers/apiCall";
import { AddScore } from "./CreateMatch";
import {Container, Col, Image, Form} from 'react-bootstrap';
import CustomModal from "../../../components/customModal";
import moment from "moment";
import Cardloader from "../../Mainloader.js/Cardloader";
import { Row } from "react-bootstrap";

import '../../../assets/css/dashboardStructure.css';
import { getNeighbors } from "./Matches";
import { convertTo12HourFormat, generateImage } from "../../../helpers/helperfunctions";
import NoData from '../../../components/NoData';


export const Card = ({ week, start, active,t,slider, end, onClick ,setselectedDate}) => {

	useEffect(()=>{
		if(active){
			setselectedDate(t)
		}
	},[slider])

	return (
		<li className={`border text-center book ${active ? 'active' : ''}`} onClick={onClick}
			style={{ cursor: 'pointer' }} >
			<span >{week}</span>
			<p >{`${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`}</p>
		</li>
	);
};


function Score({ groupid }) {
	const [moveClass, setMoveClass] = useState('');
	const [carouselItems, setCarouselItems] = useState([]);
	const [slider, setSlider] = useState(0)
	const [data, setData] = useState(null);
	const [showStatus, setShowStatus] = useState(false)
	const [componentToRender, setComponentToRender] = useState(<></>)
	const [seletedDate, setselectedDate] = useState('')

	const [refresh, setRefresh] = useState(false)
	const [roundsData , setRoundsData] = useState([])


	useEffect(() => {
		ApiGetAuth(`games/get-Weeks/${groupid}`).then((data) => {
			if (data?.data) {
				let weeks = data.data
				setCarouselItems(weeks)
				const currentDate = moment(); // Current date
				let selectedWeek = '';
				let index = 0
				for (const week of weeks) {
					const weekStartDate = moment(week.start, 'll');
					const weekEndDate = moment(week.end, 'll');
					if (currentDate.isBetween(weekStartDate, weekEndDate, null, '[]')) {
						selectedWeek = index;
						break;
					}
					index++;
				}

				// setBracketData({ rounds: formatMatchesForBrackets(data?.data?.rows) });
				if(weeks.length > 7)setSlider(selectedWeek >0 ? selectedWeek : 0)
		        	setselectedDate(weeks[selectedWeek > 0? selectedWeek : 0])
			}
		}, (err) => {
			console.log(err);
		})
	}, [])


	useEffect(() => {
		ApiGetAuth(`games/get-Matches/${groupid}`).then(
			(data) => {
				setData(data?.data?.rows)
			},
			(err) => {
				console.log(err);
			}
		);
	}, [refresh])

	const handleHide = () => {
		setShowStatus(false)
	}
	const handleShow = (value) => {
		setComponentToRender(<AddScore groupid={groupid} value={value} data={data} setData={setData} handleHide={handleHide} setRefresh={setRefresh} />);
		setShowStatus(true)
	}
	return (
		<>
			<CustomModal showModal={showStatus} onClose={handleHide} componentToRender={componentToRender} />
			<div className="game_module_section">
				<Container fluid>
					<Row>
						<Col xxl="12" md="12" xs="12">
							<div className="top_buttons_dropdown">
								<div className="time_schedule-2">
									<Form.Select aria-label="Default select example">
										<option>Team Schedule</option>
										<option value="1">One</option>
										<option value="2">Two</option>
										<option value="3">Three</option>
									</Form.Select>
								</div>
								<div className="all_round">
									<Form.Select aria-label="Default select example">
									<option>All  Rounds</option>
									{roundsData?.map(item=><option>{item.round_name}</option>
									)}
									</Form.Select>
								</div>
							</div>
						</Col>	
					</Row>
					<Row>
						<Col xxl="12" md="12" xs="12">
							<div className="background_body_table">
								<div className="team_list_section">
									<Row>
										<Col xxl="11" md="12" xs="12">
											<div className="calender-daily">
												<div className="ui">
												<button onClick={() => setMoveClass('next')} className="prev">
															<span className="material-icons prev" onClick={()=>{ setSlider(prev=>prev >0 ? prev-1:carouselItems?.length) }}>&lt;</span>
														</button>
														<button onClick={() => setMoveClass('prev')} className="next">
															<span className="material-icons next" onClick={()=>{setSlider(prev=>prev+1)}}>&gt;</span>
														</button>
												</div>
												<div className="bg-white calender-wraps">
													<ul className='d-flex mb-0 book-list' id="book-list" style={{ "list-style-type": "none" }}>
														{getNeighbors(carouselItems, slider)?.map((t, index) =>{
															return <Card  t={t}  key={index} week={t.Week} start={t.start} end={t.end} active={seletedDate.Week == t.Week} setselectedDate={setselectedDate}   onClick={() => {
																setselectedDate(t);
															}} />
														}
														)}
													</ul>
												</div>
											</div>
										</Col>	

										<Col xxl="1" md="12" xs="12">
											<div className="main-menu calendar_icon matches_calendar_main_menu newcss_matches">
											<Col xxl="1" md="12" xs="12">
												<div className="main-menu calendar_icon matches_calendar_main_menu">
													<div className="dropdown">
														<a id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
															<Image className="img-fluid" src={CalendarImage} alt="Calendar Icon"/>
														</a>	
														<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
															<li>
																<NavLink to="#">
																	<input className="form-control"   name="year" value={ moment(  carouselItems[0]?.start).format('YYYY')} disabled />
																</NavLink>
															</li>
															{carouselItems?.map((item, index)=>{
																return <li>
																<div className={`dates_listing_create_game ${seletedDate.Week === item.Week ?'active':""}`}>
																	<NavLink onClick={()=>{setselectedDate(item);setSlider(index)}} className={`${seletedDate.Week === item.Week ?'fw-bold':""}`}>
																		<span>{item.Week}</span>
																		<span>{`${moment(item.start).format('MMM D')} - ${moment(item.end).format('MMM D')}`}</span>
																	</NavLink>
																</div>
															</li>
																 
															})}
															<NavLink to="#" className="modal_cancel">Cancel</NavLink>
														</ul>
													</div>	
												</div>
											</Col>
											</div>
										</Col>	
									</Row>	
									<Row>
										<Col xxl="12" md="12" xs="12">
											<div className='match-data mt-4'>
												<div className="team-match" >
													<h4>Match Up</h4>
												</div>
												<div className='table-responsive score-tab'>
												{data == null ? <Cardloader className="score-load" /> :<>
													{
														data.length > 0 && data.filter(item=> (moment(item.date, 'dddd, MMMM Do YYYY').utc().isBetween( moment(seletedDate.start, 'MMM D, YYYY').utc(), moment(seletedDate.end, 'MMM D, YYYY').utc(), null, '[]')) ).length   ?<>	<table className="table standing-table table-striped  score-past">
														<thead>
															<tr>
																<th scope="col">Team A</th>
																<th scope="col" className="text-center">Matches</th>
																<th scope="col">Team B</th>
																<th scope="col">Time</th>
																<th scope="col">Venue </th>
																<th scope="col">Field </th>
																<th scope="col">Location</th>
																<th scope="col">Actions</th>
															</tr>
														</thead>
														<tbody>
														
																{(data)?.map((item) => {
																	const startDate = moment(seletedDate.start, 'MMM D, YYYY').utc();
																	const endDate = moment(seletedDate.end, 'MMM D, YYYY').utc();
																	const itemDate = moment(item.date, 'dddd, MMMM Do YYYY').utc();
																	return (itemDate.isBetween(startDate, endDate, null, '[]')) ? <>
																		{item.matches.map((item) => <tr>
																			<td scope="row"><div className="flag-img d-flex score-final align-items-start"> <img src={item.Team1.team_logo || generateImage(item?.Team1?.team_name)} /><div>
																				<div className="d-flex"><p className="mb-1">{item.Team1.team_name}{(item.match_status_id == 21 || item.match_status_id == 22) ? <></> : (item.score_two == item.score_one) ? <strong>Draw</strong> : (item.score_one > item.score_two ? <strong>Winner</strong> : <></>)}<span className="score-pt"> {item.score_one}</span> </p>

																				</div>
																				{/* <span>{item.score_one} / {item.score_two}</span> */}
																			</div>
																			</div></td>
																			<td scope="col" className="text-center"><strong>Vs</strong></td>

																			<td scope="row"> <div className="flag-img d-flex score-final align-items-start"><img src={item.Team2.team_logo || generateImage(item.Team2.team_name)} /><div>
																				
																				<p className="mb-1">{item.Team2.team_name}   {(item.match_status_id == 21 || item.match_status_id == 22) ? <></> : (item.score_two == item.score_one) ? <strong>Draw</strong> : (item.score_one < item.score_two ? <strong>Winner</strong> : <></>)}<span className="score-pt">  {item.score_two}</span></p>

																				{/* <span>{item.score_one} / {item.score_two}</span> */}
																			</div></div></td>
																			<td>{convertTo12HourFormat(item.venue_field_timeslot)}</td>
																			<td scope="col">{item.venue_name}</td>
																			<td scope="col">{item.venue_field}</td>
																			<td>{item.address}</td>
																			<td>
																				{/* <button type="button" className="btn ss btn-outline-primary" onClick={() => handleShow(item)}> <img src={editw} /> Update Score</button> */}
																				<button type="button" className="btn ss btn-outline-primary" onClick={() => handleShow(item)}>  Update Score</button>
																			</td>
																		</tr>)}
																	</> : <tr></tr>

																})}
															
														</tbody>
													</table></>:<>

													<div className="No-game"> <NoData message='No Games Available Score For the Selected Week ! ' helpText='As soon as you  new games would be added  it would populate on the current Week.' /></div>

													</> 
													}
												</>
															}
												
	
											
												</div>

												
											</div>
										</Col>
									</Row>
								</div>	
							</div>	
						</Col>	
					</Row>	
				</Container>	
			</div>
		</>
	)
}

export { Score }