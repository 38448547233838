import React, { useState, useLayoutEffect } from 'react';
import Loader from './Loader';

// Higher-order component to handle loading state for each page
const withLoader = (WrappedComponent) => {
  return (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useLayoutEffect(() => {
 setIsLoading(false);
    }, []);

    return (
      <>
        {isLoading ? <Loader /> : <WrappedComponent {...props} />}
      </>
    );
  };
};

export default withLoader;