import { Image } from "react-bootstrap";
import './bracket.css'
import { ArcherContainer, ArcherElement } from 'react-archer';
import { convertTo12HourFormat, generateImage } from "../../helpers/helperfunctions";
import CustomModal from "../customModal";
import { useState } from "react";
import { AddScore } from "../../pages/Organizer/Team/CreateMatch";
import { useParams } from "react-router";
import NoData from "../NoData";
import InputBox from "../InputBox/InputBox";
function CustomBracket({rounds=[],setRefresh , data , setData , standingsTeams , setRounds}) {
  const { groupId } = useParams();
	const [showStatus, setShowStatus] = useState(false)
  const [value , setValue] = useState({})
  const handleHide = () => {
		setShowStatus(false)
	}

  const findSeedNum = (data, id) => {
    const team = data.find(item => item.team_id === id);
    if (team) {
        const seedNum = data.findIndex(item => item.team_id === id) + 1;
        return seedNum;
    } else {
        return -1; // Team not found
    }
}


const Round = ({ children }) => <div className="se__round">{children}</div>;

const Placer = ({ children }) => <div className="se__placer">{children}</div>;

const Icon = ({match_type})=> {
if(match_type === 'finalMatch')return <img src={"https://cdn.icon-icons.com/icons2/2055/PNG/512/trophy_icon_124465.png"} width={50}  />
else if(match_type === 'SemiFinal')return <img src={"https://cdn.icon-icons.com/icons2/2055/PNG/512/cheerleader_icon_124463.png"} width={50}  />
else return <></>
}

const CustomDisplay = ({ children , style }) => (
    <div className='se__custom' style={style}>{children}</div>
  );
  

    const Bracket = ({ children, id, to, style }) => {
        return <div className="se__bracket">
          <ArcherElement
            id={id}
            relations={
              to
                ? [
                    {
                targetId: to,
                targetAnchor: "left",
                sourceAnchor: "right",
                style
                    }
                  ]
                : []
            }
          >
            {children}
          </ArcherElement>
        </div>
      };

      const winnerStyle = {
        arrowLength: 2,
        arrowThickness: 0,
        strokeColor: "orange",
        strokeWidth: 1
      };
      const handleShow = (matchid) => {
        if(matchid){
          setShowStatus(true)
          const matchitem =data.filter(item=>item.id == matchid) 
          setValue(matchitem[0])
          setRefresh(prev=>!prev)
        }
        }
    //silver and green  Card style
     const stylesObj =[{ background:'#f1f1f1',color:'#00000091' ,borderColor:"#dee2e6"}, {background:'#dbfae6',color:'#09904f' ,borderColor:'#3ecc80'} , {background:'#f0582924',color:'#d11b0a' }]   

    return ( <>
 
{
  rounds.length ?<>
 <div className="scroll mt-3">
 <div className="tour__wrap">
    <ArcherContainer offset={8}>
      <div className="tour__wrap">
      {rounds?.map(round=>{
        return <Round> <h3 className="text-center"><InputBox initialValue={round.title}  id={round.round_id} endPoint='/games/update-round-for-Grade' keyValue='round_name'  values={rounds} setValues={setRounds} getKeyByValue="round_id"  setKeyByValue="title"  /></h3>
        {
      round?.seeds?.map(((item, index)=>{
       return <Placer>
            <Bracket id={item.matchid} to={item.match_id_be_decided} style={winnerStyle}>
            <div onClick={()=>item.venue_name?handleShow(item.matchid):()=>{}}>
            <div className="d-flex align-items-center justify-content-center">
                    <h5>Game {item.id} <Icon match_type={item.match_type} />  </h5>
                    </div>
                 <CustomDisplay style={item.teams[1].score ||item.teams[0].score >0 ?stylesObj[1] : ( item.teams[0].id && item.teams[1].id ? stylesObj[2] : stylesObj[0] )} ><div className="bracket-card-view"  >
                  <div className="imge_with_team_name">
                  <div className="bracket-details">
                   
                  <Image className="img-fluid" src={ item.teams[0].team_logo ||  generateImage(item.teams[0].name || 'TBD')} />
                  <h6 className='text text-primary'><span className="text text-secondary">{ findSeedNum(standingsTeams,item.teams[0].id) > 0 ?  findSeedNum(standingsTeams,item.teams[0].id):''}</span> {item.teams[0].name|| 'TBD'}
                  {Number(item.teams[0].id) == Number(item.winner) ? <img src='https://cdn.icon-icons.com/icons2/412/PNG/128/Crown_40997.png' width={20} /> :<></> }  
                  </h6>
                   <div className="d-flex align-items-center">
                  <span className="numbering_under_bracket text text-primary">{item.teams[0].score || 0}</span></div>
                  </div>
                  
                  <div className="bracket-details mt-2">
                  <Image className="img-fluid" src={item.teams[1].team_logo ||   generateImage(item.teams[1].name || 'TBD')} />
                  <h6 className='text text-primary'><span className="text text-secondary">{ findSeedNum(standingsTeams,item.teams[1].id) > 0 ?  findSeedNum(standingsTeams,item.teams[1].id):''}</span> {item.teams[1].name|| 'TBD'}
                  {Number(item.teams[1].id) == Number(item.winner) ? <img src='https://cdn.icon-icons.com/icons2/412/PNG/128/Crown_40997.png' width={20} /> :<></> } 
                  </h6>
                            <div className="d-flex align-items-center">
                              <span className="numbering_under_bracket text text-primary">{item.teams[1].score || 0}</span></div>
                  </div>
                  
                  <hr className="border-dashed border-2 border-primary"></hr>
                  <div className="games-bracket-details">
                   <div className="data-dsispaly">
                   {
                    item.venue_name ? <>
                    <div className="d-flex align-items-center justify-content-between">
                    <p> {item.date}</p>
                    <p>{ convertTo12HourFormat(item.venue_field_timeslot)}</p>
                    </div>
                    <div className="vennu-filed">
                      <div><span> {item.venue_name}</span></div>
                      <span> {item.venue_field}</span>
                    </div>
                    </>:<>
                    <div className="flex align-items-center justify-content-center">
                    
                    <p className="todecited"> TBD</p>
                    </div>

                    </>
                   }
                   </div>
</div>
</div>
    </div></CustomDisplay>

             </div>
            </Bracket>
          </Placer>
          
          
          }))}
          
        </Round>
      })}
      </div>
    </ArcherContainer>

  </div>
 </div>
  </>:<>
  <div className="No-game"> <NoData message='No Brackets Found  ! ' helpText='  No Games Generated  Yet Please click Above Generate Brackets to  Generate Bracket Games ' /></div>
  </>
}
    <CustomModal showModal={showStatus} onClose={handleHide} componentToRender={<AddScore groupid={groupId} value={value} data={data} setData={setData} handleHide={handleHide} setRefresh={setRefresh} />} />
  </> );
}

export default CustomBracket;