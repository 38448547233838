import { GET_COACH_DETAILS, GET_PLAYER_DETAILS, GET_REFEREE_DETAILS, GET_TEAM_DETAILS, GET_VENUE_DETAILS, GET_SPORTS_DETAILS, GET_GRADE_LIST, GET_AGEGROUP_LIST, GET_GAME_LIST, ISAUTHENTICATED, GET_PROFILE_DETAILS, PAYMENTDETAIL, LOGOUT } from "../actions/actionTypes";

const initialState = {
  PLAYERS: [],
  COACHES: [],
  VENUES: [],
  TEAMS: [],
  REFEREE: [],
  SPORTSLIST: [],
  GRADELIST:[],
  AGEGROUPLIST:[],
  GAMELIST: [],
  PROFILEDETAILS:[],
  PAYMENTDETAIL:[],
  isAuthenticated :false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        PROFILEDETAILS: action.payload
      };
      case LOGOUT:
           return initialState;
    case GET_PLAYER_DETAILS:
      return {
        ...state,
        PLAYERS: action.payload
      };
    case GET_COACH_DETAILS:
      return {
        ...state,
        COACHES: action.payload
      };
    case GET_TEAM_DETAILS:
      return {
        ...state,
        TEAMS: action.payload
      };
    case GET_REFEREE_DETAILS:
      return {
        ...state,
        REFEREE: action.payload
      };
    case GET_VENUE_DETAILS:
      return {
        ...state,
        VENUES: action.payload
      };
    case GET_SPORTS_DETAILS:
      return {
        ...state,
        SPORTSLIST: action.payload
    };
    case GET_GRADE_LIST:
      return {
        ...state,
        GRADELIST: action.payload
    };
    case GET_AGEGROUP_LIST:
      return {
        ...state,
        AGEGROUPLIST: action.payload
    };
    case GET_GAME_LIST:
      return {
        ...state,
        GAMELIST: action.payload
    };
    case ISAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
    };

    case PAYMENTDETAIL:
      return{
        ...state,
        PAYMENTDETAIL:action.payload
      }

    default:
      return state;
  }
};

export { reducer }