import { useEffect, useState } from "react";

const InputList = ({ inputList, setInputList, title }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (inputList.length >= 0) {
      inputList[inputList.length - 1]?.input === ""
        ? setIsDisabled(true)
        : setIsDisabled(false);
    }
  }, inputList);

  const handleListAdd = () => {
    setInputList([
      ...inputList,
      {
        fieldname: "",
      }
    ]);
  };
  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const newInputList = [...inputList];
    newInputList[index].fieldname = value;
    setInputList(newInputList);
  };

  const handleRemoveItem = (index) => {
    const newList = [...inputList];
    newList.splice(index, 1);
    setInputList(newList);
  };

  return (
    <>
    
      {inputList.length > 0
        ? inputList?.map((input, index) => (
          <div key={index} className="Inputlist_div">
            <input
              id="outlined-basic"
              label={`input ${index + 1}`}
              value={input.fieldname}
              placeholder="Add Field"
              variant="outlined"
              onChange={(event) => handleInputChange(event, index)}
            />
            <button
              className="cross-btn"
              onClick={() => handleRemoveItem(index)}
            >
              <span role="img" aria-label="x emoji">
                -
              </span>
            </button>
          </div>
        ))
        : <span className="Emptyinput"></span>}
        <br/>
        <button
        className="addfield-btn"
        onClick={handleListAdd}
        disabled={isDisabled}
      >
        +
      </button>
    </>
  );
};

export default InputList;
