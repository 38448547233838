import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup';
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import {
    errorMessageNodify,
    successMessageNodify,
} from "../../../helpers/notify";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { State, City } from "country-state-city";
import Select from "react-select";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const validationSchema = Yup.object().shape({
    game_title: Yup.string().required("Please Enter League Name").max(30, 'League name cannot be longer than 30 characters'),
    game_bracket_id: Yup.number().required("Please Enter League  Type"),
    selected_State: Yup.object().required("Please Enter State"),
    selected_City: Yup.object().required("Please Enter City"),
    About: Yup.string(),
  });
  

const CreateLeagueNew = ({ closeModal, tournament_id = 0, getGames = () => { }, sports = '' }) => {
  const { sportId, type } = useParams();
    const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      id: 0,
      game_title: "",
      game_bracket_id: 0,
      robin_number: 0,
      selected_State: null,
      selected_City: null,
      About: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = () => {
    const payload = {
      sportId: sportId || sports,
      game_type_id: 2,
      game_title: formik.values.game_title,
      selected_State: JSON.stringify(formik.values.selected_State),
      selected_City: JSON.stringify(formik.values.selected_City),
      About: formik.values.About ||'',
      id:tournament_id ||0
    };

    const endpoint = tournament_id ? "/games/update-game" : "/games/add-game";

    formik.setSubmitting(true);

    Apipost(endpoint, payload, "application/x-www-form-urlencoded", true).then(
      (response) => {
        const result = response.data;
        if (result.status === true) {
           if(tournament_id == 0){
            navigate(`/organizer/${sportId}/events/${type}/${result.data.id}`)
           }
          successMessageNodify(result.msg);
          closeModal(false);
          getGames();
        } else {
          errorMessageNodify(result.msg);
        }
        formik.setSubmitting(false);
      }
    );
  };



  useEffect(() => {
    if (tournament_id) {
      ApiGetAuth(`games/game-details/${tournament_id}`).then((data) => {
        if (data.data) {
          if (data.data.About === "") data.data.About = "<p></p>";
          formik.setValues({
            ...data.data,
            selected_State: data.data.selected_State,
            selected_City: data.data.selected_City
          });
          if (data.data.game_bracket_id === 3) {
            formik.setFieldValue("robin_number", data.data.robin_number);
          }
        }
      });
    } else {
      formik.setValues({
        ...formik.values,
        sportId: sportId || sports,
        game_type_id: 1,
      });
    }
  }, [tournament_id]);

  const titleBasic = <h4>Basic <span className="red">*</span></h4>;
  
  return (
    <Formik>
      <div className="col-sm-12 season-popup">
        <div className="create-form-inner newCreateLeague form_submit_tabbing">
          <Tabs
            id="controlled-tab-example"
            activeKey={formik.values.key}
            onSelect={(k) => formik.setFieldValue("key", k)}
            className="mb-3"
          >
            <Tab eventKey="home" title={titleBasic}>
              <div className="form_submit_basketball">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="form-label">League Name</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <input
                      maxLength={30}
                          className={`form-control ${
                            formik.touched.game_title && formik.errors.game_title ? "is-invalid" : ""
                          }`}
                          placeholder="Enter League"
                          name="game_title"
                          value={formik.values.game_title}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.game_title && formik.errors.game_title && (
                          <div className="invalid-feedback">{formik.errors.game_title}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="form-label">State</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <Select
                          options={State?.getStatesOfCountry("US")}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["name"]}
                          value={formik.values.selected_State}
                          onChange={(item) => {
                            formik.setFieldValue("selected_State", item);
                            formik.setFieldValue("selected_City", null); // Reset city when state changes
                          }}
                          className={`formm-control ${
                            formik.touched.selected_State && formik.errors.selected_State ? "is-invalid" : ""
                          }`}
                        />
                        {formik.touched.selected_State && formik.errors.selected_State && (
                        <div className="invalid-feedback">{formik.errors.selected_State}</div>
                      )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="form-label">City</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <Select
                          options={City.getCitiesOfState(
                            formik.values.selected_State?.countryCode,
                            formik.values.selected_State?.isoCode
                          )}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["name"]}
                          value={formik.values?.selected_City}
                          onChange={(item) => formik.setFieldValue("selected_City", item)}
                          className={`formm-control ${
                            formik.touched.selected_City && formik.errors.selected_City ? "is-invalid" : ""
                          }`}
                        />
                        {formik.touched.selected_City && formik.errors.selected_City && (
                        <div className="invalid-feedback">{formik.errors.selected_City}</div>
                      )}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>  
            </Tab>
            <Tab eventKey="profile" title="About">
              <div className="col-md-12 mb-4">
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formik.values.About ? formik.values.About : "<p></p>"}
                    onReady={(editor) => console.log("Editor is ready to use!", editor)}
                    onChange={(event, editor) => formik.setFieldValue("About", editor.getData())}
                    onBlur={(event, editor) => console.log("Blur.", editor)}
                    onFocus={(event, editor) => console.log("Focus.", editor)}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>

          <div className="schedule-1">
            <div className="create-next submission">
              <button
                className="btn btn-dark green add next-orange-btn"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : tournament_id ? (
                  " Update"
                ) : (
                  " Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      </Formik>
    );
};

export default CreateLeagueNew;
