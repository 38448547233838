export const GameType=({game_type_id})=>{
    <>{game_type_id == 1 ? <svg className="trophy_img" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#F2F7FF" />
      <path d="M22.8944 26.8342C22.8105 26.8101 20.8054 26.216 17.5134 23.4143C14.1123 20.5192 14.0032 15.7385 14.0001 15.5367C13.9975 15.3725 14.0609 15.2142 14.1752 15.0962C14.2895 14.9787 14.4463 14.9111 14.6104 14.9095L20.4472 14.8408L20.4619 16.0768L15.2843 16.1376C15.4311 17.4427 16.0147 20.5161 18.3146 22.473C21.3696 25.0734 23.2185 25.6407 23.2369 25.6465L22.8944 26.8342Z" fill="#FCAB28" />
      <path d="M37.7924 26.8347L37.4526 25.6464C37.4678 25.6417 39.3168 25.0738 42.3723 22.474C44.6716 20.5171 45.2558 17.4442 45.4026 16.1386L40.225 16.0777L40.2397 14.8418L46.0764 14.9105C46.2406 14.9126 46.3973 14.9797 46.5117 15.0972C46.6265 15.2146 46.6894 15.3735 46.6868 15.5376C46.6836 15.7395 46.5751 20.5207 43.173 23.4152C39.8815 26.2164 37.8758 26.8111 37.7919 26.8352L37.7924 26.8347Z" fill="#FCAB28" />
      <path d="M20.1804 14.9101C20.1804 14.9101 19.4248 14.6353 19.4248 13.8802C19.4248 13.1251 19.2874 12.7817 19.9743 12.0947C20.6613 11.4078 25.8572 10.4697 30.0459 10.4697C34.2346 10.4697 39.957 11.6144 40.6439 12.2321C41.3309 12.8504 41.0561 14.4985 40.9187 14.7727C40.7813 15.0475 40.2318 14.9788 40.2318 14.9788L20.1804 14.9101Z" fill="#FFCF3F" />
      <path d="M30.0001 12.7808C27.4144 12.7808 21.9657 13.1242 19.9741 14.5662C19.9741 14.5662 19.9741 21.9824 22.0344 25.7594C24.0946 29.5365 27.7337 32.6266 27.7337 32.6266H32.678C32.678 32.6266 36.3177 29.5365 38.3774 25.7594C40.4377 21.9824 40.4377 14.5662 40.4377 14.5662C38.24 13.1242 32.5857 12.7808 30.0001 12.7808Z" fill="#FFD733" />
      <path d="M30.0001 12.7808C27.4144 12.7808 21.9657 13.1242 19.9741 14.5662C19.9741 14.5662 19.9741 21.9824 22.0344 25.7594C23.0102 27.5486 24.3406 29.1831 25.4706 30.4085C26.2613 30.7441 27.1701 30.9554 28.2146 30.978C34.5322 31.1154 36.1803 25.7589 36.8672 23.8366C37.949 20.8073 38.5646 16.0507 38.8069 13.8431C36.1525 13.0036 32.0824 12.7808 30.0006 12.7808H30.0001Z" fill="#FFE266" />
      <path d="M37.2788 41.8969C36.6605 40.0427 35.3559 40.3174 35.3559 40.3174C35.3559 40.3174 35.3538 39.7212 34.4404 39.6358C33.8746 39.4617 32.4163 38.9017 31.9223 37.639C31.3041 36.0596 31.5789 34.068 31.5789 34.068L30.2056 33.8887L28.8322 34.068C28.8322 34.068 29.107 36.0596 28.4888 37.639C27.9948 38.9017 26.5365 39.4617 25.9707 39.6358C25.0573 39.7212 25.0552 40.3174 25.0552 40.3174C25.0552 40.3174 23.7506 40.0427 23.1323 41.8969H37.2783H37.2788Z" fill="#FFCE45" />
      <path d="M25.0557 40.3175C25.9141 40.2619 26.7725 40.2247 27.6308 40.2058C28.4892 40.1827 29.3476 40.177 30.206 40.1738C31.0644 40.1764 31.9228 40.1822 32.7812 40.2053C33.6396 40.2242 34.498 40.2614 35.3564 40.317C34.498 40.3726 33.6396 40.4103 32.7812 40.4287C31.9228 40.4512 31.0644 40.4575 30.206 40.4601C29.3476 40.4575 28.4892 40.4512 27.6308 40.4281C26.7725 40.4093 25.9141 40.372 25.0557 40.3165V40.3175Z" fill="#FCAB28" />
      <path d="M32.6781 33.5878H27.8025C27.4989 33.5878 27.2529 33.3419 27.2529 33.0383C27.2529 32.7347 27.4989 32.4888 27.8025 32.4888H32.6781C32.9817 32.4888 33.2276 32.7347 33.2276 33.0383C33.2276 33.3419 32.9817 33.5878 32.6781 33.5878Z" fill="#FCAB28" />
      <path d="M32.4031 34.281H28.077C27.808 34.281 27.5894 34.0629 27.5894 33.7933C27.5894 33.5238 27.8075 33.3057 28.077 33.3057H32.4031C32.6721 33.3057 32.8907 33.5238 32.8907 33.7933C32.8907 34.0629 32.6726 34.281 32.4031 34.281Z" fill="#FCAB28" />
      <path d="M37.9388 41.8281H22.9534C22.4457 41.8281 22.0342 42.2397 22.0342 42.7473V49.0807C22.0342 49.5884 22.4457 49.9999 22.9534 49.9999H37.9388C38.4465 49.9999 38.8581 49.5884 38.8581 49.0807V42.7473C38.8581 42.2397 38.4465 41.8281 37.9388 41.8281Z" fill="#6C554F" />
      <path d="M35.4109 43.2427H25.4815C25.2463 43.2427 25.0557 43.4333 25.0557 43.6685V47.1293C25.0557 47.3645 25.2463 47.5551 25.4815 47.5551H35.4109C35.6461 47.5551 35.8367 47.3645 35.8367 47.1293V43.6685C35.8367 43.4333 35.6461 43.2427 35.4109 43.2427Z" fill="#FCAB28" />
      <path d="M26.8504 43.2427H30.2656C30.3464 43.2427 30.4119 43.4335 30.4119 43.6685V47.1293C30.4119 47.3642 30.3464 47.5551 30.2656 47.5551H26.8504C26.7696 47.5551 26.7041 47.3642 26.7041 47.1293V43.6685C26.7041 43.4335 26.7696 43.2427 26.8504 43.2427Z" fill="#FFD733" />
      <path d="M27.7339 32.626C28.146 32.5967 28.5577 32.5825 28.9698 32.571C29.382 32.5579 29.7936 32.5563 30.2058 32.5542C30.6179 32.5558 31.0296 32.5579 31.4417 32.5705L32.0599 32.5904C32.266 32.6024 32.4721 32.6093 32.6782 32.6255C32.4721 32.6418 32.266 32.6486 32.0599 32.6606L31.4417 32.6806C31.0296 32.6937 30.6179 32.6953 30.2058 32.6968C29.7936 32.6953 29.382 32.6932 28.9698 32.68C28.5577 32.6685 28.146 32.6549 27.7339 32.625V32.626Z" fill="#FFE266" />
      <path d="M27.7568 33.4507C28.1763 33.4214 28.5964 33.4072 29.0158 33.3957C29.4353 33.3826 29.8554 33.3805 30.2749 33.3789C30.6944 33.3805 31.1144 33.3826 31.5339 33.3952L32.1631 33.4151C32.3729 33.4271 32.5826 33.434 32.7924 33.4502C32.5826 33.4665 32.3729 33.4733 32.1631 33.4854L31.5339 33.5053C31.1144 33.5184 30.6944 33.52 30.2749 33.5215C29.8554 33.52 29.4353 33.5179 29.0158 33.5048C28.5964 33.4932 28.1763 33.4796 27.7568 33.4497V33.4507Z" fill="#FFE266" />
      <path d="M28.8804 34.3658C29.3224 34.3097 29.7639 34.2966 30.206 34.2939C30.648 34.296 31.0895 34.3086 31.5316 34.3658C31.0895 34.4224 30.648 34.4355 30.206 34.4376C29.7639 34.4355 29.3224 34.4224 28.8804 34.3658Z" fill="#FFE266" />
      <path d="M23.4307 41.2335C24.5596 41.1779 25.6891 41.1407 26.8186 41.1218C27.9476 41.0988 29.0771 41.093 30.206 41.0898C31.335 41.0925 32.4645 41.0982 33.594 41.1213C34.723 41.1402 35.8525 41.1774 36.982 41.233C35.8525 41.2886 34.7235 41.3263 33.594 41.3447C32.4645 41.3672 31.3355 41.3735 30.206 41.3761C29.0766 41.3735 27.9476 41.3672 26.8186 41.3442C25.6891 41.3253 24.5602 41.2881 23.4307 41.2325V41.2335Z" fill="#FCAB28" />
      <g opacity="0.48">
          <path d="M26.53 10.6523C25.3764 10.7646 24.2579 10.9203 23.2779 11.0975C23.1641 14.4886 23.1284 20.155 23.9575 23.2876C25.1934 27.9571 27.391 30.2921 27.391 30.2921C27.391 30.2921 26.0177 23.4249 26.1551 17.3821C26.2207 14.4881 26.3811 12.2265 26.53 10.6529V10.6523Z" fill="#FFFFFD" />
      </g>
      <path d="M40.4377 14.5663C39.6768 14.1311 38.8336 13.882 37.9868 13.6885C37.1383 13.4939 36.2768 13.3581 35.4121 13.2527C34.5469 13.1515 33.6785 13.0713 32.8086 13.0194C31.9387 12.9654 31.0672 12.9297 30.1967 12.9261C29.3268 12.9177 28.4553 12.9476 27.5854 12.9958C26.7149 13.0409 25.8465 13.118 24.9813 13.2181C24.1172 13.323 23.2546 13.4541 22.4072 13.6513C21.5619 13.8474 20.7177 14.1012 19.9741 14.5663C20.3302 14.3141 20.7151 14.0928 21.1256 13.937C21.2274 13.8961 21.328 13.8516 21.4313 13.8133L21.7433 13.7074C21.9505 13.6329 22.1618 13.5731 22.3731 13.5144C23.2205 13.2832 24.0852 13.1211 24.953 12.9958C26.6908 12.7488 28.4432 12.6366 30.1993 12.6387C31.9539 12.6586 33.7058 12.7813 35.4425 13.0309C36.3103 13.1568 37.174 13.3235 38.0214 13.5522C38.2343 13.6051 38.443 13.6749 38.6517 13.742C38.8619 13.8039 39.0664 13.8857 39.2709 13.9654C39.4781 14.0377 39.6737 14.14 39.8719 14.2333C39.9725 14.2774 40.0659 14.3356 40.1587 14.3938L40.4382 14.5658L40.4377 14.5663Z" fill="#FCAB28" />
      <g opacity="0.35">
          <path d="M23.6655 49.9994H26.2103L32.5662 41.8281H29.6418L23.6655 49.9994Z" fill="white" />
      </g>
      <g opacity="0.35">
          <path d="M29.9491 49.9994L36.2525 41.8281H35.0575L28.6885 49.9994H29.9491Z" fill="white" />
      </g>
      <path d="M28.9911 10.0449L29.8778 13.1974L28.0409 18.6147L25.2539 10.0449H28.9911Z" fill="#CD3414" />
      <path d="M28.4316 10.0449L29.5381 14.2L29.6812 13.7774L28.6875 10.0449H28.4316Z" fill="#FFCE45" />
      <path d="M26.521 19.9445V18.1077C26.521 17.8628 26.7198 17.6641 26.9646 17.6641H31.9687C32.2136 17.6641 32.4123 17.8628 32.4123 18.1077V20.008H31.3987V19.0578C31.3987 18.813 31.2 18.6142 30.9551 18.6142H28.0412C27.7963 18.6142 27.5976 18.813 27.5976 19.0578V19.6913L26.5205 19.9445H26.521Z" fill="#FCAB28" />
      <path d="M28.2802 17.9094L28.4123 17.5203L25.9855 10.0449H25.7275L28.2802 17.9094Z" fill="#FFCE45" />
      <path d="M33.1079 27.862C35.1364 25.8334 35.1364 22.5445 33.1079 20.516C31.0793 18.4874 27.7904 18.4874 25.7619 20.516C23.7333 22.5445 23.7333 25.8334 25.7619 27.862C27.7904 29.8905 31.0793 29.8905 33.1079 27.862Z" fill="#FCAB28" />
      <path d="M24.2407 24.189C24.2407 24.4717 24.2638 24.7491 24.3073 25.0196C25.0614 25.4465 25.9329 25.6898 26.861 25.6898C29.7298 25.6898 32.0554 23.3642 32.0554 20.4954C32.0554 20.2127 32.0323 19.9353 31.9888 19.6648C31.2348 19.2379 30.3633 18.9946 29.4351 18.9946C26.5663 18.9946 24.2407 21.3202 24.2407 24.189Z" fill="#FFCE45" />
      <path d="M28.041 18.6144H31.2717L34.9459 10H30.8915L28.041 18.6144Z" fill="#FE5629" />
      <path d="M29.4349 27.9537C27.3594 27.9537 25.6704 26.2652 25.6704 24.1893C25.6704 22.1133 27.3589 20.4248 29.4349 20.4248C31.5108 20.4248 33.1993 22.1133 33.1993 24.1893C33.1993 26.2652 31.5108 27.9537 29.4349 27.9537ZM29.4349 20.5517C27.4291 20.5517 25.7973 22.1835 25.7973 24.1893C25.7973 26.195 27.4291 27.8268 29.4349 27.8268C31.4406 27.8268 33.0724 26.195 33.0724 24.1893C33.0724 22.1835 31.4406 20.5517 29.4349 20.5517Z" fill="#FCAB28" />
      <path d="M25.4128 22.0989C25.4128 22.0989 25.0012 21.2437 25.8879 20.832C25.8879 20.832 25.9513 21.7187 25.4128 22.0989Z" fill="#FFE266" />
      <path d="M25.4437 22.4471C25.4437 22.4471 25.3802 21.8136 26.3938 21.6553C26.3938 21.6553 26.2355 22.2572 25.4437 22.4471Z" fill="#FFE266" />
      <path d="M26.1496 21.1727C26.1496 21.1727 25.9246 20.2509 26.8779 20.0322C26.8779 20.0322 26.7558 20.9126 26.1496 21.1727Z" fill="#FFE266" />
      <path d="M26.1084 21.5203C26.1084 21.5203 26.1781 20.8874 27.2022 20.9435C27.2022 20.9435 26.9222 21.4993 26.1084 21.5203Z" fill="#FFE266" />
      <path d="M27.0101 20.4328C27.0101 20.4328 26.964 19.4847 27.9409 19.4512C27.9409 19.4512 27.654 20.2928 27.0101 20.4328Z" fill="#FFE266" />
      <path d="M26.9038 20.7658C26.9038 20.7658 27.0921 20.1575 28.0873 20.4066C28.0873 20.4066 27.7071 20.899 26.9038 20.7658Z" fill="#FFE266" />
      <path d="M27.9761 19.8669C27.9761 19.8669 28.2131 18.9477 29.1564 19.2052C29.1564 19.2052 28.6326 19.9236 27.9761 19.8669Z" fill="#FFE266" />
      <path d="M27.7759 20.1538C27.7759 20.1538 28.1361 19.6289 29.0129 20.1617C29.0129 20.1617 28.5037 20.5193 27.7759 20.1538Z" fill="#FFE266" />
      <path d="M29.0942 19.6406C29.0942 19.6406 29.5982 18.8363 30.4193 19.3675C30.4193 19.3675 29.7025 19.8939 29.0942 19.6406Z" fill="#FFE266" />
      <path d="M28.8164 19.8529C28.8164 19.8529 29.3188 19.4617 29.9926 20.2346C29.9926 20.2346 29.399 20.4213 28.8164 19.8529Z" fill="#FFE266" />
      <path d="M30.2681 19.7328C30.2681 19.7328 30.9802 19.1057 31.6162 19.8482C31.6162 19.8482 30.7793 20.1486 30.2681 19.7328Z" fill="#FFE266" />
      <path d="M29.9409 19.8576C29.9409 19.8576 30.534 19.6258 30.9598 20.5587C30.9598 20.5587 30.3373 20.5686 29.9409 19.8576Z" fill="#FFE266" />
      <path d="M31.3584 20.1934C31.3584 20.1934 32.1853 19.7278 32.6531 20.5867C32.6531 20.5867 31.7721 20.7062 31.3584 20.1934Z" fill="#FFE266" />
      <path d="M31.0127 20.2472C31.0127 20.2472 31.6409 20.1434 31.8637 21.1449C31.8637 21.1449 31.2529 21.0254 31.0127 20.2472Z" fill="#FFE266" />
      <path d="M32.3032 20.8226C32.3032 20.8226 33.2036 20.5221 33.4998 21.4539C33.4998 21.4539 32.6121 21.4046 32.3032 20.8226Z" fill="#FFE266" />
      <path d="M31.9536 20.8096C31.9536 20.8096 32.5902 20.8269 32.619 21.852C32.619 21.852 32.0422 21.6187 31.9536 20.8096Z" fill="#FFE266" />
      <path d="M33.1084 21.5996C33.1084 21.5996 34.0575 21.5797 34.0638 22.5577C34.0638 22.5577 33.2306 22.2472 33.1084 21.5996Z" fill="#FFE266" />
      <path d="M32.7781 21.4839C32.7781 21.4839 33.3806 21.6889 33.1042 22.6768C33.1042 22.6768 32.6223 22.283 32.7781 21.4839Z" fill="#FFE266" />
      <path d="M33.6288 22.6152C33.6288 22.6152 34.5391 22.8837 34.2497 23.8176C34.2497 23.8176 33.5496 23.2696 33.6288 22.6152Z" fill="#FFE266" />
      <path d="M33.3491 22.4053C33.3491 22.4053 33.8614 22.7833 33.2988 23.6412C33.2988 23.6412 32.959 23.12 33.3491 22.4053Z" fill="#FFE266" />
      <path d="M33.9371 23.7021C33.9371 23.7021 34.7426 24.2045 34.2135 25.0267C34.2135 25.0267 33.6854 24.3115 33.9371 23.7021Z" fill="#FFE266" />
      <path d="M33.7245 23.4248C33.7245 23.4248 34.1167 23.9261 33.3454 24.602C33.3454 24.602 33.1571 24.009 33.7245 23.4248Z" fill="#FFE266" />
      <path d="M33.8092 24.8784C33.8092 24.8784 34.4924 25.537 33.8039 26.2313C33.8039 26.2313 33.4363 25.4217 33.8092 24.8784Z" fill="#FFE266" />
      <path d="M33.658 24.563C33.658 24.563 33.9375 25.1351 33.0424 25.6358C33.0424 25.6358 32.9816 25.0166 33.658 24.563Z" fill="#FFE266" />
      <path d="M33.4778 25.9648C33.4778 25.9648 34.0237 26.7409 33.2162 27.2925C33.2162 27.2925 33.0085 26.4279 33.4778 25.9648Z" fill="#FFE266" />
      <path d="M33.3896 25.626C33.3896 25.626 33.5558 26.2405 32.5815 26.5625C32.5815 26.5625 32.6392 25.9427 33.3896 25.626Z" fill="#FFE266" />
      <path d="M32.9647 26.959C32.9647 26.959 33.2557 27.8625 32.3213 28.1493C32.3213 28.1493 32.3795 27.2621 32.9647 26.959Z" fill="#FFE266" />
      <path d="M32.9804 26.6094C32.9804 26.6094 32.9568 27.2454 31.9312 27.2643C31.9312 27.2643 32.1703 26.6896 32.9804 26.6094Z" fill="#FFE266" />
      <path d="M32.1407 27.749C32.1407 27.749 32.1449 28.6981 31.167 28.6887C31.167 28.6887 31.4911 27.8607 32.1407 27.749Z" fill="#FFE266" />
      <path d="M32.2617 27.4209C32.2617 27.4209 32.0467 28.0202 31.0635 27.7276C31.0635 27.7276 31.4651 27.2525 32.2617 27.4209Z" fill="#FFE266" />
      <path d="M31.3139 28.3649C31.3139 28.3649 31.1005 29.2898 30.1509 29.0565C30.1509 29.0565 30.6558 28.325 31.3139 28.3649Z" fill="#FFE266" />
      <path d="M31.507 28.0733C31.507 28.0733 31.1604 28.6071 30.2705 28.0974C30.2705 28.0974 30.7702 27.7267 31.507 28.0733Z" fill="#FFE266" />
      <path d="M30.159 28.6257C30.159 28.6257 29.7578 29.4862 28.8779 29.0604C28.8779 29.0604 29.524 28.4495 30.159 28.6257Z" fill="#FFE266" />
      <path d="M30.4088 28.3803C30.4088 28.3803 29.9589 28.8307 29.1943 28.1464C29.1943 28.1464 29.7601 27.8879 30.4088 28.3803Z" fill="#FFE266" />
      <path d="M29.0249 28.6655C29.0249 28.6655 28.468 29.4343 27.6846 28.8496C27.6846 28.8496 28.4349 28.3724 29.0249 28.6655Z" fill="#FFE266" />
      <path d="M29.3163 28.4721C29.3163 28.4721 28.7893 28.8286 28.168 28.0122C28.168 28.0122 28.7726 27.8654 29.3163 28.4721Z" fill="#FFE266" />
      <path d="M27.9181 28.5028C27.9181 28.5028 27.1582 29.0717 26.583 28.281C26.583 28.281 27.4409 28.0477 27.9181 28.5028Z" fill="#FFE266" />
      <path d="M28.2536 28.4047C28.2536 28.4047 27.6443 28.5893 27.2935 27.625C27.2935 27.625 27.9143 27.6643 28.2536 28.4047Z" fill="#FFE266" />
      <path d="M26.9028 27.9803C26.9028 27.9803 26.0066 28.2928 25.6978 27.3652C25.6978 27.3652 26.586 27.4025 26.9028 27.9803Z" fill="#FFE266" />
      <path d="M27.2528 27.9886C27.2528 27.9886 26.6162 27.9797 26.5732 26.9551C26.5732 26.9551 27.1532 27.1806 27.2528 27.9886Z" fill="#FFE266" />
      <path d="M26.1026 27.3379C26.1026 27.3379 25.1576 27.426 25.0806 26.4512C25.0806 26.4512 25.9337 26.7008 26.1026 27.3379Z" fill="#FFE266" />
      <path d="M26.44 27.4294C26.44 27.4294 25.8244 27.2679 26.0289 26.2627C26.0289 26.2627 26.5381 26.6208 26.44 27.4294Z" fill="#FFE266" />
      <path d="M25.4918 26.3238C25.4918 26.3238 24.549 26.2137 24.6764 25.2441C24.6764 25.2441 25.4593 25.6657 25.4918 26.3238Z" fill="#FFE266" />
      <path d="M25.803 26.4838C25.803 26.4838 25.234 26.198 25.643 25.2573C25.643 25.2573 26.0662 25.7135 25.803 26.4838Z" fill="#FFE266" />
      <path d="M25.0978 25.2599C25.0978 25.2599 24.1932 24.973 24.5016 24.0454C24.5016 24.0454 25.1906 24.6075 25.0978 25.2599Z" fill="#FFE266" />
      <path d="M25.3728 25.4756C25.3728 25.4756 24.8684 25.087 25.4483 24.2412C25.4483 24.2412 25.7776 24.7693 25.3728 25.4756Z" fill="#FFE266" />
      <path d="M24.9039 24.1579C24.9039 24.1579 24.1252 23.6157 24.6946 22.8213C24.6946 22.8213 25.186 23.5622 24.9039 24.1579Z" fill="#FFE266" />
      <path d="M25.1033 24.4458C25.1033 24.4458 24.7368 23.9251 25.5417 23.2891C25.5417 23.2891 25.6995 23.891 25.1033 24.4458Z" fill="#FFE266" />
      <path d="M25.081 23.0295C25.081 23.0295 24.5026 22.2771 25.2865 21.6924C25.2865 21.6924 25.5304 22.5476 25.081 23.0295Z" fill="#FFE266" />
      <path d="M25.1831 23.3641C25.1831 23.3641 24.9912 22.7569 25.9508 22.3945C25.9508 22.3945 25.9194 23.0159 25.1831 23.3641Z" fill="#FFE266" />
      <path d="M28.3975 18.6144H28.657L31.7461 10H31.4865L28.3975 18.6144Z" fill="#FFCE45" />
      <path d="M30.6309 18.6144H30.8925L34.4991 10H34.237L30.6309 18.6144Z" fill="#FFCE45" />
  </svg>
      : <svg className="trophy_img" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="30" fill="#F2F7FF" />
          <path d="M24.1229 44.8545H36.3641C36.7785 44.8545 37.115 45.1134 37.115 45.4331V49.4208C37.115 49.7405 36.7791 49.9994 36.3641 49.9994H24.1229C23.7085 49.9994 23.3721 49.7405 23.3721 49.4208V45.4331C23.3721 45.1134 23.7079 44.8545 24.1229 44.8545Z" fill="#CD3414" />
          <path d="M26.1881 45.7451H34.2991C34.4914 45.7451 34.6469 45.8654 34.6469 46.0133V48.1927C34.6469 48.3406 34.4914 48.4609 34.2991 48.4609H26.1881C25.9958 48.4609 25.8403 48.3406 25.8403 48.1927V46.0133C25.8403 45.8654 25.9958 45.7451 26.1881 45.7451Z" fill="#FCAB28" />
          <path d="M27.3062 45.7451H30.0962C30.1623 45.7451 30.2159 45.8654 30.2159 46.0133V48.1927C30.2159 48.3406 30.1623 48.4609 30.0962 48.4609H27.3062C27.2402 48.4609 27.1865 48.3406 27.1865 48.1927V46.0133C27.1865 45.8654 27.2402 45.7451 27.3062 45.7451Z" fill="#FFD733" />
          <path opacity="0.35" d="M24.7046 50H26.7833L31.9748 44.8545H29.5862L24.7046 50Z" fill="white" />
          <path opacity="0.35" d="M29.8364 50L34.9857 44.8545H34.0095L28.8071 50H29.8364Z" fill="white" />
          <path d="M28.2485 21.4131V22.7593H31.934V21.2002L28.2485 21.4131Z" fill="#FCAB28" />
          <path d="M21.7521 19.7609C21.6226 19.7609 21.4856 19.7397 21.3425 19.697C21.1551 19.6406 21.0489 19.4434 21.1047 19.2554C21.161 19.068 21.3588 18.9618 21.5462 19.0176C21.6486 19.0485 21.835 19.0859 21.9314 18.9911C22.0273 18.8963 22.0842 18.633 21.9542 18.179C21.7342 17.4097 20.4866 16.0803 18.5309 14.532C17.3494 13.5969 16.7372 12.0887 17.1077 11.0248C17.3336 10.3747 17.9068 10.002 18.6804 10.002C19.2725 10.002 19.7238 10.1591 20.0218 10.4689C20.4877 10.9543 20.4584 11.6743 20.4373 12.2003C20.4297 12.391 20.4216 12.5882 20.4438 12.689C20.446 12.6977 20.4476 12.702 20.4481 12.7031C20.525 12.7778 20.9828 12.7345 21.387 12.5774C21.5695 12.5064 21.7748 12.5969 21.8458 12.7795C21.9168 12.962 21.8263 13.1673 21.6437 13.2383C21.4796 13.3022 20.6345 13.6067 20.122 13.3331C19.928 13.2296 19.8002 13.0601 19.752 12.8434C19.7097 12.6527 19.7184 12.43 19.7292 12.1727C19.7471 11.7301 19.7671 11.2279 19.5109 10.9608C19.3522 10.7956 19.0726 10.7116 18.681 10.7116C18.2075 10.7116 17.9036 10.8958 17.7773 11.2588C17.5449 11.9273 17.935 13.1571 18.9719 13.9778C20.4573 15.1539 22.2987 16.8035 22.6362 17.9856C22.8242 18.6433 22.7505 19.1801 22.4298 19.497C22.2521 19.6726 22.0192 19.7625 21.7526 19.7625L21.7521 19.7609Z" fill="#FCAB28" />
          <path d="M38.8265 19.7605C38.56 19.7605 38.327 19.6706 38.1493 19.4951C37.8281 19.1782 37.755 18.6413 37.9429 17.9836C38.2804 16.8016 40.1224 15.152 41.6073 13.9758C42.6436 13.1551 43.0342 11.9253 42.8018 11.2568C42.6756 10.8939 42.3717 10.7097 41.8982 10.7097C41.5065 10.7097 41.227 10.7937 41.0682 10.9589C40.8115 11.2265 40.832 11.7282 40.8499 12.1708C40.8602 12.4286 40.8694 12.6507 40.8272 12.8414C40.779 13.0581 40.6511 13.2277 40.4572 13.3312C39.9447 13.6053 39.0996 13.3003 38.9354 13.2364C38.7528 13.1654 38.6624 12.9601 38.7333 12.7775C38.8043 12.5949 39.0096 12.505 39.1916 12.5754C39.5963 12.7315 40.0541 12.7753 40.131 12.7C40.131 12.7 40.1327 12.6957 40.1348 12.687C40.157 12.5863 40.1494 12.3891 40.1413 12.1984C40.1202 11.6724 40.0909 10.9518 40.5568 10.467C40.8548 10.1571 41.3061 10 41.8982 10C42.6718 10 43.245 10.3727 43.4709 11.0228C43.8409 12.0868 43.2287 13.5945 42.0472 14.5306C40.0915 16.0789 38.8439 17.4078 38.6239 18.1776C38.4939 18.6316 38.5508 18.8948 38.6467 18.9897C38.7425 19.0845 38.9294 19.0471 39.0318 19.0162C39.2193 18.9599 39.417 19.0666 39.4734 19.254C39.5297 19.4415 39.423 19.6392 39.2355 19.6955C39.0931 19.7383 38.9554 19.7595 38.826 19.7595L38.8265 19.7605Z" fill="#FCAB28" />
          <path d="M21.2319 10.356H39.3759V15.1758H21.3739L21.2319 10.356Z" fill="#FFD733" />
          <path d="M21.374 15.1758C21.374 15.1758 22.7208 21.8381 30.1627 21.8381C37.6046 21.8381 39.3766 15.1758 39.3766 15.1758H21.374Z" fill="#FCAB28" />
          <g opacity="0.7">
              <path d="M35.4631 15.1758H21.374C21.374 15.1758 21.6828 16.6997 22.8183 18.2897C24.0053 18.942 25.5129 19.3813 27.4226 19.3813C31.8015 19.3813 34.2165 17.0746 35.4636 15.1758H35.4631Z" fill="#FFCE45" />
          </g>
          <path d="M28.2488 22.7593C28.2488 22.7593 27.1149 22.9716 27.0439 24.5313H33.4229C33.4229 24.5313 33.5649 23.0431 31.9348 22.7593H28.2493H28.2488Z" fill="#FFD733" />
          <path d="M26.3354 24.5312H34.1316C34.1316 24.5312 34.1316 33.6032 31.2967 38.2102H29.3123C29.3123 38.2102 26.4774 33.6742 26.3354 24.5312Z" fill="#FFD733" />
          <g opacity="0.48">
              <path d="M26.3354 24.5313C26.4243 30.2537 27.5679 34.171 28.4114 36.3012C29.5962 36.1787 30.625 35.4013 31.0578 34.2713C32.2979 31.0354 32.7047 26.8982 32.838 24.5308H26.3354V24.5313Z" fill="#FFFFFD" />
          </g>
          <path d="M31.2254 38.21H29.312V41.6121H31.2254V38.21Z" fill="#FCAB28" />
          <path d="M31.6861 38.7957H28.8642C28.6843 38.7957 28.5386 38.65 28.5386 38.4701C28.5386 38.2903 28.6843 38.1445 28.8642 38.1445H31.6861C31.8659 38.1445 32.0117 38.2903 32.0117 38.4701C32.0117 38.65 31.8659 38.7957 31.6861 38.7957Z" fill="#FCAB28" />
          <path d="M35.0531 44.4243C34.3445 41.488 31.2257 41.6121 31.2257 41.6121H29.3123C26.4773 41.6121 25.6978 44.3002 25.6978 44.3002L35.0536 44.4243H35.0531Z" fill="#FFCE45" />
          <path d="M31.6861 41.9148H28.8642C28.6843 41.9148 28.5386 41.7691 28.5386 41.5893C28.5386 41.4094 28.6843 41.2637 28.8642 41.2637H31.6861C31.8659 41.2637 32.0117 41.4094 32.0117 41.5893C32.0117 41.7691 31.8659 41.9148 31.6861 41.9148Z" fill="#FCAB28" />
          <path d="M35.0527 45.0085H25.6969C25.3052 45.0085 24.9883 44.691 24.9883 44.2999C24.9883 43.9088 25.3057 43.5913 25.6969 43.5913H35.0527C35.4444 43.5913 35.7613 43.9088 35.7613 44.2999C35.7613 44.691 35.4439 45.0085 35.0527 45.0085Z" fill="#FFCE45" />
          <g opacity="0.48">
              <path d="M29.2883 10.356H24.9414V15.1753H29.2883V10.356Z" fill="#FFFFFD" />
          </g>
          <path d="M37.2263 10.356H34.8638V15.1753H37.2263V10.356Z" fill="#FFE266" />
          <path d="M27.9544 26.3509C27.5801 27.8553 27.2615 29.3738 26.9983 30.9015C25.5421 30.4177 24.0864 29.9329 22.6302 29.4475C22.3848 28.688 22.1145 27.9377 21.8225 27.1944C22.7033 27.5362 23.4683 27.8125 24.3622 28.2172C24.373 27.8699 24.036 27.8001 23.7121 27.6727C23.0869 27.4273 22.4601 27.1803 21.8349 26.9349C21.7385 26.8975 21.6399 26.858 21.5662 26.7865C21.4839 26.7041 21.4427 26.5914 21.4032 26.4804C21.3761 26.4034 21.3495 26.3243 21.3208 26.2474C21.0451 25.463 20.7693 24.6802 20.4936 23.8941C21.8761 24.3974 23.2407 24.9505 24.5821 25.5507C24.6325 25.2501 24.3204 25.0242 24.0517 24.879C23.4878 24.5707 21.025 23.7039 20.4324 23.4071C20.298 23.3388 20.1637 23.2603 20.0651 23.1454C19.9578 23.0235 19.8966 22.8697 19.8359 22.7191C19.8072 22.6492 19.7801 22.5777 19.7519 22.5078C19.3792 21.5619 19.0032 20.6182 18.6289 19.6729C20.7997 20.7114 22.9683 21.7504 25.1374 22.7889C25.2934 22.8642 25.4527 22.9428 25.5599 23.0772C25.6547 23.1969 25.6959 23.3513 25.7496 23.4943C26.0036 24.1661 26.5643 24.6227 27.2269 24.8551C27.9095 25.0935 28.1208 25.6791 27.9539 26.3504L27.9544 26.3509Z" fill="url(#paint0_linear_570_3786)" />
          <path d="M24.5821 25.5508C24.6325 25.2501 24.3205 25.0242 24.0518 24.879C23.4878 24.5708 21.025 23.704 20.4324 23.4071C20.298 23.3388 20.1637 23.2603 20.0651 23.1454C19.9578 23.0235 19.8966 22.8697 19.8359 22.7191C19.8072 22.6492 19.7801 22.5777 19.752 22.5078H19.7536L24.1503 24.3275C24.1503 24.3275 25.0832 24.7593 25.0654 25.755L24.5816 25.5508H24.5821Z" fill="url(#paint1_linear_570_3786)" />
          <path d="M24.7858 28.4091L24.3616 28.2173C24.3725 27.8701 24.0355 27.8002 23.7116 27.6729C23.0864 27.4275 22.4596 27.1804 21.8344 26.935C21.738 26.8976 21.6394 26.8581 21.5657 26.7866C21.4834 26.7042 21.4422 26.5916 21.4027 26.4805C21.3756 26.4036 21.349 26.3245 21.3203 26.2476L24.243 27.4584C24.243 27.4584 24.775 27.6696 24.7858 28.4091Z" fill="url(#paint2_linear_570_3786)" />
          <path d="M32.4795 26.3509C32.8538 27.8553 33.1724 29.3738 33.4357 30.9015C34.8919 30.4177 36.3475 29.9329 37.8037 29.4475C38.0491 28.688 38.3195 27.9377 38.6115 27.1944C37.7306 27.5362 36.9657 27.8125 36.0718 28.2172C36.0609 27.8699 36.3979 27.8001 36.7219 27.6727C37.347 27.4273 37.9738 27.1803 38.599 26.9349C38.696 26.8975 38.794 26.858 38.8677 26.7865C38.9501 26.7041 38.9912 26.5914 39.0308 26.4804C39.0579 26.4034 39.0844 26.3243 39.1131 26.2474C39.3889 25.463 39.6646 24.6802 39.9404 23.8941C38.5578 24.3974 37.1932 24.9505 35.8518 25.5507C35.8015 25.2501 36.1135 25.0242 36.3822 24.879C36.9462 24.5707 39.4089 23.7039 40.0016 23.4071C40.1359 23.3388 40.2703 23.2603 40.3689 23.1454C40.4761 23.0235 40.5374 22.8697 40.598 22.7191C40.6267 22.6492 40.6533 22.5777 40.682 22.5078C41.0547 21.5619 41.4307 20.6182 41.805 19.6729C39.6343 20.7114 37.4657 21.7504 35.2966 22.7889C35.1405 22.8642 34.9813 22.9428 34.874 23.0772C34.7792 23.1969 34.738 23.3513 34.6844 23.4943C34.4303 24.1661 33.8696 24.6227 33.2071 24.8551C32.5245 25.0935 32.3132 25.6791 32.48 26.3504L32.4795 26.3509Z" fill="url(#paint3_linear_570_3786)" />
          <path d="M35.8517 25.5508C35.8013 25.2501 36.1133 25.0242 36.382 24.879C36.946 24.5708 39.4087 23.704 40.0014 23.4071C40.1357 23.3388 40.2701 23.2603 40.3687 23.1454C40.476 23.0235 40.5372 22.8697 40.5978 22.7191C40.6266 22.6492 40.6531 22.5777 40.6818 22.5078H40.6802L36.2834 24.3275C36.2834 24.3275 35.3505 24.7593 35.3684 25.755L35.8522 25.5508H35.8517Z" fill="url(#paint4_linear_570_3786)" />
          <path d="M35.6475 28.4091L36.0716 28.2173C36.0608 27.8701 36.3978 27.8002 36.7217 27.6729C37.3469 27.4275 37.9737 27.1804 38.5989 26.935C38.6958 26.8976 38.7939 26.8581 38.8676 26.7866C38.9499 26.7042 38.9911 26.5916 39.0306 26.4805C39.0577 26.4036 39.0843 26.3245 39.113 26.2476L36.1903 27.4584C36.1903 27.4584 35.6583 27.6696 35.6475 28.4091Z" fill="url(#paint5_linear_570_3786)" />
          <defs>
              <linearGradient id="paint0_linear_570_3786" x1="18.6289" y1="25.2875" x2="28.0081" y2="25.2875" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
              <linearGradient id="paint1_linear_570_3786" x1="19.7059" y1="22.6682" x2="24.4223" y2="24.9662" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
              <linearGradient id="paint2_linear_570_3786" x1="21.284" y1="26.3662" x2="24.3741" y2="27.8722" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
              <linearGradient id="paint3_linear_570_3786" x1="41.8045" y1="10.0017" x2="32.4253" y2="10.0017" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
              <linearGradient id="paint4_linear_570_3786" x1="40.7273" y1="22.6682" x2="36.0109" y2="24.9662" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
              <linearGradient id="paint5_linear_570_3786" x1="39.1493" y1="26.3662" x2="36.0592" y2="27.8722" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF992E" />
                  <stop offset="0.22" stop-color="#F88038" />
                  <stop offset="0.67" stop-color="#E74352" />
                  <stop offset="1" stop-color="#DA1467" />
              </linearGradient>
          </defs>
      </svg>}</>  
  }