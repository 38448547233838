import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { ApiGet, Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const VerifyOtp = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [otp, setOtp] = useState('');
	const [loading, setLoading] = useState(false);
	const [Resetloading, setResetloading] = useState(false);
	
	// const fromPage = location.state?.from?.pathname || '/';
	let fromPage = location.state?.from?.pathname || `${role}/dashboard`;;
	var role = localStorage.getItem("role");
	const id = location.state?.id
	if (role === 'organizer') {
		fromPage = location.state?.from?.pathname || '/organizer/dashboard';
	} else if (role === 'coach') {
		fromPage = location.state?.from?.pathname || '/coach/dashboard';
	} else if (role === 'superadmin') {
		fromPage = location.state?.from?.pathname || '/superadmin/dashboard';
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		Apipost('/auth/verify-otp', { id, otp }).then((response) => {
			let result = response.data;
			if (result.status === true) {
				successMessageNodify(result.msg);
				setLoading(false);
				localStorage.setItem('token', result.data.jwt_token)
				localStorage.setItem('name', result.data.firstname)
				localStorage.setItem('id', result.data.id)
				localStorage.setItem("role", "organizer");
				navigate('/sports-list', { replace: true })
			} else {
				errorMessageNodify(result.msg);
				setLoading(false);
				return;
			}
		}, (err) => {
			errorMessageNodify(err.response.data.message)
			setLoading(false);
			return;
		})
	}

	const handleResend = (e) => {
		e.preventDefault();
		setResetloading(true);
		ApiGet(`auth/resend-otp/${id}`).then((response) => {
			let result = response;
			if (result.status === true) {
				successMessageNodify(result.msg);
				setResetloading(false);
			} else {
				errorMessageNodify(result.msg);
				setResetloading(false);
				return;
			}
		}, (err) => {
			errorMessageNodify(" Otp Resend  Failed")
			setResetloading(false);
				return;
		})
	}

	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={require("../../../assets/images/GIU_logo.png")} alt="giu" />
							{/* <p>Verify OTP</p> */}
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit}>
								<div className="mb-4 otp_form send-otp">
									<label className="form-label">Enter OTP</label>
									<OtpInput
										inputStyle="otp-input"
										value={otp}
										onChange={setOtp}
										numInputs={4}
										className="form-control"
										renderSeparator={<span>-</span>}
										renderInput={(props) => <input {...props} />}
									/>
								</div>
								<div className="mb-3">
									<button className="btn btn-dark green full-btn btn-lg signin-btn" >
										{
											loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Verify'
										}
									</button>
								</div>
							</form>
							<button className="btn btn-dark green full-btn btn-lg signin-btn" onClick={handleResend} >
								{
									Resetloading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Resend Otp'
								}

							</button>
						</div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>GUI: Organizer Panel</h1>
							<p>Verify OTP</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyOtp;
