import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import { roles } from "../../../components/CommonForm";
import { useDispatch } from "react-redux";
import { fetchCoaches } from "../../../redux/api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-input-2/lib/style.css';
import { Apipost } from "../../../helpers/apiCall";
import PhoneInput from "react-phone-input-2";

const EditCoach = ({ value, closeModal, refreshList }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    role_id: Yup.number().required('Please select the user type'),
    firstname: Yup.string().required('FullName is required').max(30, 'FullName cannot be longer than 30 characters'),
    // lastname: Yup.string().required('Last Name is required').max(30, 'Last Name cannot be longer than 30 characters'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    contact_number: Yup.string().required('Phone Number is required'),
    // gender: Yup.string().required('Gender is required'),
    // dob: Yup.string().required('Date of Birth is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: value.id,
      role_id: value.role_id || 0,
      firstname: value.name ||value.firstname|| '',
      lastname: value.lastname || '',
      email: value.email || '',
      contact_number: value.contact_number || '',
      // gender: value.gender || '',
      // dob: value.dob ? moment(value.dob).format('YYYY-MM-DD') : '',
      image: value.profile_pic, // Add image field
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        // Your API call here
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }
        
        // Replace the following lines with your actual API call
        const response = await Apipost('/users/update-referee', formData, "multipart/form-data", true);
        const result = response.data;

        if (result.status === true) {
          successMessageNodify(result.msg);
          setLoading(false);
          closeModal();
          dispatch(fetchCoaches());
          refreshList((prev) => !prev);
        } else {
          errorMessageNodify(result.msg);
          setLoading(false);
        }
      } catch (error) {
        errorMessageNodify(error.response?.data?.message || 'An error occurred');
        setLoading(false);
      }
    }
  }

  );

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      formik.setFieldValue('image', event.target.files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <>
      <div className="season-popup">
        <div className="modal-title h4 mb-4 pt-1">Update Member</div>
        <div className="dashboard-auto-wrapper px-1">
          <div className="create-from">
            <div className="d-flex justify-content-between align-items-center"></div>
            <div className="create-form-inner member-edit pt-0">
              <form onSubmit={formik.handleSubmit}>
                <div className='row  mb-2'>
                  <div className="col-lg-2">
                    <div className="profile-img-box">
                      <div >
                        <img
                          className="rounded-circle upadateimg"

                          alt=""
                          src={
                            (formik.values.image || image) ? (image || formik.values.image)
                              : require("../../../assets/images/user-100.png")
                          }
                          onClick={handleClick}
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={onImageChange}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="col-lg-10">
                    <label className="form-label" htmlFor="role">Select User Type:</label>
                    <select
                      disabled
                      name="role_id"
                      id="role"
                      className={`form-control ${formik.touched.role_id && formik.errors.role_id ? 'is-invalid' : ''}`}
                      value={formik.values.role_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {roles.map(item => <option key={item.roleid} value={item.roleid}>{item.type}</option>)}
                    </select>
                    {formik.touched.role_id && formik.errors.role_id ? (
                      <div className="invalid-feedback">{formik.errors.role_id}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-4">
                      <label className="form-label">FullName</label>
                      <input
                        name="firstname"
                        className={`form-control ${formik.touched.firstname && formik.errors.firstname ? 'is-invalid' : ''}`}
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="invalid-feedback">{formik.errors.firstname}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="mb-4">
                      <label className="form-label">Last Name</label>
                      <input
                        name="lastname"
                        type="text"
                        className={`form-control ${formik.touched.lastname && formik.errors.lastname ? 'is-invalid' : ''}`}
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="invalid-feedback">{formik.errors.lastname}</div>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    <div className="mb-4">
                      <label className="form-label">Email</label>
                      <input
                        name="email"
                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        readOnly
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="invalid-feedback">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-4">
                      <label className="form-label">Phone Number</label>
                      <PhoneInput
                        country={'us'}

                        inputClass={`form-control ${formik.touched.contact_number && formik.errors.contact_number ? 'is-invalid' : ''}`}
                        name="contact_number"
                        value={formik.values.contact_number}
                        onChange={(value)=>formik.setFieldValue("contact_number",value )}
                      />
                      {formik.touched.contact_number && formik.errors.contact_number ? (
                        <div className="invalid-feedback">{formik.errors.contact_number}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="mb-4">
                      <label className="form-label">Gender</label>
                      <select
                        className={`form-control ${formik.touched.gender && formik.errors.gender ? 'is-invalid' : ''}`}
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {formik.touched.gender && formik.errors.gender ? (
                        <div className="invalid-feedback">{formik.errors.gender}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-4">
                      <label className="form-label">Date of Birth</label>
                      <input
                        name="dob"
                        className={`form-control ${formik.touched.dob && formik.errors.dob ? 'is-invalid' : ''}`}
                        type="date"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.dob && formik.errors.dob ? (
                        <div className="invalid-feedback">{formik.errors.dob}</div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
                <div className="d-flex align-items-center divisions-schedule justify-content-start">
                  <div className="create-from-btn submission">
                    <button type="submit" className="btn btn-primary savesubmit" disabled={loading}>
                      {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoach;

