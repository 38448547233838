import { uid as UID } from 'uid';

export const roundRobin = (arr, round) => {
    var AllBracket = [];
    arr?.map((ar, i) => {
        let match = generateRounds(ar.teams);
        AllBracket.push({
            bracket: match,
            group_id: ar.id,
        })
    })
    return AllBracket;
}


export const generateMatch = (array) => {
    let result = []
    if (array.teams.length >= 2) {
        for (let i = 0; i < array.teams.length - 1; i++) {
            for (let j = i + 1; j < array.teams.length; j++) {
                let temp = {
                    id: UID(),
                    team_one_id: array.teams[i]['id'],
                    team_two_id: array.teams[j]['id'],
                    winner: null,
                    score_one: 0,
                    score_two: 0,
                }
                result.push(temp)
            }
        }
    }
    return result;
}


export const generateRounds = (array) => {
    
    var numberOfRounds = array.length;
    let result = [];
    if (numberOfRounds % 2 == 0) {
        for (let i = 0; i < numberOfRounds - 1; i++) {
            let match = [];
            for (var j = 0; j < array.length / 2; j++) {
                let temp = {
                    team_one_id: array[j].id,
                    team_two_id: array[array.length - 1 - j].id,
                    winner: null,
                    score_one: 0,
                    score_two: 0,
                }
                match.push(temp)
            }
            array.splice(1, 0, array[numberOfRounds - 1]);
            array.pop();
            result.push(shuffle(match));
        }
        return result;
    } else {
        for (let i = 0; i < numberOfRounds; i++) {
            let match = [];
            for (let j = i + 1; j < array.length; j++) {
                if (j % 2 == 0) {
                let temp = {
                    team_one_id: array[i]['id'],
                    team_two_id: array[j]['id'],
                    winner: null,
                    score_one: 0,
                    score_two: 0,
                }
                result.push(temp)
            }else{
                let temp = {
                    team_one_id: array[j]['id'],
                    team_two_id: array[i]['id'],
                    winner: null,
                    score_one: 0,
                    score_two: 0,
                }
                result.push(temp)
            }
            }
        }
        return shuffle(chunk(result,numberOfRounds));
    }
   
}

function chunk(array, limit) {
    let result = [];
    let n = limit;
    for (let i = n; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result
  }


function shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i));

        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
}