import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './index.css'
import Collapse from '../Collapse';

function OffCanvasExample({ title, content, name, placement,item, season, ...props   }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <span variant="primary" onClick={handleShow} className="me-2  link-menu">
        {name}
      </span>
      <Offcanvas show={show} onHide={handleClose} placement={placement} {...props}>
        <Offcanvas.Header closeButton>
          <div className="card w-100 mt-3">
            <div className="card-body">
            {item[0]?.group_name}
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {item.map(item=> <Collapse item={item} season={season} />)}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffcanvasComponent({offcanvasContent ,title, name , item, season}) {

  return (

    <>
        {['end'].map((placement, idx) => (
        <OffCanvasExample
          key={idx}
          placement={placement}
          name={name}
          title={`Offcanvas ${title}`}
          content={offcanvasContent}
          item={item}
          className='offcanvas'
          season={season}
        />
      ))}</>

  );
}

export default OffcanvasComponent;
