import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

const MultiSelectDatePicker = ({ validDates,placeholder , selectedDates, setSelectedDates}) => {


  const isDateValid = (date) => {
    return validDates.some((validDate) => {
      const valid = new Date(validDate);
      const current = date.toDate();
      return valid.getFullYear() === current.getFullYear() &&
             valid.getMonth() === current.getMonth() &&
             valid.getDate() === current.getDate();
    });
  };

  return (
    <DatePicker
      value={selectedDates}
      onChange={setSelectedDates}
      placeholder={placeholder}
      multiple
      format="YYYY-MM-DD"
      mapDays={({ date }) => {
        let props = {};
        if (!isDateValid(date)) {
          props.disabled = true;
        }
        return props;
      }}
      plugins={[
        <DatePanel />
      ]}
    />
  );
};

export default MultiSelectDatePicker;
