import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faPlus  ,faTimes,faEye, } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import {Table, Pagination} from 'react-bootstrap';

import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { Loader } from '../../../assets/Loader/Loader';
import FilterIcon from "../../../assets/images/filter-ico.svg";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams } from '../../../redux/api';
import withLoader from '../../../components/PageLoader';


const TeamWithComponent = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const dispatch = useDispatch();
	const selector = useSelector(state=>state)
	const [show, setShow] = useState(false);
	const navigate = useNavigate()
	const handleClose = () => setShow(false);
	const [reload,setReload] = useState(false)
	const [teamList, setTeamList] = useState(selector.TEAMS);

	const routePath = process.env.REACT_APP_DEV_MODE
	// table
	const columns = [
		{ id: 'teams', label: 'Teams Name', minWidth: 100 },
		{ id: 'coach', label: 'Team Code', minWidth: 100 },
		{ id: 'grade', label: 'Grade/Age Group', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 50 },
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	   const handleUpdate=(id) =>{
		ApiGetAuth(`teams/update-status/${id}`).then((data) => {
			setReload((prev)=>!prev)
        }, (err) => {
            console.log(err);
        })
	   }


	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		dispatch(fetchTeams())
		ApiGetAuth('teams/get-teams').then(
			(success) => {
			  const Teamlist = success.data;
			  setTeamList(Teamlist)
			},
			(error) => {
			 console.log(error.message);
			}
		  );
	}, [reload]);
	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search Item" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img src={FilterIcon} alt=""  />Filters</button>
				</div>
				<Link to={`${window.location.pathname}/registeration-player`}><button className="btn btn-dark green"><FontAwesomeIcon icon={faPlus} /> Add Team</button></Link>
			</div>
			
				<Table className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<thead>
							<tr>
								{columns?.map((column) => (
									<td
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</td>
								))}
							</tr>
						</thead>
						<tbody>
							{teamList?.length ?
								teamList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
									return (
										<tr hover role="checkbox" tabIndex={-1} key={'1'}>
											{columns?.map((column) => {
												let coach = ''
												if (row.coach) {
													row.coach?.map(item => {
														coach += (item.firstname + item.lastname + " ")
													})
												}
												return (
													<>
														{column.id === 'action' ? (
															<td>
																<button className="btn " onClick={() => navigate(`${window.location.pathname}/edit-team`, { state: { id: row.id, row } })}>
																	<FontAwesomeIcon icon={faPenToSquare} />
																</button>
																<button className="btn " title="View" onClick={() => navigate( `${window.location.pathname}/view-team`, { state: { id: row.id, row } })}> <FontAwesomeIcon icon={faEye} /></button>
																{/* <Switch
																	name="checkedB"
																	color="primary"
																	checked={row.enabled}
																	onClick={()=>handleUpdate(row.id)}
																/> */}

															</td>
														) : column.id === 'teams' ? (
															<td key={column.id} align={column.align} className="table-user">
																<img alt=""  src={row?.team_logo ? row?.team_logo : require("../../../assets/images/usainvitationallogo.png")} /> {row?.team_name}
															</td>
														) : column.id === 'coach' ? (
															<td key={column.id} align={column.align} className="table-user">
																{row?.team_code}
															</td>
														) : column.id === 'player' ? (
															<td key={column.id} align={column.align} className="table-user">
																{row?.playersDetails?row?.playersDetails.length:0}
															</td>
														) : column.id === 'grade' ? (
															<td key={column.id} align={column.align} className="table-user">
																{row?.age_group || row?.grade_name}
															</td>
														) : (
															<td key={column.id} align={column.align}>
																{row?.seasoned}
															</td>
														)}
													</>


												);
											})}

										</tr>
									)
								}) :
								<tr hover role="checkbox" tabIndex={-1} key={'1'}>
									<td colSpan={7}>
										{teamList ==null ?<Loader/>:"No records found!"}
									</td>
								</tr>
							}
						</tbody>
					</Table>
				</Table>
				<Pagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={teamList?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<label> By Season </label>
										<input type="text" className="form-control" placeholder="Select Season" />
									</div>
									<div className="mb-4">
										<h3 className="title">By active</h3>
										<button className="btn activities">Active</button>
										<button className="btn activities">InActive</button>
									</div>
								</div>

							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center"> New User</h4>
										<div className="pop-form">
											<div className="mb-3">
												<label>First Name</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Last Name</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>

											<div className="mb-3">
												<label>E-mail</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>

											</div>
											<div className="mb-3">
												<label>Phone </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Sex </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Date Of Birth </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Generate a Password </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>

										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Save</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</>
			}
		</>
	);
};

const Team = withLoader(TeamWithComponent)

export default Team;