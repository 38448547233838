import React, { useState } from "react";
import CommonForm from "../../../components/CommonForm";
import {useParams } from "react-router-dom";

const CreateCoach = ({setRefresh,closeModal,roleid,teamid}) => {
	const {sportId} = useParams();
    const [values, setValues ] = useState({
        firstname:'',
        lastname:'',
        email:'',
        phoneNumber:'',
        gender:'',
        dob:'',
        role:''
        })
        const apiRoute = '/users/add-referee';
        const redirectPath = `/organizer/${sportId}/referee`;

      return (<CommonForm  roleid={roleid} teamid={teamid} values={values} type="referee" setValues={setValues} closeModal={closeModal}  dropDown={'games/get-games'} customCheck="Game" apiRoute={apiRoute}  redirectPath={redirectPath} pastYear={12} setRefresh={setRefresh}/>);
  
};

export default CreateCoach;
