import { reducer } from "./reducers";
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './persistConfig';
const redux = require("redux");
const applymiddleware = redux.applyMiddleware;
const thunk = require("redux-thunk").default;

// const store = redux.createStore(reducer, applymiddleware(thunk));
const persistedReducer = persistReducer(persistConfig, reducer);
const store = redux.createStore(persistedReducer, applymiddleware(thunk));
const persistor = persistStore(store);


store.subscribe(() => {

}); 

export  {store,persistor}