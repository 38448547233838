import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { ApiGet, Apipost } from '../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';
import { fetchSports } from '../../redux/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Dropdown = () => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState('');
  const [sportsList, setSportsList] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const selector = useSelector(state => state);
  const [loader, setLoader] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    ApiGet("games/get-sports").then(
      (data) => {
        setSportsList(data.data);
      }
    );
    let temp = [];
    selector.SPORTSLIST.map((e) => temp.push(e.id.toString()));
    setSelectedSports(temp);
  }, [selector.SPORTSLIST]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSports((prev) => [...prev, value]);
    } else {
      setSelectedSports(selectedSports.filter((e) => e !== value));
    }
  };

  const handleUpdate = () => {
    setLoader(true);
    if(selectedSports.length ==0){
      errorMessageNodify('Cannot Remove All the Sports')
      setLoader(false)
     return
   };
    Apipost('/auth/Update-sports-organizer', { id: JSON.stringify(selectedSports) }, "application/x-www-form-urlencoded", true).then(
      (response) => {
        dispatch(fetchSports());
        setLoader(false);
        successMessageNodify(response?.data.message);
        setShowDropdown(false);
      },
      (err) => {
        setLoader(false);
        errorMessageNodify(err.response?.data.message);
        return;
      }
    );
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="dropdown" ref={dropdownRef}>
        <button onClick={toggleDropdown} className="dropbtn">
          Choose sports <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" className="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg></span>
        </button>
        {showDropdown && (
          <div id="myDropdown" className="dropdown-content show">
            <input
              type="text"
              placeholder="Search.."
              id="myInput"
              value={filter}
              onChange={handleFilterChange}
            />
            {sportsList?.filter(link => link.sports_name.toLowerCase().includes(filter.toLowerCase()))?.map((link, index) => (
              <div key={index} className='games-section'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div className='w-25'>
                    <img src={link.icon} className='sports-img' alt={link.sports_name} />
                  </div>
                  <div className='w-75'>
                    <a key={link.href} href={link.href}>{link.sports_name}</a>
                  </div>
                </div>
                <div className='w-25'>
                  <input
                    checked={selectedSports.includes(link.id.toString())}
                    type="checkbox"
                    name="day"
                    value={link.id}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ))}
            <div className='games-section-sports'>
              <button className="btn btn-dark savesubmit" onClick={handleUpdate} disabled={loader}>
                {loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
