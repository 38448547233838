import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { S3, PutObjectCommand } from "@aws-sdk/client-s3";
import fs from "fs";
import { Buffer } from 'buffer';

export default function VideoInput(props) {
  const { width, height } = props;
  const inputRef = React.useRef();
  const [source, setSource] = React.useState();
  var id = localStorage.getItem("id");


  const BucketName = process.env.REACT_APP_BUCKET_NAME_HERE;


  const s3Client = new S3({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: "https://nyc3.digitaloceanspaces.com",
    region: "us-east-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_BUCKET_KEY_ID_HERE,
      secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_HERE
    }
  });

  const handleFileChange = async (event) => {

    let OrginalFile = event.target.files[0];
    let reader = new FileReader()
    reader.readAsDataURL(OrginalFile)
    reader.onload = async () => {
      let myFile = OrginalFile.name.split(".");
      const fileType = myFile[myFile.length - 1];
      const uniqueSuffix = Date.now() + '-' + Math.round(Math.random() * 1E9)
      let blob = `organizer/${id}/videos/${uniqueSuffix}.${fileType}`;
      // let fileurl = URL.createObjectURL(event.target.files[0]);
      let fileurl = reader.result;
      // setSource(reader.result)
      const params = {
        Bucket: BucketName,
        Key: blob,
        Body: Buffer.from(fileurl, 'base64'),
        ACL: "public-read",
        ContentType: OrginalFile.type,
      };
      const data = await s3Client.send(new PutObjectCommand(params));
      setSource('https://teamconnect-media.nyc3.digitaloceanspaces.com/'+params.Key);
      return;
    };
    reader.onerror = function (error) {
    }
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };


  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4"
      />
      <div className="form-group">
        <input className="form-control" name="title" value={source} />
        <span className="icon file-hide" onClick={handleChoose}>
          <FontAwesomeIcon icon={faLink} />
        </span>
      </div>
      {source && (
        <video
          className="custom"
          width={width}
          height={height}
          controls
          src={source}
        />
      )}
    </div>
  );
}
