import React, { forwardRef, useImperativeHandle } from 'react';
import Table from 'react-bootstrap/Table';

const DynamicTable = forwardRef(({ headers, data, shortby }, ref) => {
  // Define a consistent width for table columns
  const columnWidth = '150px';

  // Style for wrapping content
  const cellStyle = {
    width: columnWidth,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  };

  // Function to export data to CSV
  const exportToCSV = () => {
    if (!Array.isArray(headers)) {
      console.error("Headers is not an array:", headers);
      return;
    }

    let csvContent = [];

    if (Array.isArray(data)) {
      // For flat array of data, add headers once
      csvContent.push(headers.join(','));
      csvContent.push(
        ...data.map(row => headers.map(header => row[header] || '').join(','))
      );
    } else if (typeof data === 'object' && data !== null) {
      // For grouped data, add headers before each group's data
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const group = data[key];
          csvContent.push(headers.join(',')); // Add headers for each group
          csvContent.push(
            ...group.map(row => headers.map(header => row[header] || '').join(','))
          );
        }
      }
    } else {
      console.error("No data available for export");
      return;
    }

    csvContent = csvContent.join('\n');

    // Create a blob and download the CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Expose the exportToCSV function to parent components
  useImperativeHandle(ref, () => ({
    exportToCSV
  }));

  return (
    <div>
      {Array.isArray(data) ? (
        <Table striped className="w-100">
          <thead className="table-secondary">
            <tr>
              {headers.map((header, index) => (
                <th key={index} style={cellStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td key={colIndex} style={cellStyle}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        Object.keys(data).map((key, index) => (
          <div key={index}>
            <Table striped className="w-100">
              <thead className="table-secondary">
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={cellStyle}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data[key].map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex} style={cellStyle}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))
      )}
    </div>
  );
});

export default DynamicTable;
