import React from 'react';
import {  useParams } from "react-router-dom";
import { Leagues } from '../../Leagues/Leagues';
import BreadcrumbComponent from '../Breadcrumb/Breadcrumb';
import withLoader from '../../../components/PageLoader';

export const sports = ['', "BasketBall", "Football"]
const EventWithLoader = () => {
	const { sportId } = useParams();
	return (
		<>
			<BreadcrumbComponent />
			<div className="organizer_events">
				<Leagues sportId={sportId} breadCrum={`Games/${sports[sportId]}`} />	
			</div>
		</>
	);
};

const Event = withLoader(EventWithLoader);

export default Event;