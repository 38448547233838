import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >

      <Modal.Body className='p-3 text-center'>
      {props.price > 0?<span className='text-danger text-center'> In order to publish this season, please make all required payments!</span> :<> Do You Really Want to Release This Season ?</>} 

      </Modal.Body>
      <Modal.Footer className='text-center'>
      <Button variant='danger' onClick={props.onHide}>Cancel</Button>
      <Button variant='success' disabled={props.price > 0} onClick={props.handlePublish}>Publish</Button>
      </Modal.Footer>
     
       
    </Modal>
  );
}