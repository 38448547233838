import React from 'react';
const Sports = ({sportsList,selectedSports,loading,handleChange}) => {
    return (
        <div>
            <div id="sports-choose">
                <div className="">
                    <div className="row">
                       
                        <div className="">
                            <div className="continue-btn sportlist">
                                
                                <div className="panel_sports create-form-inner mt-4">
                                   <h5> Update Selected Sports From Below:</h5> 
                                    <div className="form-group mb-3">
                                        <div className="game-seletcted">
                                            {sportsList?.length ? (
                                                sportsList?.map((item, i) => (
                                                    <label key={i} className="custom-check">
                                                        {item.sports_name}
                                                        <input
                                                            checked={selectedSports.filter(value=>value ==item.id).length ? true: false}
                                                            type="checkbox"
                                                            name="day"
                                                            value={item.id}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <span>{
										
									}</span>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default Sports;