import React, {useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import {  Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import '../../../assets/css/payment.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    score_one: Yup.string() .required('Score_one cannot be empty').min(0, 'Score_One must be at least 0 characters long')
    .max(10000, 'Score_One cannot be longer than 100 characters'),
    score_two: Yup.string() .required('Score_two cannot be empty').min(0, 'Score_Two must be at least 0 characters long')
    .max(10000, 'Score_Two cannot be longer than 100 characters'),
  
});

const AddScore =({value,handleHide ,setRefresh,data, setData, teamOne ="", teamTwo="",groupid})=>{
    const formik = useFormik({
        initialValues: value,
        validationSchema,
        onSubmit: (values) => {
            const {score_one,score_two,id}=values
            setLoading(true);
            Apipost('/games/add-match',{score_one,score_two,id,type:'score',game_group_id:groupid}, "application/x-www-form-urlencoded", true).then(
            (response) => {
               successMessageNodify(response?.data.message)
               setLoading(false);
               updateState(data,id,score_one,score_two,"score_one","score_two")
               setRefresh(prev=>!prev)
               handleHide()
            },
            (err) => {
                errorMessageNodify(err.response?.data.message);
                setLoading(false);
        return;
            })
        }
    })
    const [loading, setLoading] = useState(false);
    const [values,setValues] = useState(value)

    const updateState = (data, id, value1, value2, key1, key2) => {
        const newState = data.map(obj => {
          if (obj.id === id) {
            return { ...obj, [key1]: value1,[key2]:value2 };
          }
          return obj;
        });
        setData(newState);
      };

    return (
        <form onSubmit={formik.handleSubmit} className='create-form-inner season-popup'>
          
            <div className="row d-flex justify-content-around update-score ">
            <h5 className='text-left mb-4'>Score</h5>
               <div className="form-group col-md-6">
               <span className='w-25'>
                    <label className="form-label" for="">{teamOne || values?.Team1?.team_name}:</label>
                    <input className='w-100' name='score_one' value={formik?.score_one} 
                    type='number' min={0}
                    max={10000}
                    onChange={formik.handleChange}
                    {...formik.getFieldProps('score_one')}
                    />
                </span>
                <div className="issue_emrollment">
                {formik.touched.score_one && formik.errors.score_one && (
                                                <span className="text-danger">{formik.errors.score_one}</span>
                                            )}
                </div>                            
               </div>
               <div className='form-group col-md-6'>
               <span className='w-25'>
                <label className="form-label" for="">{teamTwo || values?.Team2?.team_name}:</label>
                    <input className='w-100' name='score_two' value={formik?.score_two}
                     type='number' min={0}
                     max={10000}
                     onChange={formik.handleChange}
                     {...formik.getFieldProps('score_two')}
                    />
                </span>
                {formik.touched.score_two && formik.errors.score_two && (
                                                <span className="text-danger">{formik.errors.score_two}</span>
                                            )}
               </div>
              
            </div>
            <button  className=' btn btn-primary m-2 ms-0 mt-4 uscore' disabled={loading}> 
                {
                              loading ? <FontAwesomeIcon icon={faCircleNotch} spin  /> : 'Update'
                }
            </button>
        </form>
    )
}

export {AddScore}