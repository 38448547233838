export function convertTo12HourFormat(time24) {
    if  (!time24) return "00:00" ;
    // Split the input string into hours and minutes
    var timeParts = time24.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Determine AM/PM
    var period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (00:00) as 12 AM

    // Format the time as HH:MM AM/PM
    var time12 = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + period;
    
    return time12;
}

export function generateImage(text, fontSize = 30, fontFamily = 'Arial') {
    const initials = (text?.split(' ').map(word => word.charAt(0).toUpperCase()).join(''))?.slice(0, 2);
    const svgString = `
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
            <!-- Background rectangle -->
            <rect width="100%" height="100%" fill="#edcdca"/>
            
            <!-- Text element -->
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"
                font-size="${fontSize}" font-family="${fontFamily}" fill="white">
                ${initials}
            </text>
        </svg>
    `;

    // Encode SVG string to data URL
    const encodedData = window.btoa(unescape(encodeURIComponent(svgString)));
    const dataURL = `data:image/svg+xml;base64,${encodedData}`;
    
    return dataURL;
}

const addDummyTeams = (teams , count )=>{
  let result =[...teams]
  while(count){
result.push({AP_DIFF:0,
PCT:"0.00",
draws:"0",
losses:"0",
team_id:0,
team_logo:"",
team_name:"",
wins:"0"})
count--;
  }
  
  return result
}

export const genMatchesByTeamCount = (nTeams, seedsno=1, teams) => {
    let matchArray = [];
     let  teamsInPattern = swapArrayElements(addDummyTeams(teams,nTeams-teams.length ))
    while (nTeams > 1) {
      nTeams = (nTeams + 1) >> 1;
      let matches = [];
      for (let i = 0,j=0; i < nTeams; ++i) {
        const match = {
          id: seedsno,
          date: new Date().toDateString(),
          teams: [
            { id: teamsInPattern[j]?.team_id || null, name: teamsInPattern[j]?.team_name||"" }, // You can replace null with team data if available
            { id: teamsInPattern[j+1]?.team_id ||null, name: teamsInPattern[j+1]?.team_name || "" }  // You can replace null with team data if available
          ]
        };
        j+=2
        seedsno++;
        matches.push(match);
      }
      const roundTitle = matchArray.length + 1;
      teamsInPattern=[]
      matchArray.push({ title: `Round ${roundTitle}`, seeds: matches });
    }
    return matchArray;
  };

  function swapArrayElements(arr) {
    let swappedArray = [];
    for (let i = 0; i < Math.ceil(arr.length / 2); i++) {
        swappedArray.push(arr[i]);
        if (i !== arr.length - i - 1) {
            swappedArray.push(arr[arr.length - i - 1]);
        }
    }
    return swappedArray;
}
  
export const genMatchesSingleRound = (nTeams,startIndexSecondRound, teams) => {
  let matchArray = [];
  let matches = [];
  let  teamsInPattern = swapArrayElements(teams)
  // Calculate the number of matches required for the given teams
  const numMatches = Math.floor(nTeams / 2);
  let toIndex = startIndexSecondRound
  for (let i = 0,j=0 ; i < numMatches; ++i) {
    const match = {
      id: i+1,
      fromKey:i+1,
      toKey:toIndex,
      date: new Date().toDateString(),
      teams: [
        { id: teamsInPattern[j].team_id || null , name: teamsInPattern[j].team_name || null }, // You can replace null with team data if available
        { id: teamsInPattern[j+1].team_id || null, name: teamsInPattern[j+1].team_name || null}  // You can replace null with team data if available
      ]
    };
    j+=2
    toIndex++
    matches.push(match);
  }

  matchArray.push({ title: `Starter `, seeds: matches });

  return matchArray;
};



