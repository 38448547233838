import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import { ApiGetAuth } from '../../../helpers/apiCall';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import NoData from '../../../components/NoData';
import Loader from '../../Mainloader.js/Loader';

const PaymentHistory = () => {
    const { sportId } = useParams()
    const [paymentHistory, setPaymentHistory] = useState(null)
    const [allData, setAllData] = useState([])
    const [values , setValues]= useState({selected:"All",startDate:'', endDate:'',search:''})
    const navigate = useNavigate()
    const getPaymentHistory = () => {
        ApiGetAuth(`payment/payment-history/all`).then(
            (success) => {
                if (sportId == 'all') {
                    setTimeout(()=>{
                        setPaymentHistory(success.data)
                    },[3000])
                    
                    setAllData(success.data)
                } else {
                    if (success.data?.length) {
                        let temp = success.data.filter(item => item.sportsId == sportId)
                        setTimeout(()=>{
                            setPaymentHistory(temp)
                        },[1000])
                       // setPaymentHistory(temp)
                        setAllData(temp)
                    }
                }
                return;
            }, (err) => {
                return;
            }
        )
    }

    const filterArrayByValue = (array, startDate, endDate, value) => {
        return array.filter(obj => {
            if (startDate && moment(obj.transaction_date).isBefore(moment(startDate))) {
                return false;
            }
            if (endDate && moment(obj.transaction_date).isAfter(moment(endDate))) {
                return false;
            }
            // Check if the value is present in any string property of the object
            if (value !== '') {
                for (const key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        const element = obj[key];
                        if (typeof element === 'string' && element.includes(value)) {
                            return true;
                        }
                    }
                }
                return false; // If value is not found in any property, exclude the object
            }
            return true; // Include the object if no value filter is applied
        });
    };

    
    
    
    useEffect(() => {
        getPaymentHistory()
        setValues({selected:"All",startDate:'', endDate:'',search:''})
    }, [sportId])

    useEffect(() => {
        if(allData?.length > 0){
            if (values.selected === 'All') {
                let {startDate, endDate,search} = values
                 setPaymentHistory(filterArrayByValue(allData,startDate, endDate,search));
             } else if (values.selected === 'failed') {
                 let {startDate, endDate,search} = values
                 let temp = allData.filter(item => item.payment_status !== 'Paid');
                 setPaymentHistory( filterArrayByValue(temp,startDate, endDate,search));
             } else if (values.selected === 'paid') {
                 let {startDate, endDate,search} = values
                 let temp = allData.filter(item => item.payment_status === 'Paid');
                 setPaymentHistory( filterArrayByValue(temp,startDate, endDate,search));
             }
        }
       
    }, [values]);
    
    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to="#" role="button" tabIndex="0">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                        <NavLink to="/organizer/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <NavLink to="/organizer/dashboard">Payments</NavLink>
                    </li>
                    {/* <PlayModal /> */}
                </ol>
            </nav>

            <div className="dahboard_inner_white_background">
                <Row>
                    <Col xxl="12" md="12" xs="12">
                        <div className="payment_history_page">
                            <div className="payment_list_heading">
                                <div className="payment_list_heading_search_button">
                                    <h4>Payment List</h4>
                                    <div className="team-search ">
                                        
                                        <input type="text" className="form-control " id="exampleFormControlInput1" placeholder="Search"   onChange={(e)=>setValues({...values, search:e.target.value})} />
                                        <div className="s-icon">
                                        </div>
                                    </div>
                                </div>

                                <div className='select-payments d-flex'>
                                   <div className='pendind-paments-details'>
                                   <Form.Select aria-label="Default select example" value={values.selected}  onChange={(e)=>setValues({...values, selected:e.target.value})}>
                                        <option value='All'>All</option>
                                        <option value='paid'>Successful</option>
                                        <option value='failed'>Failed</option>
                                    </Form.Select>
                                   </div>

                                    <div className="all_games_date_range">
                                        <div className='input-right-icon'>
                                            <input
                                                className='form-control'
                                                type='date'
                                                name='start_date'
                                                value={values.startDate}
                                                onChange={(e)=>setValues({...values, startDate:e.target.value})}
                                            />
                                            <span>To</span>
                                            <input
                                                className='form-control'
                                                type='date'
                                                name='end_date'
                                                value={values.endDate}
                                                onChange={(e)=>setValues({...values, endDate:e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="payment_listing_background">
                            {
                                paymentHistory == null ?<>
                                <Loader/>
                                </>:<>
                                {paymentHistory.length > 0?<>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Invoice ID</th>
                                            <th>Transaction ID</th>
                                            <th>DATE</th>
                                            <th>REFERENCE</th>
                                            <th>STATUS</th>
                                            <th>PAYMENT METHOD</th>
                                            <th>TOTAL</th>
                                            <th className='text-center'>View Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentHistory.length > 0 ? <>{paymentHistory?.map(item => <tr>
                                            <td>{item.id + 1000}</td>
                                            <td>{item.stripe_transaction_id}</td>
                                            <td>{moment(new Date(item.transaction_date)).format('LLL')}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                <div className={item.payment_status == 'Paid' ? `pending_color_column text text-success` : ` pending_color_column`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                        <circle cx="5" cy="5" r="5" fill={item.payment_status == 'Paid' ? '#00e600' : '#F05829'} />
                                                    </svg>
                                                    {item.payment_status}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="svg_payment_detail_listing">
                                                    {JSON.parse(item.card_details)?.brand == 'visa' ? <svg xmlns="http://www.w3.org/2000/svg" width="88" height="26" viewBox="0 0 88 26" fill="none">
                                                        <g clip-path="url(#clip0_250_3)">
                                                            <path d="M32.2656 25.2584L36.7355 0.877441H43.7196L39.3894 25.2584H32.2656Z" fill="#3C58BF" />
                                                            <path d="M32.2656 25.2584L37.9926 0.877441H43.7196L39.3894 25.2584H32.2656Z" fill="#293688" />
                                                            <path d="M64.6722 1.14805C63.2754 0.606251 61.0405 0.0644531 58.2468 0.0644531C51.2627 0.0644531 46.2341 3.45069 46.2341 8.32689C46.2341 11.984 49.7262 13.8803 52.5198 15.0994C55.3135 16.3184 56.1516 17.1311 56.1516 18.2147C56.1516 19.8401 53.9167 20.6528 51.9611 20.6528C49.1674 20.6528 47.6309 20.2465 45.2563 19.2983L44.2786 18.892L43.3008 24.4454C44.977 25.1227 48.05 25.7999 51.2627 25.7999C58.6659 25.7999 63.5548 22.4137 63.5548 17.2666C63.5548 14.4221 61.7389 12.2549 57.5484 10.4941C55.0341 9.27503 53.4976 8.59779 53.4976 7.37874C53.4976 6.29514 54.7548 5.21154 57.5484 5.21154C59.923 5.21154 61.5992 5.61789 62.8563 6.15969L63.5548 6.43059L64.6722 1.14805Z" fill="#3C58BF" />
                                                            <path d="M64.6722 1.14805C63.2754 0.606251 61.0405 0.0644531 58.2468 0.0644531C51.2627 0.0644531 47.4913 3.45069 47.4913 8.32689C47.4913 11.984 49.7262 13.8803 52.5198 15.0994C55.3135 16.3184 56.1516 17.1311 56.1516 18.2147C56.1516 19.8401 53.9167 20.6528 51.9611 20.6528C49.1674 20.6528 47.6309 20.2465 45.2563 19.2983L44.2786 18.892L43.3008 24.4454C44.977 25.1227 48.05 25.7999 51.2627 25.7999C58.6659 25.7999 63.5548 22.4137 63.5548 17.2666C63.5548 14.4221 61.7389 12.2549 57.5484 10.4941C55.0341 9.27503 53.4976 8.59779 53.4976 7.37874C53.4976 6.29514 54.7548 5.21154 57.5484 5.21154C59.923 5.21154 61.5992 5.61789 62.8563 6.15969L63.5548 6.43059L64.6722 1.14805Z" fill="#293688" />
                                                            <path d="M76.6861 0.877441C75.0099 0.877441 73.7527 1.01289 73.0543 2.63829L62.5781 25.2584H70.121L71.5178 21.1949H80.4575L81.2956 25.2584H88.0004L82.1337 0.877441H76.6861ZM73.4734 17.1314C73.8924 15.9124 76.267 9.95258 76.267 9.95258C76.267 9.95258 76.8257 8.46263 77.2448 7.51448L77.6638 9.81712C77.6638 9.81712 79.0607 15.9124 79.34 17.2669H73.4734V17.1314Z" fill="#3C58BF" />
                                                            <path d="M78.3623 0.877441C76.6861 0.877441 75.4289 1.01289 74.7305 2.63829L62.5781 25.2584H70.121L71.5178 21.1949H80.4575L81.2956 25.2584H88.0004L82.1337 0.877441H78.3623ZM73.4734 17.1314C74.0321 15.7769 76.267 9.95258 76.267 9.95258C76.267 9.95258 76.8257 8.46263 77.2448 7.51448L77.6638 9.81712C77.6638 9.81712 79.0607 15.9124 79.34 17.2669H73.4734V17.1314Z" fill="#293688" />
                                                            <path d="M19.4156 17.9439L18.7172 14.4222C17.4601 10.3587 13.4093 5.88889 8.93945 3.72169L15.2252 25.3937H22.768L34.0823 1.0127H26.5395L19.4156 17.9439Z" fill="#3C58BF" />
                                                            <path d="M19.4156 17.9439L18.7172 14.4222C17.4601 10.3587 13.4093 5.88889 8.93945 3.72169L15.2252 25.3937H22.768L34.0823 1.0127H27.9363L19.4156 17.9439Z" fill="#293688" />
                                                            <path d="M0 0.877441L1.25714 1.14834C10.1968 3.18009 16.3429 8.32718 18.7175 14.4224L16.2032 2.90919C15.7841 1.28379 14.527 0.877441 12.9905 0.877441H0Z" fill="#FFBC00" />
                                                            <path d="M0 0.877441C8.93968 2.90919 16.3429 8.19173 18.7175 14.287L16.3429 4.67004C15.9238 3.04464 14.527 2.09649 12.9905 2.09649L0 0.877441Z" fill="#F7981D" />
                                                            <path d="M0 0.877441C8.93968 2.90919 16.3429 8.19173 18.7175 14.287L17.0413 9.00443C16.6222 7.37903 16.0635 5.75363 14.1079 5.07638L0 0.877441Z" fill="#ED7C00" />
                                                            <path d="M26.4007 17.1314L21.6515 12.5261L19.4166 17.6732L18.8579 14.287C17.6007 10.2235 13.5499 5.75362 9.08008 3.58643L15.3658 25.2584H22.9086L26.4007 17.1314ZM39.3912 25.2584L33.3848 19.2986L32.2674 25.2584H39.3912ZM55.4547 16.8605C56.0134 17.4023 56.2928 17.8087 56.1531 18.3504C56.1531 19.9758 53.9182 20.7885 51.9626 20.7885C49.169 20.7885 47.6325 20.3822 45.2579 19.434L44.2801 19.0277L43.3023 24.5811C44.9785 25.2584 48.0515 25.9356 51.2642 25.9356C55.734 25.9356 59.3658 24.7166 61.461 22.5494L55.4547 16.8605ZM63.5563 25.2584H70.1214L71.5182 21.1949H80.4579L81.296 25.2584H88.0007L85.6261 15.3706L77.2452 7.51447L77.6642 9.68166C77.6642 9.68166 79.061 15.7769 79.3404 17.1314H73.4737C74.0325 15.7769 76.2674 9.95256 76.2674 9.95256C76.2674 9.95256 76.8261 8.46262 77.2452 7.51447" fill="#051244" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_250_3">
                                                                <rect width="88" height="26" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="71" height="43" viewBox="0 0 71 43" fill="none">
                                                        <path d="M21.6923 42.3677C33.6726 42.3677 43.3845 32.8834 43.3845 21.1839C43.3845 9.48433 33.6726 0 21.6923 0C9.71196 0 0 9.48433 0 21.1839C0 32.8834 9.71196 42.3677 21.6923 42.3677Z" fill="#FF6041" />
                                                        <path d="M49.0165 42.3677C60.9968 42.3677 70.7087 32.8834 70.7087 21.1839C70.7087 9.48433 60.9968 0 49.0165 0C37.0362 0 27.3242 9.48433 27.3242 21.1839C27.3242 32.8834 37.0362 42.3677 49.0165 42.3677Z" fill="#FFD941" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3534 4.88847C37.8575 6.86467 39.8773 9.36283 41.2648 12.2C42.6523 15.0371 43.3725 18.1415 43.3725 21.2856C43.3725 24.4297 42.6523 27.534 41.2648 30.3712C39.8773 33.2083 37.8575 35.7065 35.3534 37.6827C32.8305 35.7074 30.7943 33.2038 29.3951 30.3569C27.996 27.51 27.2695 24.3925 27.2695 21.2347C27.2695 18.0768 27.996 14.9593 29.3951 12.1124C30.7943 9.26554 32.8305 6.86381 35.3534 4.88847Z" fill="#FFD941" />
                                                        <mask id="mask0_251_17" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="27" y="4" width="17" height="34">
                                                            <path d="M35.3553 4.88847C37.8595 6.86467 39.8792 9.36283 41.2668 12.2C42.6543 15.0371 43.3744 18.1415 43.3744 21.2856C43.3744 24.4297 42.6543 27.534 41.2668 30.3712C39.8792 33.2083 37.8595 35.7065 35.3553 37.6827C32.8325 35.7074 30.7963 33.2038 29.3971 30.3569C27.9979 27.51 27.2715 24.3925 27.2715 21.2347C27.2715 18.0768 27.9979 14.9593 29.3971 12.1124C30.7963 9.26554 32.8325 6.86381 35.3553 4.88847Z" fill="white" />
                                                        </mask>
                                                        <g mask="url(#mask0_251_17)">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3613 8.9624H49.2252V11.7122H23.3613V8.9624ZM23.3613 14.3602H49.2252V17.11H23.3613V14.3602ZM23.3613 19.8599H49.2252V22.6097H23.3613V19.8599ZM23.3613 25.2577H49.2252V28.0075H23.3613V25.2577ZM23.3613 30.6555H49.2252V33.4053H23.3613V30.6555Z" fill="#FF6041" />
                                                        </g>
                                                    </svg>}
                                                    <span>...{JSON.parse(item.card_details)?.last4}</span>
                                                </div>
                                            </td>
                                            <td className='text-left-amount'>${(item.order_amount / 100).toFixed(2)}</td>
                                            <td className='text-center' onClick={() => navigate(`/organizer/ALL/payment-detail/${item.id}`)}>
                                               <span className='view-payment-details'> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
                                                    <path d="M0 7.70708C1.78843 5.27476 3.96981 3.26844 6.57656 1.73829C8.03632 0.881413 9.60013 0.282208 11.2925 0.0759448C13.2835 -0.167042 15.1864 0.182443 17.0103 0.989747C18.9622 1.85336 20.6655 3.08237 22.225 4.52622C23.1242 5.35861 23.9486 6.25712 24.7131 7.21254C24.8398 7.37106 24.9511 7.54182 25.0693 7.70708V8.29343C23.2631 10.7441 21.0646 12.7651 18.4284 14.2971C16.9932 15.1313 15.4587 15.7146 13.8007 15.9209C11.8017 16.17 9.89024 15.8211 8.05835 15.0102C6.09916 14.1429 4.38968 12.9083 2.82587 11.4565C1.9341 10.6284 1.11517 9.73544 0.355606 8.78675C0.229522 8.62945 0.118127 8.45869 0 8.29343C0 8.09819 0 7.90233 0 7.70708ZM12.68 14.2971C12.8942 14.3155 13.2437 14.2702 13.5895 14.223C15.1405 14.0101 16.558 13.4286 17.8904 12.6268C19.8747 11.4327 21.542 9.87621 23.044 8.13001C23.1505 8.00638 23.1015 7.9415 23.0183 7.84541C22.14 6.82388 21.1992 5.8654 20.1618 5.00485C18.8116 3.88478 17.3598 2.93119 15.703 2.31608C14.3607 1.81786 12.9824 1.5865 11.5532 1.76645C10.2942 1.92497 9.11293 2.33811 7.99164 2.92263C5.64807 4.1449 3.73661 5.88682 2.02714 7.87172C1.93594 7.97761 1.95124 8.04065 2.03509 8.13797C3.68214 10.0445 5.51586 11.7295 7.7462 12.9438C9.24024 13.7579 10.82 14.2806 12.6806 14.2977L12.68 14.2971Z" fill="#484848" />
                                                    <path d="M12.5422 12.9882C9.80137 12.938 7.5643 10.8576 7.55084 8.01403C7.53798 5.24141 9.71385 3.02331 12.5397 3.02637C15.3491 3.02943 17.5182 5.22672 17.5194 7.99934C17.5206 10.7597 15.3638 12.9221 12.5422 12.9882ZM15.802 7.99934C15.802 6.19316 14.3477 4.74013 12.5391 4.73891C10.7274 4.73768 9.26888 6.18581 9.26644 7.98955C9.26399 9.81042 10.7182 11.2641 12.5416 11.2622C14.349 11.2604 15.802 9.80675 15.802 7.99934Z" fill="#484848" />
                                                </svg></span>
                                            </td>
                                        </tr>)}</> : <> </>}

                                    </tbody>
                                </Table>
                            </>:<> 
                            <div className="No-game"> <NoData message='No Payment History ! ' helpText='As soon as you create new games it would populate on the current Day.' /></div>
                            </> }
                                 </>
                            }
                           
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PaymentHistory;