import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { ApiGetAuth } from '../../helpers/apiCall';
import { useParams } from 'react-router';
import NoData from '../NoData';

const RosterTable = ({ divisions, season }) => {
    const [players, setPlayers] = React.useState([]);
    const [teams, setTeams] = React.useState([]);
    const {sportId} = useParams();
    const [selectedDivision, setSelectedDivision] = React.useState(null);  // State for selected division object
    const [selectedTeam, setSelectedTeam] = React.useState(null);          // State for selected team object

    // Function to fetch teams based on division selection
    const handleGetTeams = (divisionId) => {
        const selectedDivObj = divisions.find(div => div.id === parseInt(divisionId));  // Find the full division object
        setSelectedDivision(selectedDivObj);  // Set the full selected division object
        if (divisionId > 0) {
            ApiGetAuth(`games/get-gameDetails/${divisionId}`).then(
                (data) => {
                    if (data?.data) {
                        setTeams(data?.data[0].teams);
                        setPlayers([]);  // Clear players list when division changes
                    }
                }
            );
        }
    };

    // Function to fetch players based on team selection
    const handleGetPlayers = (teamId) => {
        const selectedTeamObj = teams.find(team => team.id === parseInt(teamId));  // Find the full team object
        setSelectedTeam(selectedTeamObj);  // Set the full selected team object
        if (teamId > 0) {
            ApiGetAuth(`games/get-players?id=${teamId}`).then(
                (data) => {
                    if (data?.players) {
                        setPlayers(data.players);
                    }
                }
            );
        }
    };

    // Effect to handle default division and team selection
    useEffect(() => {
        if (divisions?.length > 0) {
            const defaultDivision = divisions[0];  // Select first division by default
            handleGetTeams(defaultDivision.id);    // Fetch teams for default division

            // Optional: You can also select the first team if teams data is available after fetching
            setSelectedDivision(defaultDivision);
        }
    }, [divisions]);  // Only runs when divisions are loaded

    // Effect to handle default team and players selection once teams are available
    useEffect(() => {
        if (teams.length > 0) {
            const defaultTeam = teams[0];  // Select first team by default
            handleGetPlayers(defaultTeam.id);  // Fetch players for default team

            setSelectedTeam(defaultTeam);
        }
    }, [teams]);  // Only runs when teams are loaded for the default division

    return (
        <div className="seasonDashboard_divison_group">
            <div className="divison_group roster-table">
                <div className="d-flex justify-content-between align-items-baseline">
                    <div className="heading">
                        <h4> Team Roster</h4>
                    </div>
                    <div>
                        <div className="max-weight">
                        
                            {
                                selectedDivision?.id > 0 ? (
                                    <h5><b>Max Weight</b>: {selectedDivision.max_weight === 999999 ? 'Unlimited' : selectedDivision.max_weight}</h5>
                                ) : null
                            }
                            <div>
                   
                                                  
                    </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-1">
                        <div>
                            <select className="form-select form-select-sm" aria-label="Select Division" value={selectedDivision?.id || 0} onChange={(e) => handleGetTeams(e.target.value)}>
                                <option value={0}>Select Division</option>
                                {divisions?.map(item => <option key={item?.id} value={item?.id}>{item?.group_name}</option>)}
                            </select>
                        </div>
                        <div>
                            <select className="form-select form-select-sm" aria-label="Select Teams" value={selectedTeam?.id || 0} onChange={(e) => handleGetPlayers(e.target.value)} disabled={teams.length === 0}>
                                <option value={0}>Select Teams</option>
                                {teams.map(item => <option key={item?.id} value={item?.id}>{item?.team_name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="max-weight">
                {season.id > 0 ? <>
                        <button className="btn btn-primary"  style={{padding:'2px 10px'}} onClick={() => {
                             const url = `/roaster/${sportId}/${season.season_name.replace(/ /g, "_")}/${season.id}`;
                             window.open(url, '_blank'); // Opens the URL in a new tab
                           }}
                            > Roster with Minimum Play Sheet
                             </button>
                                                </> :null}
                    {
                        selectedDivision?.id > 0 && (
                            <p className='text-muted'>
                                <b>Note:</b> For players in {selectedDivision.group_name}, the maximum allowable weight is {selectedDivision.max_weight === 999999 ? 'Unlimited' : (selectedDivision.max_weight + ' lbs')}.
                                {selectedDivision.max_weight !== 999999
                                    ? ` If a player's weight falls between ${selectedDivision.max_weight - 5} - ${selectedDivision.max_weight} lbs, they must be reweighed during mid-season to ensure they meet the requirements.`
                                    : ' Players do not need to be reweighed.'}
                                    
                            </p>
                            
                        )
                    }
                   
                </div>
                <div className="table_list mt-3">
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Weight</th>
                                <th>Mid-Season</th>
                                <th>X</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                players.length >0 ? <>{
                                    players.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.firstname}</td>
                                    <td>{item.weight}</td>
                                    <td>
                                        {selectedDivision?.max_weight !== 999999 && item.weight >= (selectedDivision.max_weight - 5) && item.weight <= selectedDivision.max_weight
                                            ? <input type='checkbox' checked />
                                            : ''
                                        }
                                    </td>
                                    <td>
                                        {selectedDivision?.max_weight !== 999999 && item.weight > selectedDivision.max_weight
                                            ? 'X'
                                            : ''
                                        }
                                    </td>
                                </tr>
                            ))
                                } </> : <NoData message='No players have been added to this team yet.' helpText="Players will be listed here once added" />
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default RosterTable;
