import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function  Poploader({content,loader}) {
    return (

<>{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : content}</>
      );
}

export default Poploader;