import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faLink } from "@fortawesome/free-solid-svg-icons";

import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { Link } from "react-router-dom";
import moment from "moment";
import { objectValuesNonEmpty, onTextChange } from "../../../helpers/commonFunction";
import {
	errorMessageNodify,
	successMessageNodify,
} from "../../../helpers/notify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditPlayer = ({ playerDetails, setShowModal }) => {
	const [loader , setLoader] = useState(false)
	const [image, setImage] = useState("");
	const [playerDeatils, setPlayerDeatils] = useState(playerDetails);
	const [gradeOptions, setGradeOptions] = useState([])
	const [teamList, setTeamList] = useState(null);
	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
		}
	};

	const handleSubmit = (e) => {
		const { height, profile_pic, ...values } = playerDeatils
		if (!objectValuesNonEmpty(values)) {
			errorMessageNodify("Please Fill All the Values ");
		}
		setLoader(true)
		Apipost(
			"/users/update-user",
			{ ...playerDeatils, ["role"]: 4, image },
			"multipart/form-data",
			true
		).then(
			(success) => {
				successMessageNodify(success?.data?.message);
				setShowModal(false)
				setLoader(false)
			},
			(err) => {
				errorMessageNodify(err?.response?.data.message);
				setLoader(false)
			}
		);
	};

	useEffect(() => {
		ApiGetAuth("teams/get-teams").then(
			(data) => {
				setTeamList(data?.data);
			},
			(err) => {
				errorMessageNodify(err.response.data.message);
			}
		);
		ApiGetAuth('teams/get-grades').then((data) => {
			if (data.data) {
				setGradeOptions(data.data)
			}
		}, (err) => {
			console.log(err);
		})
	}, []);

	return (
		<>
			<div className="row season-popup edit_profile">
				<div className="col-lg-1"></div>
				<div className="col-lg-10">
					<div className="d-flex justify-content-between align-items-center">
						<div className="profile-img-box">
							<div className="">
							
								<img
								className="rounded-circle upadateimg"
									src={
										(image ? URL.createObjectURL(image) : (playerDeatils.profile_pic) ? playerDeatils.profile_pic
											: require("../../../assets/images/user-100.png"))
									}
									alt=""
									onClick={handleClick}
								/>
							</div>
							<div className="cperson-detail">
								<label className="profile-file-upload">
									<input
										type="file"
										ref={hiddenFileInput}
										onChange={onImageChange}
										accept="image/*"
									/>{" "}
									<FontAwesomeIcon icon={faLink} /> Change profile
									image
								</label>
							</div>
						</div>
					</div>
					<div className="form-sec edit-profile-sec margin-t30">
						<div className="row">
							<div className="col-sm-12">
								<div className="form-group mb-4">
									<label className="form-label">Full Name</label>
									<input
										name="firstname"
										className="form-control"
										value={playerDeatils?.firstname}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									/>
								</div>
							</div>
							{/* <div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Last Name</label>
									<input
										name="lastname"
										type="text"
										className="form-control"
										value={playerDeatils?.lastname}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									/>
								</div>
							</div> */}
							<div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Email</label>
									<input
										name="email"
										className="form-control"
										value={playerDeatils?.email}
										readOnly
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Phone Number</label>
									<PhoneInput
										country={'us'}
										inputClass="form-control"
										id="phoneNumber"
										name="contact_number"
										onChange={phoneNumber => setPlayerDeatils({ ...playerDeatils, contact_number: phoneNumber })}
										value={playerDeatils.contact_number}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Gender</label>
									<select
										className="form-control"
										name="gender"
										key={playerDeatils?.gender}
										defaultValue={playerDeatils?.gender}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									>
										<option value="">Select</option>
										<option value="male">Male</option>
										<option value="female">Female</option>
									</select>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Date of Birth</label>
									<input
										name="dob"
										className="form-control"
										type="date"
										value={moment(playerDeatils?.dob).format(
											"YYYY-MM-DD"
										)}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group mb-4">
									<label className="form-label">Change Weight</label>
									<input
										name="weight"
										className="form-control"
										value={playerDeatils?.weight}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group mb-0">
									<label className="form-label">Change Grade</label>
									<select
										className="form-control"
										name="gradeid"
										value={playerDeatils?.gradeid}
										onChange={(e) =>
											onTextChange(e, setPlayerDeatils)
										}
									>
										<option>select </option>
										{gradeOptions?.length ? (
											gradeOptions?.map((grade, i) => (
												<option value={grade.id}>
													{grade.grade_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
							</div>

							{playerDeatils?.team_id ? (
								<div className="row">
									<div className="col-sm-6">
										<div className="mb-4">
											<label className="form-label">
												Change Assign Team
											</label>
											<select
												className="form-control"
												name="teamid"
												defaultValue={String(playerDeatils?.team_id)}
												onChange={(e) =>
													onTextChange(e, setPlayerDeatils)
												}
											>
												<option>Select</option>
												{teamList?.length ? (
													teamList?.map((team, i) => (
														<option value={team.id}>
															{team.team_name}
														</option>
													))
												) : (
													<></>
												)}
											</select>
										</div>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="d-flex  align-items-center divisions-schedule mt-3">
								<div className="create-from-btn submission">

									<button
									    disabled={loader}
										type="submit"
										className="btn btn-dark savesubmit"
										onClick={handleSubmit}
									>
									{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Update"}
										
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};


export default EditPlayer;
