import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = () => {
const [visible, setVisible] = useState({visible1:false, visible2:false,visible3:false})
const [loader , setLoader ] = useState(false)
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validate: values => {
      const errors = {};

      if (!values.currentPassword) {
        errors.currentPassword = 'Current password is required';
      }
      if (!values.newPassword) {
        errors.newPassword = 'New password is required';
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Confirm password is required';
      } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (values.newPassword === values.confirmPassword) {
        setLoader(true)
        Apipost("/auth/Change-password", values, "application/x-www-form-urlencoded", true)
          .then(success => {
            if (success.data.status) {
              setLoader(false)
              successMessageNodify(success.data.message);
            } else {
              setLoader(false)
              errorMessageNodify(success.data.message);
            }
          })
          .catch(err => {
            setLoader(false)
            errorMessageNodify(err.message);
          });
      } else {
        console.error('New password and confirm password do not match');
        setLoader(false)
      }

      setSubmitting(false);
    }
  });

  const togglePasswordVisibility = (name) => {
    setVisible(prev => ({ ...prev, [name]: !prev[name] }));
  };
  

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='mb-3'>
        <label className="form-label" htmlFor="currentPassword">Current Password:</label>
        <div className="password-input-wrapper">
          <input
            className="form-control"
            type={visible.visible1 ? "text" : "password"}
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
          />
          <span className="icon Password-hide" name='visible1' onClick={()=>togglePasswordVisibility("visible1")} >
            {visible.visible1 == true ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
          </span>
        </div>
        {formik.errors.currentPassword && (
          <div className='text-danger'>{formik.errors.currentPassword}</div>
        )}
      </div>
      <div className='mb-3'>
        <label className="form-label" htmlFor="newPassword">New Password:</label>
        <div className="password-input-wrapper">
          <input
            className="form-control"
            type={visible.visible2 == true ? "text" : "password"}
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
          />
          <span className="icon Password-hide" name='visible2' onClick={()=>togglePasswordVisibility("visible2")}>
            {visible.visible2 ==true ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
          </span>
        </div>
        {formik.errors.newPassword && (
          <div className='text-danger'>{formik.errors.newPassword}</div>
        )}
      </div>
      <div className='mb-3'>
        <label className="form-label" htmlFor="confirmPassword">Confirm Password:</label>
        <div className="password-input-wrapper">
          <input
            className="form-control"
            type={visible.visible3 == true ? "text" : "password"}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          <span className="icon Password-hide" name='visible3' onClick={()=>togglePasswordVisibility("visible3")}>
            {visible.visible3 ==true ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
          </span>
        </div>
        {formik.errors.confirmPassword && (
          <div className='text-danger'>{formik.errors.confirmPassword}</div>
        )}
      </div>
      <div className='updatepassword'>
        <button className="btn btn-dark savesubmit" type="submit" disabled={loader || formik.isSubmitting}>
        {loader ?  <FontAwesomeIcon icon={faCircleNotch} spin /> :   "Update" }
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
