export const GET_PLAYER_DETAILS = 'GET_PLAYER_DETAILS';
export const UPDATE_PLAYER_DETAILS = 'UPDATE_PLAYER_DETAILS';

export const GET_TEAM_DETAILS ='GET_TEAM_DETAILS';
export const UPDATE_TEAM_DETAILS='UPDATE_TEAM_DETAILS';

export const GET_VENUE_DETAILS ='GET_VENUE_DETAILS';
export const UPDATE_VENUE_DETAILS='UPDATE_VENUE_DETAILS';

export const GET_COACH_DETAILS ='GET_COACH_DETAILS';
export const UPDATE_COACH_DETAILS ='UPDATE_COACH_DETAILS';

export const GET_REFEREE_DETAILS ='GET_REFEREE_DETAILS';
export const UPDATE_REFEREE_DETAILS ='UPDATE_REFEREE_DETAILS';

export const GET_SPORTS_DETAILS ='GET_SPORTS_DETAILS';
export const UPDATE_SPORTS_DETAILS ='UPDATE_SPORTS_DETAILS';

export const GET_GRADE_LIST ='GET_GRADE_LIST';
export const UPDATE_GRADE_LIST ='UPDATE_GRADE_LIST';

export const GET_AGEGROUP_LIST ='GET_AGEGROUP_LIST';
export const UPDATE_AGEGROUP_LIST ='UPDATE_AGEGROUP_LIST';

export const GET_GAME_LIST ='GET_GAME_LIST';
export const UPDATE_GAME_LIST ='UPDATE_GAME_LIST';

export const GET_PROFILE_DETAILS ='GET_PROFILE_DETAILS';

export const ISAUTHENTICATED ="ISAUTHENTICATED"

export const PAYMENTDETAIL="PAYMENTDETAIL"

export const LOGOUT ="LOGOUT"

