/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Apipost } from '../../../helpers/apiCall';
import { objectValuesNonEmpty } from '../../../helpers/commonFunction';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { useCookies } from 'react-cookie';

import { useDispatch } from 'react-redux';
import { fetchCoaches, fetchTeams, fetchVenues, fetchGameList, fetchSports, fetchGrades, fetchAgeGroup, fetchProfileDetails, fetchPaymentDetail } from "../../../redux/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { isAuthenticated } from '../../../redux/actions';
import withLoader from '../../../components/PageLoader';

const LoginpageWithOutLoader = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const queryData = queryParams.get('auth') || '';
	const [_, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();
	const [values, setValues] = useState({
		Email: "",
		Password: '',
		errorMessage: { Emailerror: '', Passworderror: '' }
	})
	const dispatch = useDispatch();
	const [isVisible, setVisible] = useState(true);
	const [loading, setLoading] = useState(false);

	
	const errorMsg = (e, value) => {
		setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: value } })
	}

 useEffect(()=>{
	if(queryData.length){
		let authData = atob(queryData).split('&')
		if(authData.length >0){
			handleLogin({queryData})
		}
	}
 },[queryData])

	const handleError = (e) => {
		if (e.target.name === 'Email') {
			if (!values.Email.length > 1) errorMsg(e, 'Email address Cannot be Empty!')
		} else {
			if (!values.Password.length) errorMsg(e, 'Password  Cannot be Empty!')
		}
	}
	const removeError = (e) => {
		setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: '' } })
	}
	const onTextChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value.trim() });
	};

const handleLogin = (userCredentials)=>{
	Apipost("/auth/signin", userCredentials).then((success) => {
		if (success?.data?.data[1]['role_id'] === 2) {
			localStorage.setItem("role", "organizer");
			setCookie('token', success?.data?.data[0], { path: '/' });
			localStorage.setItem('token', success?.data?.data[0])
			localStorage.setItem('name', success?.data?.data[1]['firstname']+" " +success?.data?.data[1]['lastname'] )
			localStorage.setItem('id', success?.data?.data[1]['id'])
			successMessageNodify('Login Success')
			dispatch(fetchSports())
			dispatch(fetchVenues())
			dispatch(fetchGrades())
			dispatch(fetchAgeGroup())
			dispatch(fetchCoaches())
			dispatch(fetchGameList())
			dispatch(fetchProfileDetails())
			dispatch(fetchPaymentDetail())
			dispatch(isAuthenticated(true))
			setTimeout(() => navigate(`/${localStorage.getItem('role')}/dashboard`), 2000)
		} else {
			const roles = [{ roleid: 5, type: "Referee" }, { roleid: 3, type: "Coach" }, { roleid: 9, type: "Team Mom" }]
			setLoading(false);
			errorMessageNodify(`Opps, your are using your ${roles.filter(item => item.roleid == success?.data?.data[1]['role_id'])[0]?.type} credentials. Please use your valid organizer credentials to access organizer panel`)
		}
	}, (err) => {
		errorMessageNodify(err.response?.data.message)
		setLoading(false);
		if (err?.response?.data?.data.length) {
			navigate('/verify-otp', { state: { id: err.response?.data?.data[0] } })
		}
		setLoading(false);
		return;
	})
} 

	const handleSubmit = async e => {
		e.preventDefault();
		const userCredentials = { email: values.Email, password: values.Password };
		if (objectValuesNonEmpty(userCredentials) && !objectValuesNonEmpty(values.errorMessage)) {
			setLoading(true);
			handleLogin(userCredentials)
		
		} else {
			errorMessageNodify('Enter Credentials to Login!')
		}
	}

	const toggle = () => {
		setVisible(!isVisible);
	};

	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={require("../../../assets/images/GIU_logo.png")} alt="GIU Football" />
						</div>
						<div className="login-form-box login-to-giu">
							<form onSubmit={handleSubmit}>
								<div className="mb-2">
										<label>Email</label>
										<div className="form-group email-form-group ">
										<input type="text" name='Email' id="email" className="form-control" placeholder="Email" onFocus={removeError} onBlur={handleError} onChange={onTextChange} />
										{(values.errorMessage?.Emailerror) ? <span className="text-danger">{values.errorMessage?.Emailerror}</span> : <br />}
									</div>
								</div>
								<div className="mb-2">
									<label>Password</label>
									<div className="form-group passord-form-group">
										<input type={isVisible ? "password" : "text"} name='Password' id="password" className="form-control" placeholder="Password" onFocus={removeError} onBlur={handleError} onChange={onTextChange} />
										<span className="icon Password-hide" onClick={toggle}>
											{isVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
										</span>
									</div>
									{(values.errorMessage?.Passworderror) ? <span className="text-danger">{values.errorMessage?.Passworderror}</span> : <><br /></>}
								</div>
								<div className="mb-4 forgot-password">
									<label className="custom-check"> Remember me
										<input type="checkbox" />
										<span className="checkmark"></span>
									</label>
									<Link to="/forgot-password">Forgot Password?</Link>
								</div>
								<div className="mb-3">
									<button className="btn btn-dark green full-btn btn-lg signin-btn">{
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Login'
									}</button>
								</div>
							</form>
						</div>
						<div className="form-bottom-link">Don’t have an account? <Link to='/register'><b>Register Now</b></Link></div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>Organizer Panel</h1>
							<p>Login to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Loginpage =  withLoader(LoginpageWithOutLoader)

export default Loginpage;