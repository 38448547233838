import { GET_COACH_DETAILS, GET_PLAYER_DETAILS, GET_REFEREE_DETAILS, GET_TEAM_DETAILS, GET_VENUE_DETAILS, GET_SPORTS_DETAILS, GET_GRADE_LIST, GET_AGEGROUP_LIST, GET_GAME_LIST, UPDATE_GAME_LIST, ISAUTHENTICATED, GET_PROFILE_DETAILS, PAYMENTDETAIL } from "./actionTypes"

export const getplayerDetails = (data) => {
    return {
        type: GET_PLAYER_DETAILS,
        payload: data
    }
}
export const getprofileDetails = (data) => {
    return {
        type: GET_PROFILE_DETAILS,
        payload: data
    }
}
export const getCoachDetails = (data) => {
    return {
        type: GET_COACH_DETAILS,
        payload: data
    }
}
export const getTeamDetails = (data) => {
    return {
        type: GET_TEAM_DETAILS,
        payload: data
    }
}
export const getVenueDetails = (data) => {
    return {
        type: GET_VENUE_DETAILS,
        payload: data
    }
}

export const getRefreeDetails = (data) => {
    return {
        type: GET_REFEREE_DETAILS,
        payload: data
    }
}

export const getSportsDetails = (data) => {
    return {
        type: GET_SPORTS_DETAILS,
        payload: data
    }
}

export const getGradeList = (data) => {
    return {
        type: GET_GRADE_LIST,
        payload: data
    }
}

export const getAgeGroupList = (data) => {
    return {
        type: GET_AGEGROUP_LIST,
        payload: data
    }
}

export const getGameList = (data) => {
    return {
        type: GET_GAME_LIST,
        payload: data
    }
}

export const isAuthenticated = (data) => {
    return {
        type: ISAUTHENTICATED,
        payload: data
    }
}

export const getPaymentDetails = (data) => {
    return {
        type: PAYMENTDETAIL,
        payload: data
    }
}