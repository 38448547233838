import React, { useState, useEffect } from 'react';
import { ApiGetAuth } from '../../helpers/apiCall';
import { successMessageNodify } from '../../helpers/notify';
import Form from 'react-bootstrap/Form';

const FileUploadWithPreview = ({ setFiles, maxFileSizeMB = 2, maxFiles = 5, ngoUploadedDocs,setIsConfirmed, isConfirmed }) => {
  const [filePreviews, setFilePreviews] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
 

  // Handler for checkbox change
  const handleCheckboxChange = (e) => {
    setIsConfirmed(e.target.checked);
  };


  const StatusMessage = ({ status_id, comments }) => {
    if (status_id === '30') {
      return (
        <div className='congress-text mt-4' style={{ color: '#27AE60' }}>
          <h6> Congratulations! Your document has been verified.</h6>
          <h6>You are now a verified NGO Organizer on GIU TeamConnect.</h6>
        </div>
      );
    } else if (status_id === '31') {
      return (
        <div className='Reason-box mt-4'>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className='text-dark'>Reason</Form.Label>
              <Form.Control as="textarea" rows={5} value={comments || ''} disabled />
            </Form.Group>
          </Form>
        </div>
      );
    }
    return null; // For under-review or other statuses
  };
  

  // Map the already uploaded files (ngoUploadedDocs) to the previews
  useEffect(() => {
    if (ngoUploadedDocs && ngoUploadedDocs.length > 0) {
      const uploadedPreviews = ngoUploadedDocs.map((doc) => ({
        id: doc.id,
        name: doc.file_name,
        url: doc.file_url,
        type: doc.file_name.endsWith('.pdf') ? 'application/pdf' : 'image/jpeg', // Handle other types as needed
        status_id:doc.status_id,
        status: (doc.status_id == '29' ? 'Under Review' : (doc.status_id == '30' ? 'Approved' : 'Rejected')),
        comments:doc.comments,
        isUploaded: true, // Flag to indicate these are pre-uploaded files
      }));
      setFilePreviews(uploadedPreviews);
    }
  }, [ngoUploadedDocs]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFileTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    // Check if max file count is exceeded
    if (filePreviews.length + files.length > maxFiles) {
      setErrorMessage(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    const newPreviews = [];
    const validFiles = [];

    files.forEach((file) => {
      // Check file type
      if (!validFileTypes.includes(file.type)) {
        setErrorMessage('Unsupported file type. Please upload a PDF, JPEG, PNG, or DOC file.');
        return;
      }

      // Check file size
      const maxSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to Bytes
      if (file.size > maxSizeBytes) {
        setErrorMessage(`File size exceeds the maximum limit of ${maxFileSizeMB} MB.`);
        return;
      }

      setErrorMessage('');

      // Generate preview and add file to valid list
      const previewUrl = URL.createObjectURL(file);
      newPreviews.push({
        name: file.name,
        url: previewUrl,
        type: file.type,
        isUploaded: false, // Flag to indicate these are newly uploaded files
      });
      validFiles.push(file);
    });

    // Update the parent component's state
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);

    // Update local previews
    setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  // Handle deletion of uploaded files
  const handleUploadedFileDelete = (fileId) => {
    // Trigger API call to delete the uploaded file
    console.log(`Deleting uploaded file with ID: ${fileId}`);
    ApiGetAuth(`dashboard/delete-ngo-doc/${fileId}`).then(
      (data) => {
        successMessageNodify(data.message)
        setFilePreviews((prevPreviews) => prevPreviews.filter(file => file.id !== fileId));
      }
    );
  };

  const handleDeleteFile = (index, isUploaded, fileId) => {
    if (isUploaded) {
      handleUploadedFileDelete(fileId); // Delete the uploaded file from server
    } else {
      // Delete the newly selected file
      setFilePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };



  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleFileChange({ target: { files } }); // Simulating onChange for file input
  };

  const handleBrowseClick = () => {
    document.getElementById('fileInput').click();
  };



  console.log(filePreviews[0]?.comments , "filePreviews[0].comments")



  return (
    <div>
    {
      filePreviews.length > 0 && filePreviews[0].status_id == '30' ? null:
    
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`drop-zone ${isDragging ? 'dragging' : ''}`}
        style={{
          border: '2px dashed #B3B3B3',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          borderRadius: '8px',
        }}
        onClick={handleBrowseClick}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.71 7.7099L11 5.4099V14.9999C11 15.2651 11.1054 15.5195 11.2929 15.707C11.4804 15.8945 11.7348 15.9999 12 15.9999C12.2652 15.9999 12.5196 15.8945 12.7071 15.707C12.8946 15.5195 13 15.2651 13 14.9999V5.4099L15.29 7.7099C15.383 7.80362 15.4936 7.87802 15.6154 7.92879C15.7373 7.97956 15.868 8.00569 16 8.00569C16.132 8.00569 16.2627 7.97956 16.3846 7.92879C16.5064 7.87802 16.617 7.80362 16.71 7.7099C16.8037 7.61693 16.8781 7.50633 16.9289 7.38447C16.9797 7.26261 17.0058 7.13191 17.0058 6.9999C17.0058 6.86788 16.9797 6.73718 16.9289 6.61532C16.8781 6.49346 16.8037 6.38286 16.71 6.2899L12.71 2.2899C12.6149 2.19886 12.5028 2.12749 12.38 2.0799C12.1365 1.97988 11.8635 1.97988 11.62 2.0799C11.4972 2.12749 11.3851 2.19886 11.29 2.2899L7.29 6.2899C7.19676 6.38313 7.1228 6.49382 7.07234 6.61565C7.02188 6.73747 6.99591 6.86804 6.99591 6.9999C6.99591 7.13175 7.02188 7.26232 7.07234 7.38415C7.1228 7.50597 7.19676 7.61666 7.29 7.7099C7.38324 7.80313 7.49393 7.8771 7.61575 7.92756C7.73757 7.97802 7.86814 8.00399 8 8.00399C8.13186 8.00399 8.26243 7.97802 8.38425 7.92756C8.50607 7.8771 8.61676 7.80313 8.71 7.7099ZM21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4803 20 12.7347 20 12.9999V18.9999C20 19.2651 19.8946 19.5195 19.7071 19.707C19.5196 19.8945 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8945 4.29289 19.707C4.10536 19.5195 4 19.2651 4 18.9999V12.9999C4 12.7347 3.89464 12.4803 3.70711 12.2928C3.51957 12.1053 3.26522 11.9999 3 11.9999C2.73478 11.9999 2.48043 12.1053 2.29289 12.2928C2.10536 12.4803 2 12.7347 2 12.9999V18.9999C2 19.7955 2.31607 20.5586 2.87868 21.1212C3.44129 21.6838 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6838 21.1213 21.1212C21.6839 20.5586 22 19.7955 22 18.9999V12.9999C22 12.7347 21.8946 12.4803 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999Z" fill="#909090" />
        </svg>
        <p>Drag & drop files or <span style={{ color: '#007bff', cursor: 'pointer' }}>Browse</span></p>
        <small className='text-muted'>
          Upload max 5 documents (Below 1 MB size)<br />
          Supported formats: JPEG, PNG, PDF, DOC
        </small>
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
          multiple
          disabled={filePreviews.length >= maxFiles}
          style={{ display: 'none' }}
        />
      </div>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {filePreviews.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          <p className='text-dark mt-4'><b> Uploaded Document : {filePreviews.length}/5</b></p>
          {filePreviews.map((file, index) => (
            <div key={index} style={{ marginBottom: '10px' }} className='d-flex justify-content-between align-items-center '>
            <div className={'d-flex justify-content-between align-items-center file-uploaded-content '+ (file.status_id == '30' ? 'border-success' : ( file.status_id == '31'?'border-danger' : 'border-secondary  '))}>
                <div>
                  <a href={file.url} target="_blank" rel="noopener noreferrer" className='text-dark'>
                    {file.name}
                  </a>
                </div>
                  {file.status_id == '30' ? null :
                  <div>
                  <span onClick={() => handleDeleteFile(index, file.isUploaded, file.id)} className="mx-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </span>
                </div>
                }
              </div>
              <div><span className={'text ' + (file.status_id == '30' ? 'text-success' : ( file.status_id == '31'? 'text-danger' : 'text-secondary'))}
 >             {file.isUploaded ? file.status : null}</span></div>
            </div>
          ))}
        </div>
      )}
      
     <StatusMessage status_id={filePreviews[0]?.status_id}  comments={filePreviews[0]?.comments} /> 
     
      {/* under review checkbox */}

{
  (filePreviews.length > 0 && filePreviews.filter(item=>!item.isUploaded).length >0)? <div className='Agree-check-box'>
      <Form>
      <Form.Check
        type="checkbox"
        id="custom-checkbox"
        label="Yes, I confirmed my documents are valid and approved by the state govt."
        className="green-checkbox"
        checked={isConfirmed}
        onChange={handleCheckboxChange}
      />
    </Form>
      </div>:null
}
    </div>
  );
};

export default FileUploadWithPreview;
