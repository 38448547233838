import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DraftMatches from "./DraftMatches";
import { genMatchesByTeamCount, genMatchesSingleRound } from "../../../helpers/helperfunctions";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import CustomBracket from "../../../components/CustomBracket/CustomBracket";

function GenerateBrackets({dates}) {
    const { sportId, groupId } = useParams();
    const [standingsTeams, setStandingTeams] = useState([])
    const [bracketsRounds , setBracketRounds] = useState([])
    const [draftMatches , SetDraftMatches] = useState(null)
    const [currentScreen , setCurrentScreen ] = useState('Brackets')
    const [refresh , setRefresh] = useState(false)
    function isPowerOfTwo(n) {
      return (n !== 0) && ((n & (n - 1)) === 0);
  }
  
  function allEvenNumbers(n) {
      let result = [];
      for (let i = n; i > 0; i--) {
          if (i % 2 === 0) {
              result.push(i);
          }
      }
      return result;
  }

  const movingForwardPossibleCase = (n) => {
      const evenNumbers = allEvenNumbers(n);
      let result = 0;
      evenNumbers.forEach(item => {
          let diff = n - item;
          if (isPowerOfTwo(item / 2 + diff)) {
              result += diff;
          }
      });
      return result;
  }

  function findAddSubtractNumbers(number) {
    for(let i=number; i>0 ; i-- ){
        if(isPowerOfTwo(i)){
            return number -i
        }
    }
    return 0
    }

    function transformData(data) {
      // Group matches by round and sort rounds by round_order
      const rounds = {};
      data.forEach(match => {
          const round = match.round.trim();
          if (!rounds[round]) {
              rounds[round] = [];
          }
          rounds[round].push(match);
      });
  
      const sortedRounds = Object.keys(rounds).sort((a, b) => {
          return rounds[a][0].round_order - rounds[b][0].round_order;
      });
  
      // Convert matches to the desired format
      const transformedData = [];
      let seedIdCounter = 1;
      sortedRounds.forEach(roundTitle => {
          const seeds = [];
          const matches = rounds[roundTitle].sort((a, b) => a.id - b.id);
          matches.forEach((match, index) => {
              seeds.push({
                  id: seedIdCounter,
                  matchid : match.id,
                  roundid:match.round_id,
                  roundname:match.round,
                  venue_name:match.venue_name,
                  venue_field:match.venue_field,
                  venue_field_timeslot:match.venue_field_timeslot,
                  match_id_be_decided:match.match_id_be_decided,
                  match_type:match.match_type,
                  winner:match.winner,
                  date: match.matchDate, // You can replace this with the actual date
                  teams: [
                      { id: match.Team1.id, name: match.Team1.team_name, score:match.score_one, team_logo : match.Team1.team_logo},
                      { id: match.Team2.id, name: match.Team2.team_name ,  score:match.score_two, team_logo : match.Team2.team_logo }
                  ]
              });
              seedIdCounter +=1;
          });
          transformedData.push({ title: roundTitle, round_id: rounds[roundTitle][0].round_id, seeds: seeds });
      });
  
      return transformedData;
  }

  useEffect(() => {
    ApiGetAuth(`games/get-standings/${groupId}`).then(
      (data) => {
        if (data?.data) {
          setStandingTeams(data.data)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [])

  useEffect(()=>{
    if(draftMatches !==null && draftMatches?.length >0){
      let bracketsData = transformData(draftMatches)
      if(isPowerOfTwo(standingsTeams?.length)){
        setBracketRounds(bracketsData)
      }else{
        setBracketRounds(bracketsData)
      }
    }
  },[draftMatches])

const handleAdd= async (value) =>{
  Apipost(`/games/add-final-matches`, {value, groupId} ,"application/x-www-form-urlencoded", true).then(
    (reponse) => {
        if (reponse.data.status === true) {
            return;
        } else {
            return;
        }
    },
    (err) => {
           
        return;
    }
);
}

      function customSlice(array, length, type) {
        let result;
        let leftArray;
        
        if (type === 'start') {
            result = array.slice(0, length);
            leftArray = array.slice(length);
        } else if (type === 'end') {
            result = array.slice(-length);
            leftArray = array.slice(0, array.length - length);
        } else {
            return { result: null, leftArray: null, message: "Invalid type. Please provide 'start' or 'end'." };
        }
    
        return { result: result, leftArray: leftArray, message: "Success" };
    }

      const handleBracketGenerate = async () =>{
        let teamsCount =standingsTeams?.length
        let move2ndRound =movingForwardPossibleCase(teamsCount)
        let initialMatches =findAddSubtractNumbers(teamsCount)
        if(isPowerOfTwo(teamsCount)){
          let rounds = genMatchesByTeamCount(teamsCount,1,standingsTeams)
          await handleAdd(rounds)
        }else{
          if(initialMatches > move2ndRound){
            //MOVEFORWARD CASE
            let teams = teamsCount - move2ndRound
            const starterTeams = customSlice(standingsTeams,move2ndRound,'start')
            const {leftArray , result} = starterTeams
            let staterRound = genMatchesSingleRound(teams, (teams/2)+1 , leftArray)
            let secondRoundonwards = genMatchesByTeamCount(teams/2 +move2ndRound, (teams/2)+1, result)
             await handleAdd([...staterRound,...secondRoundonwards])
             setRefresh(prev=>!prev)
           }else{
            // 
            let teams = teamsCount - initialMatches 
            const starterTeams = customSlice(standingsTeams,initialMatches*2 ,'end')
            const {leftArray , result} = starterTeams
            let staterRound = genMatchesSingleRound(initialMatches*2 ,initialMatches+1 ,result )
            let secondRoundonwards = genMatchesByTeamCount(teams,initialMatches+1,leftArray )
             await handleAdd([...staterRound,...secondRoundonwards])
             setRefresh(prev=>!prev)
           }
        }
      }
    return ( <>
    <div className="view-brackets-btn">
    <button className="btn btn-primary " onClick={()=>setCurrentScreen( prev=>prev=='List'? 'Brackets' :'List' )} >Switch View to {currentScreen} </button>
{ draftMatches !==null &&   draftMatches?.length ==0?  <button className="btn btn-primary" onClick={handleBracketGenerate}>Generate Bracket</button>:<>
</>} 
    </div>

<div className={currentScreen =="Brackets" ?"d-none":""}>
<CustomBracket   rounds={bracketsRounds} setRounds ={setBracketRounds}   setRefresh={setRefresh}  data={draftMatches} setData={SetDraftMatches}  standingsTeams ={standingsTeams}   /> 
</div>
<div className={currentScreen =="List" ?"d-none":""}>
<DraftMatches groupid={groupId} dates={dates} sportsid={sportId} setMatchRefresh={setRefresh} generated={refresh} endPoint={`games/get-Final-Draft-Matches/${groupId}`}  updateinState={true} SetDraftMatches={SetDraftMatches}  />
</div>
    </> );
}

export default GenerateBrackets;