import React, { useEffect, useState } from "react";
import { ApiGetAuth } from "../../../helpers/apiCall";
import height from '../../../assets/images/height.png'
import age from '../../../assets/images/age.png'
import weight from '../../../assets/images/weight.png'
import noimage from '../../../assets/images/no-image.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Cardloader from "../../Mainloader.js/Cardloader";



const PlayerDetails = ({ playerDetails }) => {

	const [teamList, setTeamList] = useState(null);
	const [documentrows, setdocumentrows] = useState([]);
	const [teamdetails, setTeamdetails] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const [contentToRender, setContentToRender] = useState(<></>)
	// table
	function CustomModal({ showModal, onClose, componentToRender }) {
		return (
			<Modal show={showModal} onHide={onClose}>
				<Modal.Body className="doc-img">
					<button type="button" className="btn-close" onClick={onClose}></button>

					{componentToRender}

				</Modal.Body>

			</Modal>
		);
	}
	const openModal = (value) => {
		setContentToRender(value)
		setShowModal(true);
	}
	const closeModal = () => {
		setShowModal(false);
	};


	const state = playerDetails
	let id = state?.id;
	function createDocumentData(name, link, status, id) {
		return { name, link, status, id };
	}
	function getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}



	useEffect(() => {
		ApiGetAuth(`app/profile-details?user_id=${id}`).then((data) => {
			if (data.data) {
				setTeamdetails(data.data.sports);

			}
		});
		ApiGetAuth(`app/player-document-list?user_id=${id}`).then((data) => {
			if (data.data) {
				let documentlist = [];
				data.data.map(item => {
					documentlist.push(createDocumentData(item.document_name, item.document_url, item.status, item.id))
				})
				setdocumentrows(documentlist)
			}
		});
		ApiGetAuth(`app/team-list?user_id=${id}`).then((data) => {
			if (data.data) {
				setTeamList(data.data)
			}
		});
	}, []);

	const team = <label>Team ({teamList?.length || 0})</label>


	return (

		<>
			<div className="row">
				<CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
				<div className="col-xxl-4 col-lg-6 col-md-12 col-xs-12 ps-0 ">
					<div className="groupwrap">
						<div className="selected_game_wrap">
							<div className="selected_team_profile_section mb-2">
								<div className="groupname">
									<div className="groupicon">
										<img className="player-img" src={state?.profile_pic || noimage} />
										<div className="aboutgrp">
											<div className="d-flex">
												<p className=" ps-0">{state?.name}</p>
												<p className="verified checked ps-2"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_1_161)">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C9.48912 0 11.3968 0.790176 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5ZM7.072 10.71L11.39 5.312L10.61 4.688L6.928 9.289L4.32 7.116L3.68 7.884L7.072 10.711V10.71Z" fill="#27AE60" />
													</g>
													<defs>
														<clipPath id="clip0_1_161">
															<rect width="15" height="15" fill="white" />
														</clipPath>
													</defs>
												</svg>
												</p>
											</div>

											<p className="mb-0 sports">
												Email -
												<span>{state?.email}</span>

											</p>
											<p className="mb-0 sports">
												Contact no -
												<span>{state?.contact_number}</span>
											</p>
											<p className="mb-0 sports">Sports - <span>{
												teamdetails?.map(item => <span>{item.sports_name},</span>)
											}</span></p>
										</div>

									</div>

								</div>
								
								<div className="grpdocuments pd">
									<h5>Personal Details</h5>
									<div className="d-flex">
										<div className="height ">
											<img src={height} /> <span>{playerDetails?.height} Ft</span>
										</div>
										<div className="height ps-5 img-wraps">
											<img src={age} /> <span>{getAge(playerDetails?.dob)} Yrs</span>
										</div>
										<div className="height ps-5">
											<img src={weight} /> <span>{playerDetails?.weight} KG</span>
										</div>
									</div>
								</div>
								
								<div className="grpdocuments">
									<h5>Submitted Documents:</h5>
									<div className="d-flex align-items-center">
										{documentrows?.map((src, index) => {
											return <>
											<>
												<div className="doc1 me-2 p-d" onClick={() => openModal(<img src={src.link} width='80px' alt="not found" />)}>

													<img src={src.link} width='80px' alt="not found" />
													<p className="mb-0">{src.name}</p>
												</div>


											</></>
										})}
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
				<div className="col-xxl-8 col-lg-6 col-md-12 col-xs-12">
					<Tabs
						defaultActiveKey="home"
						id="uncontrolled-tab-example"
						className="selected_team_tabbing"
					>
						<Tab eventKey="home" title={team}>
							<div className="players-wrap">
									<div className="top-title d-flex justify-content-between">
										<div className="d-flex">
											<p className="mb-0  ms-2 total-players"></p>
										</div>
									</div>	
									
									<div className="coaches">
										{teamList == null ? <Cardloader title='teams' /> : <>{teamList?.length ? <>
											{teamList?.map((item) => (
											<div className="cfirst">
												<img src={item.team_logo || noimage} />
												<div className="my-2">
													<span className="btn selected_team_view_profile">{item.totalmembers} Members</span>
												</div>
												<p className="c-name">{item.team_name}</p>
												<div className="age-grp">
													<span>{item.age_group || item.grade} </span>
												</div>
											</div>
											))}
										</> : <>NO Teams Found !</>} </>}
									</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>

		</>
	);
};

export default PlayerDetails;
