import React from "react";
import { useNavigate } from "react-router";

const Backbutton = () => {

    const navigate = useNavigate(); 

    return(
        <>
            <button className="butn-bg back-btn" onClick={()=> navigate(-1)}> Back</button>
        </>
    )

}

export default Backbutton;