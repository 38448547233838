// DeletePopup.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import close from '../../assets/images/close.png';
import delwhite from '../../assets/images/trash-bin.png';


import './index.css'
import { Apipost } from '../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';

const DeletePopup = ({ isOpen, onClose,id,obj=false,Objkey='', endpoint ,values,setValues , title='' ,key='id', modelType='Archive'}) => {

  const [loading1, setLoading1] = useState(false);
  

  const handleRemoveGroup = () => {
    setLoading1(true);
    let value = {
        id: id,
        type :modelType
    };
    Apipost(endpoint, value, "application/x-www-form-urlencoded", true).then(
        (response) => {
            let result = response.data;
            if (result.status === 200 || result.status == true) {
              if(result?.error) {errorMessageNodify(result.msg)
                setLoading1(false);
                return
              }
                let final = values.filter(item => item[key] !== id)
                if(typeof id == "object"){
                  if(id.length>0){
                    final =values.filter(item=>!id.includes(item.id))
                  }
                }
                
               if(!obj){
                 setValues(final);
               }else{
                setValues((prev)=>({...prev,[Objkey]:final}))
               }
                successMessageNodify(title+" Successfully "+ modelType)
                setLoading1(false);
                onClose()
            } else {

              errorMessageNodify(  response.data.message|| title+" Delete Failed!")
              setLoading1(false);
                return;
            }
        }
    );
};
  return (
    <div className={`delete-popup ${isOpen ? 'open' : ''}`}>
      <div className="delete-popup-content">
        <h4>{modelType}</h4>
        <p className='text-center mx-auto'>Are you sure you want to {modelType} {title} ?</p>

     <div className='d-flex justify-content-center align-items-center'>
     <button className="delete-popup-btn delete" onClick={handleRemoveGroup}>
          <div className='d-flex align-items-center justify-content-center' >
          <img className="register-ico del-whi" src={delwhite} />
           {
                                
               loading1 ? <div> <FontAwesomeIcon icon={faCircleNotch} spin /></div> : modelType
             }
          </div>
         
        </button>
        <button className="delete-popup-btn cancel" onClick={onClose}>
          <img className="register-ico " src={close} />
         
                            Close
      
        </button>
     </div>
      </div>
    </div>
  );
};

export default DeletePopup;
