import React, { useEffect, useState } from 'react';
import PayemntDetailLogo from '../../../assets/images/payment_detail_logo.png';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Row, Table, Image } from 'react-bootstrap';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';
import { ApiGetAuth } from '../../../helpers/apiCall';
import moment from 'moment/moment';
import { usePDF } from 'react-to-pdf';

const PaymentHistoryDetail = () => {
const {id} = useParams();
const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
const  [transactionDetails , setTransactionDetails ] = useState(null)
const [paymentInfo ,setPaymentInfo] = useState({})
const [transaction, setTransaction] =useState('')

    useEffect(()=>{
        ApiGetAuth(`payment/payment-details/${id}`).then((data) => {
			if (data?.data) {
                setTransactionDetails(data.data.rows)
                setPaymentInfo(data.data.paymentInfo)
                if(data.data.rows?.length){
                     const {stripe_transaction_id,payment_status,transaction_date,total_amount,order_id,discount_amount,team_charge_paid,transaction_charge_paid,tax_paid,teams_count,coupon_code,coupon_description,tax_amount_paid} = data.data.rows[0]
                     setTransaction({order_id,stripe_transaction_id,payment_status,transaction_date: moment(transaction_date).format('MM/DD/YYYY'),total_amount,team_charge_paid,transaction_charge_paid,tax_paid,teams_count,tax_amount_paid,coupon_code,discount_amount,coupon_description})
                }
			}
		  }, (err) => {
			console.log(err);
		  })
    },[id])


    return (
        <span ref={targetRef}>
            <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <NavLink to="#" role="button" tabIndex="0">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <NavLink to="/organizer/dashboard">Dashboard</NavLink>
                    </li>
                    <PlayModal />
                </ol>
            </nav>

            <div className="dahboard_inner_white_background">
                <div className="row">
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_recepit">
                            <div className="payment_recepit_heading">
                                <h4>
                                    <span>Invoice ID : <span>{transaction?.order_id}</span></span>
                                    
                                    <span>Stripe Ref ID : <span>{transaction?.stripe_transaction_id}</span></span>
                                    <span className=""><button className={ transaction?.payment_status !== 'Paid' ? "btn btn-danger" : "btn btn-success" } >{transaction?.payment_status}</button></span>
                                </h4>
                                <p>{transaction?.transaction_date}</p>
                            </div>
                            <button className="btn btn-primary download_receipt" onClick={() => toPDF()}>Download Receipt</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxl-8 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_description">
                            <Table >
                                <thead>
                                    <tr>
                                        <th>Game</th>
                                        <th>No. of Teams</th>
                                        <th>Date</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {transactionDetails == null ? <>Loading ..</>: <>
                                    {transactionDetails.map(item=>( <tr>
                                        <td>
                                            <div className="game_type_payment_transaction">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                                    <circle cx="30" cy="30" r="30" fill="#F2F7FF" />
                                                    <path d="M24.1229 44.8545H36.3641C36.7785 44.8545 37.115 45.1134 37.115 45.4331V49.4208C37.115 49.7405 36.7791 49.9994 36.3641 49.9994H24.1229C23.7085 49.9994 23.3721 49.7405 23.3721 49.4208V45.4331C23.3721 45.1134 23.7079 44.8545 24.1229 44.8545Z" fill="#CD3414" />
                                                    <path d="M26.1876 45.7451H34.2986C34.4909 45.7451 34.6464 45.8654 34.6464 46.0133V48.1927C34.6464 48.3406 34.4909 48.4609 34.2986 48.4609H26.1876C25.9953 48.4609 25.8398 48.3406 25.8398 48.1927V46.0133C25.8398 45.8654 25.9953 45.7451 26.1876 45.7451Z" fill="#FCAB28" />
                                                    <path d="M27.3062 45.7451H30.0962C30.1623 45.7451 30.2159 45.8654 30.2159 46.0133V48.1927C30.2159 48.3406 30.1623 48.4609 30.0962 48.4609H27.3062C27.2402 48.4609 27.1865 48.3406 27.1865 48.1927V46.0133C27.1865 45.8654 27.2402 45.7451 27.3062 45.7451Z" fill="#FFD733" />
                                                    <path opacity="0.35" d="M24.7051 50H26.7837L31.9752 44.8545H29.5867L24.7051 50Z" fill="white" />
                                                    <path opacity="0.35" d="M29.8369 50L34.9862 44.8545H34.01L28.8076 50H29.8369Z" fill="white" />
                                                    <path d="M28.248 21.4131V22.7593H31.9335V21.2002L28.248 21.4131Z" fill="#FCAB28" />
                                                    <path d="M21.7521 19.7609C21.6226 19.7609 21.4856 19.7397 21.3425 19.697C21.1551 19.6406 21.0489 19.4434 21.1047 19.2554C21.161 19.068 21.3588 18.9618 21.5462 19.0176C21.6486 19.0485 21.835 19.0859 21.9314 18.9911C22.0273 18.8963 22.0842 18.633 21.9542 18.179C21.7342 17.4097 20.4866 16.0803 18.5309 14.532C17.3494 13.5969 16.7372 12.0887 17.1077 11.0248C17.3336 10.3747 17.9068 10.002 18.6804 10.002C19.2725 10.002 19.7238 10.1591 20.0218 10.4689C20.4877 10.9543 20.4584 11.6743 20.4373 12.2003C20.4297 12.391 20.4216 12.5882 20.4438 12.689C20.446 12.6977 20.4476 12.702 20.4481 12.7031C20.525 12.7778 20.9828 12.7345 21.387 12.5774C21.5695 12.5064 21.7748 12.5969 21.8458 12.7795C21.9168 12.962 21.8263 13.1673 21.6437 13.2383C21.4796 13.3022 20.6345 13.6067 20.122 13.3331C19.928 13.2296 19.8002 13.0601 19.752 12.8434C19.7097 12.6527 19.7184 12.43 19.7292 12.1727C19.7471 11.7301 19.7671 11.2279 19.5109 10.9608C19.3522 10.7956 19.0726 10.7116 18.681 10.7116C18.2075 10.7116 17.9036 10.8958 17.7773 11.2588C17.5449 11.9273 17.935 13.1571 18.9719 13.9778C20.4573 15.1539 22.2987 16.8035 22.6362 17.9856C22.8242 18.6433 22.7505 19.1801 22.4298 19.497C22.2521 19.6726 22.0192 19.7625 21.7526 19.7625L21.7521 19.7609Z" fill="#FCAB28" />
                                                    <path d="M38.8265 19.7605C38.56 19.7605 38.327 19.6706 38.1493 19.4951C37.8281 19.1782 37.755 18.6413 37.9429 17.9836C38.2804 16.8016 40.1224 15.152 41.6073 13.9758C42.6436 13.1551 43.0342 11.9253 42.8018 11.2568C42.6756 10.8939 42.3717 10.7097 41.8982 10.7097C41.5065 10.7097 41.227 10.7937 41.0682 10.9589C40.8115 11.2265 40.832 11.7282 40.8499 12.1708C40.8602 12.4286 40.8694 12.6507 40.8272 12.8414C40.779 13.0581 40.6511 13.2277 40.4572 13.3312C39.9447 13.6053 39.0996 13.3003 38.9354 13.2364C38.7528 13.1654 38.6624 12.9601 38.7333 12.7775C38.8043 12.5949 39.0096 12.505 39.1916 12.5754C39.5963 12.7315 40.0541 12.7753 40.131 12.7C40.131 12.7 40.1327 12.6957 40.1348 12.687C40.157 12.5863 40.1494 12.3891 40.1413 12.1984C40.1202 11.6724 40.0909 10.9518 40.5568 10.467C40.8548 10.1571 41.3061 10 41.8982 10C42.6718 10 43.245 10.3727 43.4709 11.0228C43.8409 12.0868 43.2287 13.5945 42.0472 14.5306C40.0915 16.0789 38.8439 17.4078 38.6239 18.1776C38.4939 18.6316 38.5508 18.8948 38.6467 18.9897C38.7425 19.0845 38.9294 19.0471 39.0318 19.0162C39.2193 18.9599 39.417 19.0666 39.4734 19.254C39.5297 19.4415 39.423 19.6392 39.2355 19.6955C39.0931 19.7383 38.9554 19.7595 38.826 19.7595L38.8265 19.7605Z" fill="#FCAB28" />
                                                    <path d="M21.2314 10.356H39.3754V15.1758H21.3734L21.2314 10.356Z" fill="#FFD733" />
                                                    <path d="M21.374 15.1758C21.374 15.1758 22.7208 21.8381 30.1627 21.8381C37.6046 21.8381 39.3766 15.1758 39.3766 15.1758H21.374Z" fill="#FCAB28" />
                                                    <g opacity="0.7">
                                                        <path d="M35.4631 15.1758H21.374C21.374 15.1758 21.6828 16.6997 22.8183 18.2897C24.0053 18.942 25.5129 19.3813 27.4226 19.3813C31.8015 19.3813 34.2165 17.0746 35.4636 15.1758H35.4631Z" fill="#FFCE45" />
                                                    </g>
                                                    <path d="M28.2488 22.7593C28.2488 22.7593 27.1149 22.9716 27.0439 24.5313H33.4229C33.4229 24.5313 33.5649 23.0431 31.9348 22.7593H28.2493H28.2488Z" fill="#FFD733" />
                                                    <path d="M26.335 24.5312H34.1312C34.1312 24.5312 34.1312 33.6032 31.2962 38.2102H29.3118C29.3118 38.2102 26.4769 33.6742 26.335 24.5312Z" fill="#FFD733" />
                                                    <g opacity="0.48">
                                                        <path d="M26.335 24.5313C26.4238 30.2537 27.5674 34.171 28.4109 36.3012C29.5957 36.1787 30.6245 35.4013 31.0573 34.2713C32.2974 31.0354 32.7042 26.8982 32.8375 24.5308H26.335V24.5313Z" fill="#FFFFFD" />
                                                    </g>
                                                    <path d="M31.225 38.21H29.3115V41.6121H31.225V38.21Z" fill="#FCAB28" />
                                                    <path d="M31.6856 38.7957H28.8637C28.6838 38.7957 28.5381 38.65 28.5381 38.4701C28.5381 38.2903 28.6838 38.1445 28.8637 38.1445H31.6856C31.8655 38.1445 32.0112 38.2903 32.0112 38.4701C32.0112 38.65 31.8655 38.7957 31.6856 38.7957Z" fill="#FCAB28" />
                                                    <path d="M35.0526 44.4243C34.344 41.488 31.2252 41.6121 31.2252 41.6121H29.3118C26.4768 41.6121 25.6973 44.3002 25.6973 44.3002L35.0531 44.4243H35.0526Z" fill="#FFCE45" />
                                                    <path d="M31.6856 41.9148H28.8637C28.6838 41.9148 28.5381 41.7691 28.5381 41.5893C28.5381 41.4094 28.6838 41.2637 28.8637 41.2637H31.6856C31.8655 41.2637 32.0112 41.4094 32.0112 41.5893C32.0112 41.7691 31.8655 41.9148 31.6856 41.9148Z" fill="#FCAB28" />
                                                    <path d="M35.0527 45.0085H25.6969C25.3052 45.0085 24.9883 44.691 24.9883 44.2999C24.9883 43.9088 25.3057 43.5913 25.6969 43.5913H35.0527C35.4444 43.5913 35.7613 43.9088 35.7613 44.2999C35.7613 44.691 35.4439 45.0085 35.0527 45.0085Z" fill="#FFCE45" />
                                                    <g opacity="0.48">
                                                        <path d="M29.2883 10.356H24.9414V15.1753H29.2883V10.356Z" fill="#FFFFFD" />
                                                    </g>
                                                    <path d="M37.2258 10.356H34.8633V15.1753H37.2258V10.356Z" fill="#FFE266" />
                                                    <path d="M27.9544 26.3509C27.5801 27.8553 27.2615 29.3738 26.9983 30.9015C25.5421 30.4177 24.0864 29.9329 22.6302 29.4475C22.3848 28.688 22.1145 27.9377 21.8225 27.1944C22.7033 27.5362 23.4683 27.8125 24.3622 28.2172C24.373 27.8699 24.036 27.8001 23.7121 27.6727C23.0869 27.4273 22.4601 27.1803 21.8349 26.9349C21.7385 26.8975 21.6399 26.858 21.5662 26.7865C21.4839 26.7041 21.4427 26.5914 21.4032 26.4804C21.3761 26.4034 21.3495 26.3243 21.3208 26.2474C21.0451 25.463 20.7693 24.6802 20.4936 23.8941C21.8761 24.3974 23.2407 24.9505 24.5821 25.5507C24.6325 25.2501 24.3204 25.0242 24.0517 24.879C23.4878 24.5707 21.025 23.7039 20.4324 23.4071C20.298 23.3388 20.1637 23.2603 20.0651 23.1454C19.9578 23.0235 19.8966 22.8697 19.8359 22.7191C19.8072 22.6492 19.7801 22.5777 19.7519 22.5078C19.3792 21.5619 19.0032 20.6182 18.6289 19.6729C20.7997 20.7114 22.9683 21.7504 25.1374 22.7889C25.2934 22.8642 25.4527 22.9428 25.5599 23.0772C25.6547 23.1969 25.6959 23.3513 25.7496 23.4943C26.0036 24.1661 26.5643 24.6227 27.2269 24.8551C27.9095 25.0935 28.1208 25.6791 27.9539 26.3504L27.9544 26.3509Z" fill="url(#paint0_linear_575_6629)" />
                                                    <path d="M24.5821 25.5508C24.6325 25.2501 24.3205 25.0242 24.0518 24.879C23.4878 24.5708 21.025 23.704 20.4324 23.4071C20.298 23.3388 20.1637 23.2603 20.0651 23.1454C19.9578 23.0235 19.8966 22.8697 19.8359 22.7191C19.8072 22.6492 19.7801 22.5777 19.752 22.5078H19.7536L24.1503 24.3275C24.1503 24.3275 25.0832 24.7593 25.0654 25.755L24.5816 25.5508H24.5821Z" fill="url(#paint1_linear_575_6629)" />
                                                    <path d="M24.7858 28.4091L24.3616 28.2173C24.3725 27.8701 24.0355 27.8002 23.7116 27.6729C23.0864 27.4275 22.4596 27.1804 21.8344 26.935C21.738 26.8976 21.6394 26.8581 21.5657 26.7866C21.4834 26.7042 21.4422 26.5916 21.4027 26.4805C21.3756 26.4036 21.349 26.3245 21.3203 26.2476L24.243 27.4584C24.243 27.4584 24.775 27.6696 24.7858 28.4091Z" fill="url(#paint2_linear_575_6629)" />
                                                    <path d="M32.4795 26.3509C32.8538 27.8553 33.1724 29.3738 33.4357 30.9015C34.8919 30.4177 36.3475 29.9329 37.8037 29.4475C38.0491 28.688 38.3195 27.9377 38.6115 27.1944C37.7306 27.5362 36.9657 27.8125 36.0718 28.2172C36.0609 27.8699 36.3979 27.8001 36.7219 27.6727C37.347 27.4273 37.9738 27.1803 38.599 26.9349C38.696 26.8975 38.794 26.858 38.8677 26.7865C38.9501 26.7041 38.9912 26.5914 39.0308 26.4804C39.0579 26.4034 39.0844 26.3243 39.1131 26.2474C39.3889 25.463 39.6646 24.6802 39.9404 23.8941C38.5578 24.3974 37.1932 24.9505 35.8518 25.5507C35.8015 25.2501 36.1135 25.0242 36.3822 24.879C36.9462 24.5707 39.4089 23.7039 40.0016 23.4071C40.1359 23.3388 40.2703 23.2603 40.3689 23.1454C40.4761 23.0235 40.5374 22.8697 40.598 22.7191C40.6267 22.6492 40.6533 22.5777 40.682 22.5078C41.0547 21.5619 41.4307 20.6182 41.805 19.6729C39.6343 20.7114 37.4657 21.7504 35.2966 22.7889C35.1405 22.8642 34.9813 22.9428 34.874 23.0772C34.7792 23.1969 34.738 23.3513 34.6844 23.4943C34.4303 24.1661 33.8696 24.6227 33.2071 24.8551C32.5245 25.0935 32.3132 25.6791 32.48 26.3504L32.4795 26.3509Z" fill="url(#paint3_linear_575_6629)" />
                                                    <path d="M35.8517 25.5508C35.8013 25.2501 36.1133 25.0242 36.382 24.879C36.946 24.5708 39.4087 23.704 40.0014 23.4071C40.1357 23.3388 40.2701 23.2603 40.3687 23.1454C40.476 23.0235 40.5372 22.8697 40.5978 22.7191C40.6266 22.6492 40.6531 22.5777 40.6818 22.5078H40.6802L36.2834 24.3275C36.2834 24.3275 35.3505 24.7593 35.3684 25.755L35.8522 25.5508H35.8517Z" fill="url(#paint4_linear_575_6629)" />
                                                    <path d="M35.6475 28.4091L36.0716 28.2173C36.0608 27.8701 36.3978 27.8002 36.7217 27.6729C37.3469 27.4275 37.9737 27.1804 38.5989 26.935C38.6958 26.8976 38.7939 26.8581 38.8676 26.7866C38.9499 26.7042 38.9911 26.5916 39.0306 26.4805C39.0577 26.4036 39.0843 26.3245 39.113 26.2476L36.1903 27.4584C36.1903 27.4584 35.6583 27.6696 35.6475 28.4091Z" fill="url(#paint5_linear_575_6629)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_575_6629" x1="18.6289" y1="25.2875" x2="28.0081" y2="25.2875" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_575_6629" x1="19.7059" y1="22.6682" x2="24.4223" y2="24.9662" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                        <linearGradient id="paint2_linear_575_6629" x1="21.284" y1="26.3662" x2="24.3741" y2="27.8722" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                        <linearGradient id="paint3_linear_575_6629" x1="41.8045" y1="10.0017" x2="32.4253" y2="10.0017" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                        <linearGradient id="paint4_linear_575_6629" x1="40.7273" y1="22.6682" x2="36.0109" y2="24.9662" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                        <linearGradient id="paint5_linear_575_6629" x1="39.1493" y1="26.3662" x2="36.0592" y2="27.8722" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF992E" />
                                                            <stop offset="0.22" stop-color="#F88038" />
                                                            <stop offset="0.67" stop-color="#E74352" />
                                                            <stop offset="1" stop-color="#DA1467" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <div className="game_team_name_season">
                                                    <h4><b>{item.game_title}</b></h4>
                                                    <p>Season: {item.season_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span><b>Team:</b></span> {item.teams_count}</td>
                                        <td>{moment(item.transaction_date).format('MM/DD/YYYY')}</td>
                                        <td>$ {(item.teams_count * item.team_charge_paid).toFixed(2)}</td>
                                    </tr>))}
                                </> }
                                   
                                </tbody>
                            </Table>
                            <div className="payment_summary">
                                <h4>Payment summary</h4>
                                <p>
                                    <span>Applied Coupon</span>
                                    <span> {transaction.coupon_code} </span>
                                </p>
                                <p>
                                    <span>Coupon Description</span>
                                    <span> {transaction.coupon_description}</span>
                                </p>
                                <p>
                                    <span>Discount Amount</span>
                                    <span>- $ {Number(transaction?.discount_amount).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span>Tax({Number(transaction?.transaction_charge_paid) + Number(transaction?.tax_paid)}%)</span>
                                    <span>$ {Number(transaction?.tax_amount_paid).toFixed(2)}</span>
                                </p>
                             
                                <p>
                                    <span>Subtotal</span>
                                    <span>$ {(transaction?.total_amount/100).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span><b> Total : </b></span>
                                    <span>Paid $ {(transaction?.total_amount/100).toFixed(2)}</span>
                                </p>
                            </div>
                        </div>
                        <div className="giu_thankyou_message"><p>Thankyou for Your Support</p></div>
                    </div>
                    <div className="col-xxl-4 col-lg-12 col-md-12 col-xs-12">
                        <div className="payment_description_paidto">
                            <h4 className="heading">Paid To: </h4>
                            <div className="giu_connect_img_label">
                                <div className="giu_img_team">
                                    <Image className="img-fluid" src={PayemntDetailLogo} alt="GIU Team Connect LLC"/>
                                    <p> {paymentInfo.paid_to} </p>
                                </div>
                                <div className="giu_query">
                                    <div className="giu_email_phone">
                                        <h4>For any Query</h4>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M0.000521161 15.5386C0.000521161 12.4246 0.000521161 9.31071 0.000521161 6.19678C0.0396082 6.21919 0.0802587 6.24003 0.117782 6.26505C2.80541 8.05993 5.49251 9.85533 8.17962 11.6507C9.39705 12.4643 10.5785 12.4637 11.7996 11.6486C13.1676 10.735 14.5357 9.82093 15.9037 8.90733C17.1962 8.04377 18.4882 7.18021 19.7812 6.31821C19.8328 6.28381 19.8849 6.21293 19.95 6.24472C20.0178 6.27808 19.9792 6.36199 19.9881 6.42348C19.9907 6.44277 19.9881 6.46257 19.9881 6.48185C19.9881 9.37325 19.9886 12.2652 19.9881 15.1566C19.9881 16.2234 19.1803 17.0369 18.1135 17.0374C12.6929 17.0395 7.27175 17.038 1.85116 17.039C1.68074 17.039 1.51449 17.0166 1.35137 16.9697C0.621745 16.7586 0.193351 16.2599 0 15.5391L0.000521161 15.5386Z" fill="#484848"/>
                                                <path d="M0.000976563 3.49938C0.195369 2.77913 0.623242 2.28038 1.35235 2.06879C1.51495 2.02137 1.68172 2 1.85214 2C7.27325 2.00104 12.6944 2 18.1155 2.00156C18.9634 2.00156 19.6758 2.53002 19.9166 3.32583C20.1569 4.11904 19.8723 4.90599 19.1552 5.38546C16.4639 7.18659 13.7711 8.98564 11.0762 10.7816C10.3497 11.2657 9.61326 11.2584 8.88729 10.7743C6.19862 8.98042 3.51256 7.18242 0.823368 5.39015C0.389241 5.10091 0.118759 4.70743 0.000976562 4.20295L0.000976563 3.49938Z" fill="#484848"/>
                                            </svg>
                                            {paymentInfo.email}
                                        </span>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                <path d="M16.6922 20.9951C15.1128 20.9526 13.6975 20.5762 12.3633 19.9345C7.89733 17.7872 4.54708 14.4787 2.26489 10.0902C1.66192 8.93044 1.24453 7.69618 1.0746 6.39335C0.880068 4.89971 1.02168 3.46943 2.03383 2.26051C2.566 1.62475 3.24723 1.18947 4.07454 1.03966C4.89738 0.890598 5.61662 1.16488 6.10779 1.80884C6.90753 2.85752 7.66106 3.9442 8.37806 5.05102C8.9855 5.98864 8.70675 7.2624 7.81236 7.95258C7.55522 8.15158 7.28988 8.34089 7.0156 8.51604C6.84045 8.62784 6.79871 8.71355 6.93436 8.90212C7.9495 10.322 9.10699 11.6144 10.374 12.8114C11.2267 13.6163 12.1241 14.3676 13.0803 15.0488C13.257 15.1741 13.3516 15.1741 13.4701 14.9825C13.6356 14.7142 13.8242 14.4585 14.0157 14.2074C14.761 13.2302 16.0847 13.0066 17.1029 13.7005C18.0785 14.3654 19.0459 15.0421 20.0059 15.7293C20.9532 16.4083 21.2357 17.5092 20.7527 18.5698C20.0879 20.0284 18.9036 20.7476 17.3548 20.9362C17.1066 20.9667 16.8584 21.0144 16.6929 20.9958L16.6922 20.9951Z" fill="#484848"/>
                                            </svg>
                                            {paymentInfo.phone_number}
                                           
                                        </span>
                                    </div>    
                                    <span className="giu_address">
                                        <h4> Address Details</h4>
                                        <p>  {paymentInfo.street_address}</p>
                                        <p>  {paymentInfo.po_box} </p>
                                        <p>  {paymentInfo.city },{paymentInfo.state } , {paymentInfo.postal_code}    </p>
                                        <p>  {paymentInfo.country}   </p>
                                    </span>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </span>
    )
}

export default PaymentHistoryDetail;