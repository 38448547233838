import { useEffect, useState } from 'react'
import { ApiGetAuth } from '../../../helpers/apiCall'
import Cardloader from '../../Mainloader.js/Cardloader';
import { generateImage} from "../../../helpers/helperfunctions"
import NoData from '../../../components/NoData';
import Loader from '../../Mainloader.js/Loader';

function Standings({ groupid, teams }) {
  const [data, setData] = useState(null)
  useEffect(() => {
    ApiGetAuth(`games/get-standings/${groupid}`).then(
      (data) => {

         setData(data.data)
      },
      (err) => {
        console.log(err);
      }
    );
  }, [])
  return (
    <>
     <div className='standings-section'>
     <div className="outer-warp ">
    <>{data==null ? <><Loader/></> :<> {data.length > 0 ?<>
      <table className="table table-striped standing-table">
    <thead>
      <tr>
        <th scope="col">TEAMS </th>
        <th scope="col">WIN</th>
        <th scope="col">LOSS</th>
        <th scope="col">DRAW</th>
        <th scope="col">PCT</th>
        <th scope="col">HTH</th>
        <th scope="col">AP DIFF</th>

      </tr>
    </thead>
    <tbody>
        {data?.length ?
          <>
            {
              data?.map((item, i) => {
                return <tr key={item.id}>
                  <th scope="row"> <div className="flag-img">{i + 1}<img src={item.team_logo || generateImage(item.team_name)} /><span>{item.team_name}</span></div></th>
                  <td>{item.wins}</td>
                  <td>{item.losses}</td>
                  <td>{item.draws}</td>
                  <td>{item.PCT}</td>
                  <td>--</td>
                  <td>{item.AP_DIFF}</td>
                </tr>
              })
            }
          </> :

          <></>}
     
    </tbody>
  </table>
    </>:<>
    <div className='table-responsive standing-tb'>
    <div className="No-game"> <NoData message='No Standinds For Today ! ' helpText='As soon as you create new games it would populate on the current Day.' /></div>
</div>
    </> }</>} </>




</div>
     </div>

    </>
  )
}

export { Standings }