import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    dateBefore
} from "../../../helpers/commonFunction";
import { Apipost, ApiGetAuth } from "../../../helpers/apiCall";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

const validationSchema = Yup.object({
    firstname: Yup.string()
        .matches(/^[a-zA-Z\s'-]+$/, 'Invalid Name')
        .required('Name is required')
        .max(30, 'Name cannot be longer than 30 characters'),
                        // lastname: Yup.string()
        // .matches(/^[a-zA-Z\s]+$/, 'Invalid last name')
        // .required('Last Name is required')
        // .max(30, 'last Name cannot be longer than 30 characters'),
    email: Yup.string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Invalid email address')
        .required('Email is required')
        .max(50, "Email must be at most 50 characters"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Date of Birth is required"),
    weight:Yup.number().required("Weight is  Required"),
    grade:Yup.number().required("Grade is Required")
});

const CreatePlayer = ({teamid,setShowModal,price,teamdetails}) => {
    const [image, setImage] = useState("");
    const [loader , setLoader] = useState(false)
    const [gradeOptions, setGradeOptions] = useState([])
    const [imgSource, setImgSource] = useState('');
    const formik = useFormik({
        initialValues: {
            firstname: "",
            // lastname: "",
            email: "",
            phoneNumber: "",
            gender: "",
            dob: "",
            role: 4,
            weight: null,
            grade:null,
           
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoader(true)
            let temp = { ...values }
            if (imgSource) temp = { ...temp, image: imgSource }
              temp = { ...temp, teamid }
            Apipost('/users/add-user', temp, "multipart/form-data", true).then(
                (success) => {
                    successMessageNodify(success?.data.message);
                    setShowModal(false);
                    setLoader(false)
                },
                (err) => {
                    errorMessageNodify(err.response?.data.message);
                    setLoader(false)
                }
            );
        }
    });
    useEffect(() => {
        ApiGetAuth('teams/get-grades').then((data) => {
            if (data.data) {
                setGradeOptions(data.data)
            }
        }, (err) => {
            console.log(err);
        })
    }, [])

    const [Approver, setApprover] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [teamList, setTeamList] = useState([]);

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImgSource(event.target.files[0]);
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    useEffect(() => {
        ApiGetAuth(dropDown).then((data) => {
            setTeamList(data.data);
        }, (err) => {
            console.log(err);
        })
    }, []);

    const customCheck = "Team"
    const dropDown = 'teams/get-teams'

    // return (<CommonForm values={values} setValues={setValues} dropDown={'teams/get-teams'}  customCheck="Team" apiRoute={apiRoute}  redirectPath={redirectPath} />);

    return <>
        <>
            <div className="dashboard-auto-wrapper season-popup">
                <div className="create-from">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img
                                    alt=""
                                    src={image ? image : require("../../../assets/images/user-100.png")}
                                    onClick={handleClick}
                                />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={onImageChange}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <FontAwesomeIcon icon={faLink} /> Add profile image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="mb-4">
                                    <label className="form-label" htmlFor="firstname">Full Name</label>
                                    <input
                                    max={30}
                                        className="form-control"
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstname}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname ? (
                                        <div className="ErrorHandling">{formik.errors.firstname}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                {/* <div className="mb-4">
                                    <label htmlFor="lastname" className="form-label">Last Name</label>
                                    <input
                                    max={30}
                                        className="form-control"
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastname}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname ? (
                                        <div className="ErrorHandling">{formik.errors.lastname}</div>
                                    ) : null}
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="ErrorHandling">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                    <PhoneInput
                                        country={'us'}
                                        inputClass="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={phoneNumber => formik.setFieldValue("phoneNumber", phoneNumber)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phoneNumber}
                                    />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <div className="ErrorHandling">{formik.errors.phoneNumber}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label htmlFor="gender" className="form-label">Gender</label>
                                    <select
                                        className="form-control"
                                        id="gender"
                                        name="gender"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.gender}
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div className="ErrorHandling">{formik.errors.gender}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label htmlFor="dob" className="form-label">Date of Birth</label>
                                    <input
                                        className="form-control"
                                        max={dateBefore(3)}
                                        type="date"
                                        id="dob"
                                        name="dob"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.dob}
                                    />
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <div className="ErrorHandling">{formik.errors.dob}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-4">
                                            <label className="form-label" htmlFor="weight">Weight</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="weight"
                                                name="weight"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.weight}
                                            />
                                            {formik.touched.weight && formik.errors.weight ? (
                                                <div className="ErrorHandling">{formik.errors.weight}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-4">
                                            <label htmlFor="grade" className="form-label">Grade</label>
                                            <select
                                                className="form-control"
                                                id="grade"
                                                name="grade"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.grade}
                                            >
                                                <option value="">Select</option>
                                                {gradeOptions?.map(item => {
                                                    return <option value={item.id}>{item.grade_name} </option>
                                                })}
                                            </select>
                                            {formik.touched.grade && formik.errors.grade ? (
                                                <div className="ErrorHandling">{formik.errors.grade}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                        {/* <PaymentPopup  handlesubmit ={formik.handleSubmit} Content ={"Pay and Add"} price={price} playerCount ={1} tax={1} src= {image ? image : require("../../../assets/images/user-100.png")} formik={formik} teamdetails={teamdetails} /> */}
                            <button
                                 disabled={loader}
                                 type="submit"
                                className="btn btn-dark savesubmit"
                                onClick={formik.handleSubmit}
                            >
                            {loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : " Add"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    </>
};

export default CreatePlayer;
