import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AlertDismissible({ message, duration, visible,sportId, id }) {
  const [show, setShow] = useState(visible);
  const [timer, setTimer] = useState(duration);
  
  useEffect(() => {
    setShow(visible);
    setTimer(duration);
  }, [visible, duration]);

  useEffect(() => {
    if (show && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }

    if (timer === 0) {
      setShow(false);
    }
  }, [show, timer]);

  return (
    <>
      {show && (
        <div className="alertdismiss mt-3">
          <div className="alert alert-success" role="alert">
            {message} - <Link target={"_blank"} to={`/organizer/${sportId}/slots/AddtimeSlots?v=${id}`} state={{id:id, sportsId: sportId }} className='mx-2'>Click here</Link>{timer} seconds
          </div>
        </div>
      )}
    </>
  );
}

export default AlertDismissible;
