import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Loginpage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/';
	const [email, setEmail] = useState('');
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();

		setLoading(true);
		if (!error.length) Apipost('/auth/reset', { email }).then((success) => {
			successMessageNodify("Password Reset Sucessfully")
			navigate(fromPage, { replace: true })
		}, err => {
			console.log(err)
			errorMessageNodify(err.response.data.message)
			setLoading(false);
			return;
		})

	}
	const handleError = () => {
		if (email.length > 1 && !/[^\s@]+@[^\s@]+\.[^\s@]+/.test(email)) setError('You have entered an invalid email address!')
		else if (!email.length) setError('Email address Cannot be Empty!')
	}
	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={require("../../../assets/images/GIU_logo.png")} alt="Whatson" />
							<p>Reset Password</p>
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit}>
								<div className="mb-4">
									<label>Email</label>
									<input type="text" id="email" className="form-control" placeholder="Email" onFocus={() => setError('')} onBlur={handleError} onChange={(e) => setEmail(e.target.value)} />
									{error ? <div className="text-danger">{error}</div> : <br />}
								</div>
								<div className="mb-3">
									<button className="btn btn-dark green full-btn btn-lg signin-btn"> {
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Get New Password'
									}</button>
								</div>
							</form>
						</div>
						<div className="form-bottom-link">
						<Link to="/register"><b>Register</b></Link> | <Link to="/"><b>Login Now</b></Link>
						</div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>GUI: Organizer Panel</h1>
							<p>Login to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loginpage;
