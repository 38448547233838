import { getCoachDetails, getRefreeDetails, getTeamDetails, getVenueDetails, getplayerDetails, getSportsDetails, getGradeList, getAgeGroupList, getGameList, getprofileDetails, getPaymentDetails} from "./actions";
import { ApiGetAuth, ApiGet } from "../helpers/apiCall";



const fetchplayers = () => {
    return function (dispatch) {
        ApiGetAuth('users/user-list/4').then(
        (success) => {
          const playerslist = success.data;
          dispatch(getplayerDetails(playerslist));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };
  const fetchProfileDetails = () => {
    return function (dispatch) {
        ApiGetAuth('profile/profile-details').then(
        (success) => {
          const profileDetails = success.data;
          dispatch(getprofileDetails(profileDetails));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };


  const fetchTeams = () => {
    return function (dispatch) {
        ApiGetAuth('teams/get-teams').then(
        (success) => {
          const Teamlist = success.data;
          dispatch(getTeamDetails(Teamlist));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };

  const fetchCoaches = () => {
    return function (dispatch) {
        ApiGetAuth('users/user-list/all-users').then(
        (success) => {
          const Teamlist = success.data;
          dispatch(getCoachDetails(Teamlist));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };
  
  const fetchSports = () => {
    return function (dispatch) {
        ApiGetAuth('users/user-sportslist').then(
        (success) => {
          const SportList = success.data;
          dispatch(getSportsDetails(SportList));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };

  const fetchGrades = () => {
    return function (dispatch) {
      ApiGetAuth('teams/get-grades').then(
        (success) => {
          const GradeList = success.data;
          dispatch(getGradeList(GradeList));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };

  const fetchAgeGroup = () => {
    return function (dispatch) {
        ApiGetAuth('games/get-agegroup').then(
        (success) => {
          const AgeGroupList = success.data;
          dispatch(getAgeGroupList(AgeGroupList));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };
  
  
  const fetchVenues = () => {
   
    return function (dispatch) {
        ApiGetAuth('venue/venue-field-list').then(
        (success) => {
          const venueList = success.data;
          dispatch(getVenueDetails(venueList));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };

  const fetchRefrees = () => {
    return function (dispatch) {
        ApiGetAuth('/users/user-list/all-users').then(
        (success) => {
          const Teamlist = success.data;
          dispatch(getRefreeDetails(Teamlist));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };
  const fetchGameList = () => {
    return function (dispatch) {
        ApiGetAuth('games/get-games').then(
        (success) => {
          const GameList = success.data;
          dispatch(getGameList(GameList));
        },
        (error) => {
         console.log(error.message);
        }
      );
    };
  };

  const fetchPaymentDetail=()=>{
    return function(dispatch){
      ApiGetAuth(`payment/get-paymentDetails`).then(
        (success) => {
          const paymentData=success.data
                dispatch(getPaymentDetails(paymentData))
        },
        (err) => {
            console.log(err);
        }
    );
    }
  }
  
  export {fetchplayers,fetchProfileDetails,fetchTeams,fetchCoaches,fetchVenues,fetchRefrees,fetchSports,fetchGrades,fetchAgeGroup,fetchGameList,fetchPaymentDetail}