import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link,useNavigate } from 'react-router-dom';
import { ApiGetAuth} from '../../../helpers/apiCall';
import { Loader } from '../../../assets/Loader/Loader';
import {Table, Pagination} from 'react-bootstrap'
import withLoader from '../../../components/PageLoader';

const PlayerwithLoader = () => {
	const [isFilter, setisFilter] = useState(false);
	const navigate = useNavigate()
	const [show, setShow] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filteredList, setFilteredList] = useState(null)
    const [playerList, setPlayerList] = useState([]);
	const [reload,setReload] = useState(false)
	const routePath = process.env.REACT_APP_DEV_MODE
	const columns = [
		{ id: 'basic', label: 'Name', minWidth: 100 },
		{ id: 'phone', label: 'Phone', minWidth: 100 },
		{ id: 'email', label: 'E-mail', minWidth: 100 },
		{ id: 'gender', label: 'Gender', minWidth: 100 },
		{ id: 'team', label: 'Teams', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
		{ id: 'status', label: 'Status', minWidth: 100 },
	];


	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

       const handleUpdate=(id) =>{
		ApiGetAuth(`users/update-status/${id}`).then((data) => {
			setReload((prev)=>!prev)
        }, (err) => {
            console.log(err);
        })
	   }

    useEffect(() => {
        ApiGetAuth('users/user-list/4').then((data) => {
            setPlayerList(data.data);
			setFilteredList(data.data)
        }, (err) => {
            console.log(err);
        })
    }, [reload]);

	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" onChange={(e)=>{
						const temp = playerList?.filter(obj => obj?.firstname?.startsWith(e.target.value.trim()))
						setFilteredList(temp)
						}} />
				</div>
				<Link to="/organizer/new-player">
					<button className="btn btn-dark green green" >Add Player</button>
				</Link>
			</div>
	
			
				<Table className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<thead>
							<tr>
								{columns?.map((column) => (
									<td
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</td>
								))}
							</tr>
						</thead>
						<tbody>
							{filteredList?.length?
							filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
								return (
									<tr hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns?.map((column) => {
											// const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<td>
															<button className="btn " onClick={()=>navigate('/organizer/player/edit-player',{state:{row}})}> <FontAwesomeIcon icon={faPenToSquare} /></button>
														</td>
													) : column.id === 'phone' ? (
														<td>
															<a href={`tel:${row?.contact_number}`}>
																{row?.contact_number}
															</a>
														</td >
													) : column.id === 'email' ? (
														<td>
															<a className='text-primary' href={`mailto:${row?.email}`}>
															{row?.email}
															</a>
														</td >
													) :  column.id === 'status' ? (
														<td>
															{/* <Switch
															checked={row.enabled}
															onClick={()=>handleUpdate(row.id)}
															name="checkedB"
															color="primary"
														/> */}
														</td >
													) :  column.id === 'basic' ? (
														<td key={column.id} align={column.align} className="table-user">
														 <img alt="" crossorigin="anonymous" src={(row?.profile_pic)?(routePath + '/public/' + row?.profile_pic): require("../../../assets/images/user-100.png")} /> {row?.firstname} {row?.lastname}
														</td>
													) :  column.id === 'gender' ? (
														<td key={column.id} align={column.align}>
														{row?.gender}
														</td>
													):(
														<td key={column.id} align={column.align}>
														{row?.team_name}
														</td>
													)}
												</>
											);
										})}

									</tr>
							)}) :
								<tr hover role="checkbox" tabIndex={-1} key={'1'}>
									<td colSpan={7}>
										{(filteredList === null)?<Loader/>:"No records found!"}
									</td>
								</tr>
						}
						</tbody>
					</Table>
				</Table>
				<Pagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={playerList?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			


			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<label>By Team</label>
										<input type="text" className="form-control" placeholder="Select Item" />
									</div>
								</div>

								<div className="mb-4">
									<div className="input-icon">
										<label>By Season</label>
										<input className="form-control" placeholder="Select Season" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Role</label>
										<input className="form-control" placeholder="Select role" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<div className="mb-4">
											<h3 className="title">By active</h3>
											<button className="btn activities">Active</button>
											<button className="btn activities">InActive</button>
										</div>
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Gender</label>
										<input className="form-control" placeholder="" />
									</div>
								</div>

							</div>
							<div className="filter-form-btns">

							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Upload CSV file to import users</h4>
										<div className="mb-4">
											<span>Your file must have the following field order to be processed correctly:</span>
											<code>
												first name, last name, email, phone, date of birth, Gender
											</code>
										</div>

										<div className="pop-form">
											<div className="mb-3">
												<div className="uploade-file">
													<label className="form-label">Upload Documents</label>
													<label className="custom-file-upload">
														<input type="file" />
														Choose file
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green green btn-long">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</>
			}
		</>

	);
};

const Player = withLoader(PlayerwithLoader)

export default Player;