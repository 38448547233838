import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApiGetAuth, Apipost } from "../../helpers/apiCall";
import { useParams } from "react-router";
import { errorMessageNodify, successMessageNodify } from "../../helpers/notify";
import { formatDivisions } from "../../pages/Organizer/Seasons";
import FileUpload from "../FileUpload";

const CopySeasonModal = ({ setRefresh }) => {
  // State variables
  const { sportId, gameId, type, seasonId } = useParams()
  const [show, setShow] = useState(false);
  const [copySeasonId, setCopySeasonId] = useState(0);
  const [copyTeams, setCopyTeams] = useState(true);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [divisions, setDivisions] = useState([])
  const [data , setData] = useState({})
  const [selectedImportDivisions, setSelectedImportDivisions] = useState([]);

  // Handle division selection
  const handleDivisionSelection = (divisionId, isSelected) => {
    if (isSelected) {
      setSelectedDivisions([...selectedDivisions, divisionId]);
    } else {
      setSelectedDivisions(selectedDivisions.filter(id => id !== divisionId));
    }
  };
  const handleDivisions = (id) => {
    if(id > 0){
      ApiGetAuth(`season/get-divisions?id=${id}`).then(
        (success) => {
          console.log(success, "success")
          setDivisions(success.data)
          setSelectedDivisions(success.data.map((item) => item.id));
          setData({})
          return;
        }, (err) => {
          // setLoading(false);
          return;
        }
      )
    }
   
  }


  const handleAddDivisions = () => {
    if(seasonId > 0 && (selectedDivisions.length ||   Object.keys(data).length > 0 ))
    Apipost(`/season/add-divisions-in-season`, { copySeasonId: seasonId, copyTeams, selectedDivisions, selectedImportDivisions ,ImportData: data,gameId  }, "application/x-www-form-urlencoded", true).then(
      (reponse) => {
        if (reponse.data.status === true) {
          handleClose()
          successMessageNodify(reponse.message);
          setRefresh(prev => !prev)
          return;
        } else {
          errorMessageNodify(reponse.msg);
          return;
        }
      },
      (err) => {
        errorMessageNodify(err.msg);
        return;
      }
    );
    else errorMessageNodify("Please Fill All the Details ");
  }



  const fetchSeasons = async () => {
    try {
      const response = await ApiGetAuth(`season/List-season/${gameId}`);
      setSeasons(response.data);
    } catch (error) {
      console.error('Error fetching seasons:', error);
    }
  };
  useEffect(() => {
    if (seasons.length == 0) {
      fetchSeasons()
    }
    handleDivisions(copySeasonId)
  }, [copySeasonId])

  // Handle modal show and hide
  const handleClose = () => {
    setShow(false);
    // Reset states if needed when closing the modal
    setCopySeasonId(0);
    setCopyTeams(false);
    setSelectedDivisions([]);
    setData({})
  };
  const handleShow = () => setShow(true);
  const handleFileUploaded = (jsonArray) => {
    setData(formatDivisions(jsonArray));
    setCopySeasonId(0)
    setDivisions([])
    setSelectedDivisions([])
  };

  return (
    <>
      <button className="btn btn-primary mx-2" onClick={handleShow}>
        Import Divisions
      </button>

      <Modal show={show} onHide={handleClose}
        dialogClassName="modal-md"
      
      >

        <Modal.Title><h5>Import Grade/Division</h5></Modal.Title>

        <Modal.Body>
          {/* Season Selection */}
          <div className="mt-3 mb-3">
            <div className="form-group division-check ">
            <FileUpload  text='Import divisions and teams' onFileUploaded={handleFileUploaded} requiredKeys={['Division', 'Team']} sampleFileUrl="https://teamconnect-media.nyc3.cdn.digitaloceanspaces.com/sample-data/GIU_Season_Sample.csv"  />
              <label className="form-label mb-3">Copy Season From</label>
              <select
                value={copySeasonId}
                className="form-select mt-3"
                onChange={(e) => setCopySeasonId(Number(e.target.value))}
              >
                <option value={0}>Select Season</option>
                {seasons?.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.season_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Conditionally render the following sections when a season is selected */}
          {copySeasonId > 0 && divisions.length >0 && (
            <>
              {/* Copy Teams Option */}
              <div className="mt-4 mb-4">
                <div className="form-group d-flex justify-content-between mb-3">
                  <h6> Do You Want to Copy Teams Along with Divisions?</h6>
                  <div className=" form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="copyTeamsYes"
                      checked={copyTeams}
                      onChange={(e) => setCopyTeams(e.target.checked)}
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '4px',
                        border: '2px solid #ccc',
                        cursor: 'pointer'
                      }}
                    />
                   
                  </div>
                </div>
              </div>

              {/* Divisions Selection */}

              <div className="form-group">
                <label className="form-label">Divisions</label>
                <div className="row division-check">

                  {divisions.map(item => (
                    <div className="col-md-4 py-2" key={item.id}>
                      <div className="">
                        <input
                          className="form-check-input  custom-checkbox  mx-2"
                          type="checkbox"
                          id={`division_${item.id}`}
                          checked={selectedDivisions.includes(item.id)}
                          onChange={(e) =>
                            handleDivisionSelection(item.id, e.target.checked)
                          }
                        />
                        <label className="form-check-label" htmlFor={`division_${item.id}`}>
                          {item.group_name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
                   {
                    Object.keys(data).length > 0 && (<>
                      <div>
                      <label>Imported Divisions</label>
                         <div className='row' style={{ listStyleType: 'none' }}>
                           {Object.keys(data).filter((divisionName) => !divisionName.endsWith('_color')).map((divisionName) => (
                             <div className='col-md-4 py-2' key={divisionName}>
                               <input
                                 type="checkbox"
                                 value={divisionName}
                                 checked={selectedImportDivisions.includes(divisionName)}
                                 onChange={(e) => {
                                   const isChecked = e.target.checked;
                                   setSelectedImportDivisions(prev =>
                                     isChecked
                                       ? [...prev, divisionName] // Add division
                                       : prev.filter(name => name !== divisionName) // Remove division
                                   );
                                 }}
                                 className="form-check-input mx-1 custom-checkbox"
                               />
                               {divisionName} ({data[divisionName].length} Teams)
                             </div>
                           ))}
                         </div>
                    </div>
                    </>)
                   }

          <div className="d-flex division-flow mt-3 py-3">
            <button className="btn btn-primary" onClick={handleAddDivisions} disabled={!(Object.keys(data).length || divisions.length) }>
              Import
            </button>
            <button className="btn btn-danger mx-2" onClick={handleClose}>Cancel</button>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default CopySeasonModal;
