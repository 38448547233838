import React from 'react';

const ComingSoonCard = () => {
  return (
    <div className='Notification-empty-box' style={styles.container}>
      <div style={styles.card}>
        <div className='empty-state__message' style={styles.comingSoon}>Coming Soon!</div>
  {/* <div style={styles.description}>Our new Feature is coming soon. Stay tuned for updates.</div> */}
        {/* <button style={styles.btn}>Learn More</button> */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: '300px',
    padding: '20px',
    textAlign: 'center',
  },
  comingSoon: {
    fontSize: '1.2rem',
    color: '#38a169',
    marginBottom: '0.3rem',
  },
  description: {
    fontSize: '16px',
    color: '#a2a5b9',
    marginBottom: '20px',
  },
  btn: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default ComingSoonCard;
