import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { ApiGetAuth } from '../../helpers/apiCall';
import { generateImage } from '../../helpers/helperfunctions';
import NoData from '../NoData';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import CopyToClipboard from '../CopytoClipboard';

function Collapse({item,season}) {
	const {sportId,type,gameId} = useParams()
	const [values , setValues] = useState(null)
	const navigate = useNavigate()
	useEffect(()=>{
		if(item?.id){
			ApiGetAuth(`games/get-gameDetails/${item?.id}`).then(
				(data) => {
				  if (data?.data) { 
					  setValues(data?.data)
				  }
				}
			  );
		}
		
	  },[item?.id])



  return (
    < >
   <div className='table-scroll-2'>
	   <Table striped >
					<thead>
						<tr>
							<th>Team</th>
							<th>Members</th>
							<th></th>
						</tr>
					</thead>
					<tbody  >
					{
						values == null ? <div className="spinner-border text-center" role="status">
                           <span className="visually-hidden"><NoData /></span>
                         </div>:<>
						{!values.length?<>No Teams Found</>:<>
							{values.map((teamDetails , index)=> <>{teamDetails.teams?.map(teamData => {
							return <tr>
								<td className="table-color link-menu " onClick={()=>navigate(`/organizer/${sportId}/events/${type}/${gameId}/${season}/${item.id}`, {state:{type:'teamDetails', teamId:teamData.id}})} ><div className='d-flex align-item-center '><img src={teamData.team_logo ||  generateImage(teamData.team_name)} width='30px' alt='Team Logo not Found'  /> <label className='label-setting'>{teamData.team_name}</label></div></td>
								<td className="table-color link-menu text-end" onClick={()=>navigate(`/organizer/${sportId}/events/${type}/${gameId}/${season}/${item.id}`, {state:{type:'teamDetails', teamId:teamData.id}})} >{teamData.teamMembers + (teamData.lastname ==''?1:0)}</td>
								<td className="table-color link-menu"  > <CopyToClipboard text={`https://giuteamconnect.com/app/?type=team&code=${teamData.team_code}`}  /> </td>
							</tr>
						})}</>
							)}
						</>}
						</>
					}
					</tbody>
				</Table>
				</div>
    </>
  );
}

export default Collapse;