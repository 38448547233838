import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ApiGet, ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faLink } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import ChangePassword from './ChangePassword';
import Sports from './Sports';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileDetails, fetchSports } from '../../../redux/api';
import withLoader from '../../../components/PageLoader';
import { generateImage } from '../../../helpers/helperfunctions';






const EditOrganizationWithLoader = () => {
	const [image, setImage] = useState("");
	const [profileDetails, setProfileDetails] = useState()
	const hiddenFileInput = React.useRef(null);
	const [sportsList, setSportsList] = useState([]);
	const [selectedSports, setSelectedSports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loader, setLoader] = useState(false)
	const selector = useSelector(state => state)
	useEffect(() => {
		ApiGet("games/get-sports").then(
			(data) => {
				setSportsList(data.data);
			}
		);
		let temp = []
		selector.SPORTSLIST.map((e) => temp.push(e.id.toString()))
		setSelectedSports(temp)
	}, []);

	const handleChange = (e) => {
		const { value, checked } = e.target;
		if (checked) {
			setSelectedSports((prev) => [...prev, value])
		}
		else {
			setSelectedSports(selectedSports.filter((e) => e !== value))
		}

	};

	// const handleSubmit = () => {
	// 	setLoader(true)

	// }
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};
	const dispatch = useDispatch()
	const onTextChange = (e, value) => {
		value((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};
	useEffect(() => {
		setProfileDetails(selector.PROFILEDETAILS)
	}, [])

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
		}
	};

	const handleUpdate = () => {
		if (image) profileDetails.image = image
		profileDetails.id = JSON.stringify(selectedSports)
		setLoader(true)
		if(selectedSports.length ==0){
			 errorMessageNodify('Cannot Remove All the Sports')
			 setLoader(false)
			return
		};
		Apipost('/profile/update-profile-details', profileDetails, "multipart/form-data", true).then(
			(response) => {
				localStorage.setItem('name', profileDetails.firstname)
				dispatch(fetchProfileDetails())
				dispatch(fetchSports())
				setLoader(false)
				successMessageNodify(response?.data.message)
				
			},
			(err) => {
				setLoader(false)
				errorMessageNodify(err.response?.data.message);
				return;
			}
		);

	}


	return (
		<>

			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs defaultActiveKey="account" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="account" title="Profile  Details">
							<div className="row">
								<div className="col-lg-12">
									<div className="d-flex justify-content-between align-items-center">

									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="profile-img-box row">
												<span className='col-md-5'></span>
												<div className='col-md-4'>
													<div >
														<img
															className="rounded-circle adim-profile-img"
															src={
																(image ? URL.createObjectURL(image) : (profileDetails?.profile_pic) ? profileDetails.profile_pic
																	: generateImage(profileDetails?.firstname + ' '+ profileDetails?.lastname))
															}
															alt=""
															onClick={handleClick}
														/>
													</div>
													<div className="cperson-detail">
														<label className="profile-file-upload">
															<input
																type="file"
																ref={hiddenFileInput}
																onChange={onImageChange}
																accept="image/*"
															/>{" "}
															Change profile image
														</label>
													</div>
												</div>
												<span className='col-md-3'></span>
											</div>
											<div className="row my-2">
												<div className="col-sm-6">
													<label className="form-label"> First Name</label>
													<input className="form-control" value={profileDetails?.firstname} name="firstname"
														onChange={(e) => onTextChange(e, setProfileDetails)}
													/>
												</div>
												<div className="col-sm-6">
													<label className="form-label">Last Name</label>
													<input className="form-control" value={profileDetails?.lastname} name="lastname"
														onChange={(e) => onTextChange(e, setProfileDetails)}
													/>
												</div>
											</div>
											<div className="row my-2">
												<div className="col-sm-6">
													<label className="form-label">Email</label>
													<input className="form-control" value={profileDetails?.email} disabled />
												</div>
												<div className="col-sm-6">
													<label className="form-label">Contact</label>
													<PhoneInput
														country={'us'}
														inputClass="form-control"
														id="phoneNumber"
														name="contact_number"
														onChange={phoneNumber => setProfileDetails({ ...profileDetails, contact_number: phoneNumber })}
														value={profileDetails?.contact_number}
													/>

												</div>
											</div>
											<div className='row'>
											<label className="form-label">About</label>
											<input className="form-control" name='about' onChange={(e) => onTextChange(e, setProfileDetails)} value={profileDetails?.about}  />
											</div>
											<Sports sportsList={sportsList} selectedSports={selectedSports} handleChange={handleChange} loading={loading} />

											<div className="row my-2">
												<div className=" divisions-schedule">
													<div className="create-from-btn submission update-sports">
														<button className="btn btn-dark savesubmit" onClick={handleUpdate} disabled={loader?true:false} > {loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Update"}</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey='password' title='Password'>
							<ChangePassword />
						</Tab>
					</Tabs>
				</div>
			</div>
		</>
	);
};


const EditOrganization = withLoader(EditOrganizationWithLoader);

export default EditOrganization;