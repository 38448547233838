import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Apipost } from '../../../helpers/apiCall';
import { Link } from 'react-router-dom';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';

function BreadcrumbComponent({data}) {
  const params  = useParams();
  const {type} = params
const [routes , setRoutes] = useState([])
const [videoUrl , setVideoUrl] = useState([])
var role = localStorage.getItem('role')
useEffect(()=>{
  Apipost("/common/get-breadcrum", params, "application/x-www-form-urlencoded", true).then(
    (response) => {
        let result = response.data;
        if (result.status === true) {
          setVideoUrl(result.data.videoUrl)
          delete result.data.videoUrl
          setRoutes(result.data)
        }
    }
);
},[params])


    return ( 
      <Breadcrumb className="breadcrumb_dashboard">
      <Breadcrumb.Item>
      <Link to='/organizer/dashboard'  >
            Dashboard
      </Link>
      </Breadcrumb.Item>
      {routes?.sports ? (
        <Breadcrumb.Item active={(Object.keys(routes).length) === 1? true:false}>
          <Link to={`/${role}/${params.sportId}/events/${type}`}>
            {routes?.sports[0]?.sports_name}
          </Link>
        </Breadcrumb.Item>
      ) : null}
      {routes?.game ? (
        <Breadcrumb.Item active={Object.keys(routes).length === 2? true:false}>
          <Link to={`/${role}/${params.sportId}/events/${type}/${params.gameId}`} >
            {routes?.game[0]?.game_title}
          </Link>
        </Breadcrumb.Item>
      ) : null}
      {routes?.season ? (
        <Breadcrumb.Item active={Object.keys(routes).length === 3? true:false}>
          <Link to={`/${role}/${params.sportId}/events/${type}/${params.gameId}/${params.seasonId}`}>
            {routes?.season[0]?.season_name}
          </Link>
        </Breadcrumb.Item>
      ) : null}
      {routes?.group ? (
        <Breadcrumb.Item active={Object.keys(routes).length === 5? true:false}>
          <Link to={`/${role}/${params.sportId}/events/${type}/${params.gameId}/${params.seasonId}/${params.groupId}`}>
            {routes?.group[0]?.group_name}
          </Link>
        </Breadcrumb.Item>
      ) : null}
    {
      (data?.length > 0)?<>{<PlayModal url={ data[0]['video_link']} data ={data} />}</>:<>{(videoUrl?.length >0) ?(<PlayModal url={videoUrl[0]['video_link']} data ={data||videoUrl} />):null}</>
    } 
    </Breadcrumb>
     );
}

export default BreadcrumbComponent;