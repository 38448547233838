import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye, faStar, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import FilterIcon from "../../../assets/images/filter-ico.svg";
import { Table, Pagination } from 'react-bootstrap';


const Messages = () => {
	const [isFilter, setisFilter] = useState(false);
	const [isFav, setisFav] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const markfav = () => {
		setisFav(isFav === false ? true : false);
	};

	// inbox-table
	const [inboxpage, setInboxPage] = useState(0);
	const [inboxrowsPerPage, setInboxRowsPerPage] = useState(10);

	const inboxcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Recieved At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createInboxData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const inboxrows = [
		createInboxData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangeInboxPage = (event, newPage) => {
		setInboxPage(newPage);
	};

	const handleChangeInboxRowsPerPage = (event) => {
		setInboxRowsPerPage(+event.target.value);
		setInboxPage(0);
	};

	// draft
	const [draftpage, setdraftPage] = useState(0);
	const [draftrowsPerPage, setdraftRowsPerPage] = useState(10);

	const draftcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createdraftData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const draftrows = [
		createdraftData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangedraftPage = (event, newPage) => {
		setdraftPage(newPage);
	};

	const handleChangedraftRowsPerPage = (event) => {
		setdraftRowsPerPage(+event.target.value);
		setdraftPage(0);
	};

	// sent
	const [sentpage, setsentPage] = useState(0);
	const [sentrowsPerPage, setsentRowsPerPage] = useState(10);

	const sentcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createsentData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const sentrows = [
		createsentData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangesentPage = (event, newPage) => {
		setsentPage(newPage);
	};

	const handleChangesentRowsPerPage = (event) => {
		setsentRowsPerPage(+event.target.value);
		setsentPage(0);
	};

	// favourite
	const [favouritepage, setfavouritePage] = useState(0);
	const [favouriterowsPerPage, setfavouriteRowsPerPage] = useState(10);

	const favouritecolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createfavouriteData(email, subject, recieved) {
		return { email, subject , recieved};
	}

	const favouriterows = [
		createfavouriteData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangefavouritePage = (event, newPage) => {
		setfavouritePage(newPage);
	};

	const handleChangefavouriteRowsPerPage = (event) => {
		setfavouriteRowsPerPage(+event.target.value);
		setfavouritePage(0);
	};

	return (
		<>
			<div className="organizer_events">
				<div className="dashboard-top-btn">
					<div className="search">
						<input type="text" className="form-control" placeholder="Search" />
					</div>
					<div className="table-filter filter_button_nav">
						<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={FilterIcon} />Filters</button>
					</div>
					<Link to="/organizer/message/create"><button className="btn btn-dark green"> Create</button></Link>
					{/* <button className="btn btn-dark green" onClick={handleShow} > Create</button> */}
				</div>
				<div className="tab-content venuestable" id="common-tabs-section">
					<Tabs defaultActiveKey="all" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="all" title="Inbox">
							
							<Table className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<thead>
										<tr>
											{inboxcolumns?.map((column) => (
												<td
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</td>
											))}
										</tr>
									</thead>
									<tbody>
										{inboxrows.slice(inboxpage * inboxrowsPerPage, inboxpage * inboxrowsPerPage + inboxrowsPerPage)?.map((row) => {
											return (
												<tr hover role="checkbox" tabIndex={-1} key={'1'}>
													{inboxcolumns?.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<td>
																		<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																		<Link to="/organizer/message/reply"><button className="btn " title="Reply" > <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																		<Link to="/organizer/message/view"><button className="btn " title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</td>
																) : column.id === 'email' ? (
																	<td>
																		<a href={`mailto:${value}`}>
																			{value}
																		</a>
																	</td>
																) :
																	(
																	<td key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</td>
																)}
															</>
														);
													})}

												</tr>
											);
										})}
									</tbody>
								</Table>
							</Table>
							<Pagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={inboxrows.length}
								rowsPerPage={inboxrowsPerPage}
								page={inboxpage}
								onPageChange={handleChangeInboxPage}
								onRowsPerPageChange={handleChangeInboxRowsPerPage}
							/>
							
						</Tab>
						<Tab eventKey="drafts" title="Draft">
							
								<Table className='common-table'>
									<Table stickyHeader aria-label="sticky table">
										<thead>
											<tr>
												{draftcolumns?.map((column) => (
													<td
														key={column.id}
														align={column.align}
														style={{ minWidth: column.minWidth }}
													>
														{column.label}
													</td>
												))}
											</tr>
										</thead>
										<tbody>
											{draftrows.slice(draftpage * draftrowsPerPage, draftpage * draftrowsPerPage + draftrowsPerPage)?.map((row) => {
												return (
													<tr hover role="checkbox" tabIndex={-1} key={'1'}>
														{draftcolumns?.map((column) => {
															const value = row[column.id];
															return (
																<>
																	{column.id === 'action' ? (
																		<td>
																			<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																			<Link to="/organizer/message/edit"><button className="btn "  title="Edit" > <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
																			<Link to="/organizer/message/view"><button className="btn "  title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																		</td>
																	) : column.id === 'email' ? (
																		<td>
																			<a href={`mailto:${value}`}>
																				{value}
																			</a>
																		</td>
																	) :
																		(
																		<td key={column.id} align={column.align}>
																			{column.format && typeof value === 'number' ? column.format(value) : value}
																		</td>
																	)}
																</>
															);
														})}

													</tr>
												);
											})}
										</tbody>
									</Table>
								</Table>
								<Pagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={draftrows.length}
									rowsPerPage={draftrowsPerPage}
									page={draftpage}
									onPageChange={handleChangedraftPage}
									onRowsPerPageChange={handleChangedraftRowsPerPage}
								/>
							
						</Tab>
						<Tab eventKey="sent" title="Sent">
							
								<Table className='common-table'>
									<Table stickyHeader aria-label="sticky table">
										<thead>
											<tr>
												{sentcolumns?.map((column) => (
													<td
														key={column.id}
														align={column.align}
														style={{ minWidth: column.minWidth }}
													>
														{column.label}
													</td>
												))}
											</tr>
										</thead>
										<tbody>
											{sentrows.slice(sentpage * sentrowsPerPage, sentpage * sentrowsPerPage + sentrowsPerPage)?.map((row) => {
												return (
													<tr hover role="checkbox" tabIndex={-1} key={'1'}>
														{sentcolumns?.map((column) => {
															const value = row[column.id];
															return (
																<>
																	{column.id === 'action' ? (
																		<td>
																			<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																			<Link to="/organizer/message/reply"><button className="btn " title="Reply"> <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																			<Link to="/organizer/message/view"><button className="btn "  title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																		</td>
																	) : column.id === 'email' ? (
																		<td>
																			<a href={`mailto:${value}`}>
																				{value}
																			</a>
																		</td>
																	) :
																		(
																		<td key={column.id} align={column.align}>
																			{column.format && typeof value === 'number' ? column.format(value) : value}
																		</td>
																	)}
																</>
															);
														})}

													</tr>
												);
											})}
										</tbody>
									</Table>
								</Table>
								<Pagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={sentrows.length}
									rowsPerPage={sentrowsPerPage}
									page={sentpage}
									onPageChange={handleChangesentPage}
									onRowsPerPageChange={handleChangesentRowsPerPage}
								/>
							
						</Tab>
						<Tab eventKey="favourite" title="Favourite">
							
								<Table className='common-table'>
									<Table stickyHeader aria-label="sticky table">
										<thead>
											<tr>
												{favouritecolumns?.map((column) => (
													<td
														key={column.id}
														align={column.align}
														style={{ minWidth: column.minWidth }}
													>
														{column.label}
													</td>
												))}
											</tr>
										</thead>
										<tbody>
											{favouriterows.slice(favouritepage * favouriterowsPerPage, favouritepage * favouriterowsPerPage + favouriterowsPerPage)?.map((row) => {
												return (
													<tr hover role="checkbox" tabIndex={-1} key={'1'}>
														{favouritecolumns?.map((column) => {
															const value = row[column.id];
															return (
																<>
																	{column.id === 'action' ? (
																		<td>
																			<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																			<Link to="/organizer/message/reply"><button className="btn " title="Reply"> <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																			<Link to="/organizer/message/view"><button className="btn " title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																		</td>
																	) :
																		column.id === 'email' ? (
																			<td>
																				<a href={`mailto:${value}`}>
																					{value}
																				</a>
																			</td>
																		):(
																		<td key={column.id} align={column.align}>
																			{column.format && typeof value === 'number' ? column.format(value) : value}
																		</td>
																	)}
																</>
															);
														})}

													</tr>
												);
											})}
										</tbody>
									</Table>
								</Table>
								<Pagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={favouriterows.length}
									rowsPerPage={favouriterowsPerPage}
									page={favouritepage}
									onPageChange={handleChangefavouritePage}
									onRowsPerPageChange={handleChangefavouriteRowsPerPage}
								/>
							
						</Tab>
					</Tabs>
				</div>
				{isFilter === true ?
					<>
						<div className="popup-overlay show"></div>
						<div id="filter-pop" className='show'>
							<div className="filter-pop-inner">
								<div className="filter-head">
									<h4>Filters</h4>
									<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
								</div>
								<div className="filter-body">
									<div className="mb-4">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
											<input type="text" className="form-control" placeholder="Type here..." />
										</div>
									</div>
									<div className="row mb-4">
										<div className="col-md-6">
											<div className="input-icon">
												<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
												<input type="text" className="form-control" placeholder="Start Date" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="input-icon">
												<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
												<input type="text" className="form-control" placeholder="End Date" />
											</div>
										</div>
									</div>
								</div>
								<div className="filter-form-btns">
								
								</div>
							</div>
						</div>
					</> : <>
					</>
				}
			</div>	
		</>
	);
};

export default Messages;