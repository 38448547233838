import React from "react";
import { Route } from "react-router-dom";
import UpgradeMembership from '../pages/Organizer/profileSetting/upgrade';
import Messages from '../pages/Organizer/Messages';
import EditMessage from '../pages/Organizer/Messages/edit';
import ViewMessage from '../pages/Organizer/Messages/view';
import Event from '../pages/Organizer/Event';
import Player from '../pages/Organizer/Player';
import Team from '../pages/Organizer/Team';
import Venue from '../pages/Organizer/Venue';
import AddVenue from '../pages/Organizer/Venue/add';
import EditVenue from '../pages/Organizer/Venue/edit';
import Registration from '../pages/Organizer/Registration';
import CreateTorunament from '../pages/Organizer/Event/CreateTournament';
import EnrollTeam from '../pages/Organizer/Event/EnrollTeam';
import EditOrganization from '../pages/Organizer/Organization/edit';
import CreatePlayer from '../pages/Organizer/Player/createPlayer';
import AddPlayerTeam from '../pages/Organizer/Team/AddPlayerTeam';
import ViewTeam from '../pages/Organizer/Team/viewTeam';
import EditPlayer from '../pages/Organizer/Player/edit';
import ViewPlayer from '../pages/Organizer/Player/PlayerDetails';
import Contents from '../pages/Organizer/Contents';
import EditContents from '../pages/Organizer/Contents/edit';
import CreateContents from '../pages/Organizer/Contents/create';
import ViewContents from '../pages/Organizer/Contents/view';
import Dashboard from '../pages/Organizer/Dashboard/index';
import EditAccount from '../pages/Organizer/Profileaccount/edit';
import CreateMessage from '../pages/Organizer/Messages/create';
import ReplyMessage from '../pages/Organizer/Messages/reply';
import Notification from '../pages/Organizer/Notifications';
import Referee from '../pages/Organizer/Referee';
import Reports from '../pages/Organizer/Reports';
import { PageNotFound } from "./main";
import MyForm from "../pages/Organizer/Timeslots/Index";
import Seasons from "../pages/Organizer/Seasons";
import TeamComponent from "../pages/Organizer/Team/TeamComponent";
import PaymentHistory from '../pages/Organizer/Payment-History/payment-history';
import PaymentHistoryDetail from '../pages/Organizer/Payment-History/payment-history-detail';
import SeasonDashboard from '../pages/Organizer/Seasons/dashboard';
import "bootstrap/dist/css/bootstrap.min.css";
import Completion from "../pages/Payment/Complete";


export default [
  <>
    <Route forceRefresh={true} path='/organizer/dashboard' element={<Dashboard />} />
    <Route path='/organizer/profile-setting' element={<EditOrganization />} />
    <Route path='/organizer/profile-membership' element={<UpgradeMembership />} />
    <Route path='/organizer/message' element={<Messages />} />
    <Route path='/organizer/message/edit' element={<EditMessage />} />
    <Route path='/organizer/message/view' element={<ViewMessage />} />
    <Route path='/organizer/:sportId/events/:type' element={<Event />} />
    <Route path='/organizer/:sportId/events/:type/:gameId' element={<Seasons />} />
    <Route path='/organizer/:sportId/events/:type/:gameId/:seasonId' element={<EnrollTeam />} />
    <Route path='/organizer/:sportId/events/:type/:gameId/:seasonId/:groupId' element={<TeamComponent/>} />
    <Route path='/organizer/:sportId/players' element={<Player />} />
    <Route path='/organizer/:sportId/teams' element={<Team />} />
    <Route path='/organizer/:sportId/teams/registeration-player' element={<AddPlayerTeam />} />
    <Route path='/organizer/:sportId/teams/view-team' element={<ViewTeam />} />
    <Route path='/organizer/:sportId/venues' element={<Venue />} />
    <Route path='/organizer/:sportId/venues/add' element={<AddVenue />} />
    <Route path='/organizer/:sportId/venues/edit' element={<EditVenue />} />
    <Route path='/organizer/registrations' element={<Registration />} />
    <Route path='/organizer/:sportId/events/create-tournament' element={<CreateTorunament />} />
    <Route path='/organizer/edit-organization' element={<EditOrganization />} />
    <Route path='/organizer/:sportId/new-player' element={<CreatePlayer />} />
    <Route path='/organizer/:sportId/player/edit-player' element={<EditPlayer />} />
    <Route path='/organizer/player/view-player' element={<ViewPlayer />} />
    <Route path='/organizer/contents' element={<Contents />} />
    <Route path='/organizer/contents/create' element={<CreateContents />} />
    <Route path='/organizer/contents/edit' element={<EditContents />} />
    <Route path='/organizer/contents/view' element={<ViewContents />} />
    <Route path='/organizer/profileaccount/edit' element={<EditAccount />} />
    <Route path='/organizer/message/create' element={<CreateMessage />} />
    <Route path='/organizer/message/reply' element={<ReplyMessage />} />
    <Route  path='/organizer/notification' element={<Notification/>}/>
    <Route path='/organizer/:sportId/members' element={<Referee />} />
    <Route path='/organizer/:sportId/reports' element={<Reports />} />
    <Route path='*' element={<PageNotFound path='organizer/dashboard'/>}/>
    <Route path='/organizer/:sportId/slots/AddtimeSlots' element={<MyForm/>}/>
    <Route path='/organizer/:sportId/payment-history' element={<PaymentHistory/>}/>
    <Route path='organizer/payment-Success' element={<Completion/>}/>
    <Route path='/organizer/:sportId/payment-detail/:id' element={<PaymentHistoryDetail/>}/>
    <Route path='/organizer/league/season' element={<SeasonDashboard/>}/>
  </>
];



