import Modal from 'react-bootstrap/Modal';

function CustomModal({ showModal, onClose, componentToRender ,title }) {
  return (
    // <div className='create_tournament_modal'>
       <Modal show={showModal} onHide={onClose}  className="create_tournament_modal">
      <Modal.Body >
      <button type="button" className="btn-close" onClick={onClose}></button>
         <Modal.Title>{title}</Modal.Title>
        {componentToRender}
      </Modal.Body>
        
    </Modal>

    // </div>
   
  );
}

  export default CustomModal;