import React, { useState, useEffect } from 'react';
import optionico from '../../../assets/images/options.png';
import edit from '../../../assets/images/edit.png';
import deletes from '../../../assets/images/delete.png';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ApiGetAuth } from '../../../helpers/apiCall';
import AddVenue from './add'
import EditVenue from './edit';
import DeletePopup from '../../../components/DeletePopup';
import Cardloader from '../../Mainloader.js/Cardloader';
import withLoader from '../../../components/PageLoader';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';
import { NavLink } from 'react-router-dom';
import {generateImage} from '../../../helpers/helperfunctions'
import { useSelector } from 'react-redux';

const VenuewithLoader = () => {
	const [showModal, setShowModal] = useState(false);
	const [title, setTitle] = useState('')
	const [rows, setRows] = useState(null)
	const [venue_id, setVenueid] = useState('')
	const navigate = useNavigate();
	const { sportId } = useParams();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState([])
	const [search, setSearch] = useState('')
	const [modelType , setModelType ] = useState('')

	const closeModal = () => {
		setShowModal(false);
	};
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [contentToRender, setContentToRender] = useState(<AddVenue closeModal={closeModal} />)
	const [refresh, setRefresh] = useState(false)
	// tab1
	const closeDeleteModal = () => {
		setVenueid(0)
		setShowDeleteModal(false);
	};
	const openDeleteModal = (id, type) => {
		setModelType(type)
		setVenueid(id)
		setShowDeleteModal(true);
	};

	const openModal = (value) => {
		console.log(value.id, 'value')
		if (!value) {
			setContentToRender(<AddVenue closeModal={closeModal} setRefresh={setRefresh} />)
			setShowModal(true);
		}
		else {
			setContentToRender(<EditVenue closeModal={closeModal} value={value} setRefresh={setRefresh} />)
		}
		setShowModal(true);
	}
	const getVideoTutorial = () => {
		ApiGetAuth(`dashboard/get-tutorial-videos`).then(
			(data) => {
				if (data?.data) {
					let temp = data.data.filter(item => item.type == 'venue')
					setValue(temp);
				}
			}
		);
	}



	function CustomModal({ showModal, onClose, componentToRender }) {
		return (
			<Modal className='modal-dialog-centered modal-lg' show={showModal} onHide={onClose}>
				<Modal.Body>
					<button type="button" className="btn-close" onClick={onClose}></button>
					<Modal.Title>{title}</Modal.Title>
					{componentToRender}
				</Modal.Body>

			</Modal>
		);
	}
	useEffect(() => {
		setLoading(true);
		getVideoTutorial()
		setRows(null)
		ApiGetAuth(`venue/venue-field-list?id=${sportId}`).then((data) => {
			if (data.data) {
				setRows(data.data)
			}
			setLoading(false);
			return;
		}, (err) => {
			setLoading(false);
			return;
		})
	}, [sportId, refresh]);

	let filteredVenues = (result) => {
		if (search) {
			return result?.filter(item => item.name.toLowerCase().startsWith(search.toLowerCase()))
		}
		return result
	};



	const sports = useSelector(state=>state.SPORTSLIST)
	return (
		<>

			<nav aria-label="breadcrumb" className="breadcrumb_dashboard">
				<ol className="breadcrumb">
				    <li className="breadcrumb-item">
						<NavLink to='/organizer/dashboard' role="button" tabIndex="0">Dashboard</NavLink>
					</li>
					<li className="breadcrumb-item">
                      <NavLink to={`/organizer/${sportId}/events/All-Games`} role="button" tabIndex="0">{sports?.filter(item=>item.id ==sportId)[0]?.sports_name}</NavLink>
                   </li>
					<li className="breadcrumb-item active" aria-current="page">
						<NavLink to="/organizer/dashboard">Venues</NavLink>
					</li>
					<PlayModal url={value.length ? value[0].video_link : ''} data={value} />
				</ol>
			</nav>


			<div className="organizer_events mt-5">
				<DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={rows} setValues={setRows} endpoint={`/venue/delete-venue/${venue_id}`} id={venue_id}  modelType ={modelType}/>
				<CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
				<div className='search-teams venue-top  mt-5'>
					<div className="serach-properties d-flex">
						<div className='heading-view '>
							<div className="team-top-fil">
								<div className='serach-properties'>
									<div className="team-search ">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
											<path d="M9.19668 0.839844C9.83401 0.92695 10.4665 1.03732 11.0735 1.25833C13.1246 2.00522 14.6622 3.33914 15.6601 5.28522C16.1773 6.29398 16.4576 7.36441 16.5082 8.49868C16.5969 10.4937 16.0277 12.2775 14.8172 13.8611C14.6427 14.0894 14.6435 14.0878 14.8383 14.2901C16.2993 15.8072 17.7596 17.3248 19.2204 18.8418C19.4587 19.0891 19.6664 19.3485 19.671 19.7186C19.6764 20.1641 19.4914 20.502 19.0989 20.7057C18.7002 20.9126 18.3006 20.8823 17.9479 20.5996C17.7463 20.4381 17.5724 20.2407 17.3925 20.0535C16.0053 18.61 14.6178 17.1668 13.2366 15.7177C13.1045 15.5791 13.0115 15.5575 12.8476 15.6684C11.8623 16.3347 10.7716 16.7418 9.59785 16.9076C8.00208 17.1327 6.46204 16.9252 5.00693 16.2132C2.5915 15.0316 1.07391 13.1221 0.518813 10.4954C0.0981614 8.50517 0.422239 6.60399 1.45723 4.84455C2.5658 2.96014 4.18836 1.72605 6.28269 1.10901C6.73796 0.974831 7.20785 0.912612 7.67557 0.839844H9.19695H9.19668ZM8.42084 14.8336C11.6586 14.8425 14.347 12.2502 14.3684 8.94449C14.3895 5.68775 11.8212 2.98124 8.44627 2.97313C5.15707 2.96528 2.5209 5.56954 2.509 8.89065C2.49709 12.2345 5.19792 14.8244 8.42111 14.8336H8.42084Z" fill="#F05829" />
										</svg>
										<input type="text" className="form-control " onChange={(e) => { setSearch(e.target.value) }} id="exampleFormControlInput1" placeholder="Search" />
										<div className="s-icon"></div>
									</div>
								</div>
							</div>
						</div>
						<div className='wrap-create-button venuw-wrap-create-button d-flex'>
							<button className="btn btn-dark green orange-color" onClick={() => openModal('')} > Create Venue</button>
						</div>
					</div>
				</div>


				<div className='row pb-5 mb-3  venuee  create-cards'>
					{
						rows === null ? <Cardloader content={"Venue"} /> :

							<>
								{filteredVenues(rows)?.length ? <>
									{filteredVenues(rows)?.map(
										item => {
											return <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col=sm-12'> <div className='l-card season-mange team-card-details venue-card'>
												<div className='wrap'>
													<div className='l-title img_create_team'>

														<img alt="" src={item?.venue_Image ? item?.venue_Image : generateImage(item.name)} width='100px' />
														<div className='card-content'>
															<p>{item.name}</p>
															<div className="text-group-color">
																<p><strong>Zip Code: </strong>{item.pincode}</p>
															</div>
														</div>

														<div className="dropdown acts team_component">
															<button className=" dropdown-toggle actions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
																<img className='dots team_img' src={optionico} />
															</button>
															<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
																<li onClick={() => { openModal({ id: item }) }}><a className="dropdown-item"><img src={edit} className="team_img" />Edit</a></li>
																<li onClick={() => openDeleteModal(item.id,'Archive')}><a className="dropdown-item"><img src='https://cdn.icon-icons.com/icons2/1744/PNG/512/3643772-archive-archives-document-folder-open_113445.png' alt='Archive icon' />Archive</a></li>
																<li onClick={() => openDeleteModal(item.id, 'Delete')}><a className="dropdown-item"><img src={deletes} className="team_img" />Delete</a></li>

															</ul>
														</div>
													</div>
													<div className='groups-count  team-list team-views-bottom'>
														<div className='group total-groups'>
															<p>Games</p>
															<strong>{item?.match_count}</strong>
														</div>
														<div className='group total-groups'>
															<p>Fields</p>
															<strong>{item?.fields?.length || 0}</strong>
														</div>
														<div className='group total-groups'>
															{/* <p>Time Slots</p> */}
															<Link to={`/organizer/${sportId}/slots/AddtimeSlots`} state={{ id: item.id, sportsId: sportId }} >
																<button type="button" className="btn btn-outline-primary  pay-btn">Add Time Slot</button>
															</Link>
														</div>

													</div>
												</div>
											</div>
											</div>
										})}

								</> : <> <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col=sm-12'><div className='l-card create-league'>
									<div className='wrap'>
										<span> No Data Found</span>
									</div>
									</div>

								</div></>
								}
							</>
					}


				</div>
			</div>

		</>
	);
};

const Venue = withLoader(VenuewithLoader)
export default Venue;