import React from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { TopNav } from '../../components/TopNav';

const DashboardNewComponent = ({ setCollapsed, collapsed}) => {
  return (
    <>
        <div className="innerDashboard">
            <TopNav setCollapsed={setCollapsed}  />
            <div className="dashboard-inner">
                <div className='container-fluid'>
                    <Outlet/>
                </div>
            </div>
            {/* <footer><div className='copy-right'>© 2023 | All rights reserved by GIU - Organiser Panel</div></footer> */}
        </div>
    </>
  )
}

export default DashboardNewComponent;