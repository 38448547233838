import React, { useEffect, useState } from 'react';
import CreateTournament from '../Organizer/Event/CreateTournament';
import CreateLeagueNew from '../Organizer/Event/CreateLeagueNew';
import { ApiGetAuth } from '../../helpers/apiCall';
import { Link, useLocation, useParams } from 'react-router-dom';
import optionico from '../../assets/images/options.png';
import edit from '../../assets/images/edit.png';
import deletes from '../../assets/images/delete.png';
import DeletePopup from '../../components/DeletePopup';
import Cardloader from '../Mainloader.js/Cardloader';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../components/customModal';
import tournamentimg from '../../assets/images/tournament-img.png';
import leaguimg from '../../assets/images/league-img.png';



export const TournamentLeagueIcon = ({game_type, width=30})=>{
  return <>   {game_type === 'Tournament' ?
    <img src={tournamentimg} className='trophy_img' alt='Tournament / icon missing ' width={width} />
    :
    <img src={leaguimg} className='trophy_img' alt='League / icon missing '  width={width}/>

  }</>
}




const Leagues = () => {
  const { sportId, type } = useParams();
  const [modelType , setModelType] = useState('')
  const [selectedType, SetSelectedType] = useState(type == "Tournament" ? 1 : 2)
  const [showModal, setShowModal] = useState(false);
  const [gameList, setGameList] = useState(null)
  const [title, setTitle] = useState('')
  const [contentToRender, setContentToRender] = useState(<></>)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tournament_id, setTournament_id] = useState('')
  const [search, setSearch] = useState('')
  const [deleteTitle, setDeleteTitle] = useState('')
  const state = useLocation()?.state || { type_games: '' }
  function createData(title, game_type, game_bracket_type, total_teams, id, status, roundnumber, status_id, season) {
    return { title, game_type, game_bracket_type, total_teams, id, status, roundnumber, status_id, season };
  }
  const navigate = useNavigate();


  const getGames = () => {
    ApiGetAuth(`games/get-games?id=${sportId}`).then(
      (success) => {
        let temp = []
        if (success.data) {
          success.data?.map(item => {
            temp.push(createData(item.game_title, item.game_type_id === 1 ? 'Tournament' : "Leagues", (item.game_bracket_id === 3 ? 'Round Robin' : item.game_bracket_id == 1 ? "Single Elimination" : "Double Elimination"), item.total_teams, item.id, item.status, item.robin_number, item.status_id, item.season))
          })
        }
        if (type == 'All-Games') {
          setGameList(temp)
        } else {
          let temp1 = temp.filter((fil => fil.game_type == type))
          setGameList(temp1)
        }
        // setLoading(false);
        return;
      }, (err) => {
        // setLoading(false);
        return;
      }
    )
  }

  const closeModal = () => {
    setShowModal(false);
  };


  const openModal = (value, type) => {
    if (!value) {
      setTitle(type == 1 ? "Create Tournament" : "Create League")
      if (type == 1) setContentToRender(<CreateTournament closeModal={closeModal} getGames={getGames} />)
      else setContentToRender(<CreateLeagueNew closeModal={closeModal} getGames={getGames} />)
      setShowModal(true);
    } else if (value) {
      setTitle(type == 2 ? "Update League" : "Update Tournament")
      if (type == 2) {
        setContentToRender(<CreateLeagueNew closeModal={closeModal} tournament_id={value.id} getGames={getGames} />)
      } else {
        setContentToRender(<CreateTournament closeModal={closeModal} tournament_id={value.id} getGames={getGames} />)
      }
      setShowModal(true);
    }
  };


  const closeDeleteModal = () => {
    setTournament_id(0)
    setShowDeleteModal(false);
  };
  const openDeleteModal = (id, title,modelType) => {
    setTournament_id(id)
    setModelType(modelType)
    setDeleteTitle(title)
    setShowDeleteModal(true);
  };


  useEffect(() => {
    if (selectedType) {
      setTitle(selectedType == 1 ? "Create Tournament" : "Create League")
      if (selectedType == 1) setContentToRender(<CreateTournament closeModal={closeModal} getGames={getGames} />)
      else setContentToRender(<CreateLeagueNew closeModal={closeModal} getGames={getGames} />)
    }
  }, [selectedType,type])

  useEffect(() => {
    SetSelectedType(type == "Tournament" ? 1 : 2)
    if(type != 'All-Games' && gameList !== null && gameList?.length == 0 ) openModal('', type == "Tournament" ? 1 : 2)
    getGames()

    if (state?.type_games == 'Create') {
      openModal('', type == "Tournament" ? 1 : 2)
    }
  }, [sportId, type])

  let filteredGame = () => {
    let result = gameList;
    if (search) {
      result = gameList?.filter(item => item.title?.toLowerCase()?.startsWith(search?.toLowerCase()));
    }
    return result;
  };
  
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    navigate(`/organizer/${sportId}/events/${selectedValue}`);
  };


  return (
    <>
      <DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={gameList} setValues={setGameList} endpoint={'/games/remove-game'} id={tournament_id} title={deleteTitle} modelType={modelType} />
      <CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} title={title} />
      <div className='search-teams '>
        <div className="serach-properties d-flex">
          <div className='heading-view '>
            <div className="team-top-fil">

              <div className='serach-properties fixselect-box'>
                <div className="team-search ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M9.19668 0.839844C9.83401 0.92695 10.4665 1.03732 11.0735 1.25833C13.1246 2.00522 14.6622 3.33914 15.6601 5.28522C16.1773 6.29398 16.4576 7.36441 16.5082 8.49868C16.5969 10.4937 16.0277 12.2775 14.8172 13.8611C14.6427 14.0894 14.6435 14.0878 14.8383 14.2901C16.2993 15.8072 17.7596 17.3248 19.2204 18.8418C19.4587 19.0891 19.6664 19.3485 19.671 19.7186C19.6764 20.1641 19.4914 20.502 19.0989 20.7057C18.7002 20.9126 18.3006 20.8823 17.9479 20.5996C17.7463 20.4381 17.5724 20.2407 17.3925 20.0535C16.0053 18.61 14.6178 17.1668 13.2366 15.7177C13.1045 15.5791 13.0115 15.5575 12.8476 15.6684C11.8623 16.3347 10.7716 16.7418 9.59785 16.9076C8.00208 17.1327 6.46204 16.9252 5.00693 16.2132C2.5915 15.0316 1.07391 13.1221 0.518813 10.4954C0.0981614 8.50517 0.422239 6.60399 1.45723 4.84455C2.5658 2.96014 4.18836 1.72605 6.28269 1.10901C6.73796 0.974831 7.20785 0.912612 7.67557 0.839844H9.19695H9.19668ZM8.42084 14.8336C11.6586 14.8425 14.347 12.2502 14.3684 8.94449C14.3895 5.68775 11.8212 2.98124 8.44627 2.97313C5.15707 2.96528 2.5209 5.56954 2.509 8.89065C2.49709 12.2345 5.19792 14.8244 8.42111 14.8336H8.42084Z" fill="#F05829" />
                  </svg>
                  <input type="text" className="form-control " id="exampleFormControlInput1" onChange={(e) => { setSearch(e.target.value) }} placeholder="Search Tournament/League" />
                  <div className="s-icon"></div>
                </div>

                <div className='back-btn-dashboard'>
                  <Link onClick={() => navigate(-1)}> <span className='back'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="32" height="32" rx="6" fill="#F05829" />
                      <path d="M10.947 17.2243C10.9863 17.3879 11.0873 17.4465 11.1619 17.5219C12.2375 18.6007 13.3139 19.6787 14.3936 20.7535C14.6911 21.0495 14.882 21.3831 14.8572 21.8179C14.8299 22.2903 14.6077 22.6384 14.193 22.8526C13.7776 23.0667 13.3597 23.0475 12.9642 22.7956C12.8391 22.7154 12.7268 22.6111 12.6209 22.5052C11.0039 20.8906 9.38692 19.2752 7.77231 17.6582C6.74484 16.6283 6.74243 15.3699 7.7659 14.344C9.39414 12.7125 11.024 11.0819 12.6562 9.45447C13.257 8.85611 14.0607 8.84969 14.5628 9.4288C15.0023 9.93572 14.9582 10.6672 14.4401 11.1894C13.3532 12.285 12.2592 13.3743 11.1684 14.4667C11.0914 14.5437 11.0184 14.6239 10.9317 14.7162C11.0585 14.806 11.1812 14.7715 11.2935 14.7715C15.4226 14.7739 19.5526 14.7739 23.6817 14.7723C24.1694 14.7723 24.5311 14.9865 24.7638 15.4052C24.9972 15.8263 24.9803 16.2554 24.7245 16.6604C24.467 17.0671 24.0852 17.2291 23.6023 17.2283C19.5133 17.2219 15.4234 17.2251 11.3344 17.2251H10.9462L10.947 17.2243Z" fill="white" />
                    </svg>
                    <span className='btn-back-to-view'>  Back</span>
                  </span></Link>
                </div>
                <select value={type} onChange={handleChange} className="form-select" aria-label="Default select example">
                  <option value='All-Games'> All Events </option>
                  <option value='Tournament' >Tournaments</option>
                  <option value='Leagues' > League</option>
                </select>
              </div>
             
            </div>
          </div>
         
          <div className='wrap-create-button'>
            <button className="btn btn-dark green orange-color" onClick={() => openModal('', selectedType)}> Create Tournament/League</button>
          </div>
        </div>
      </div>

      <div className='row create-cards '>
      

          {gameList == null ? <Cardloader content={"Leagues/Tournament"} /> :
            <>
              {gameList?.length ? <>
                {filteredGame()?.map((item, i) => {
                  
                  return  <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                        <div key={i} className='l-card new_tournament_design'>
                    <div className='wrap'>
                      <Link to={`/organizer/${sportId}/events/${type}/${item.id}`} className="tournament_card_redesign" >
                        <div className='parties'>
                          <div className='l-title'>
                         <TournamentLeagueIcon game_type={item.game_type} />
                            {/* Tournament SVG */}

                            <Link to={`/organizer/${sportId}/events/${type}/${item.id}`} className="tournament_card_redesign" >
                              <div className='card-content'>

                                <h6>{item.title}</h6>
                              </div>
                              <div className='l-status mx-3'>
                                <p className='mb-0'>{item.game_type == 'Tournament' ? item.game_bracket_type : 'Round Robin'}</p>
                              </div>
                            </Link>
                          </div>
                          <div className='seasons-bottom'>
                            <div className='date mt-2 date-card  '>
                            </div>
                            <div className='date mt-1 date-card '>
                              <p><strong>Season: </strong>{item.season}</p>
                            </div>
                          </div>

                        </div>
                      </Link>
                      <div className="dropdown acts">
                        <button className=" dropdown-toggle actions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <img className='dots' src={optionico} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <a className="dropdown-item"> <li onClick={() => { openModal({ id: item.id, game_type: item.game_type }, item.game_type == 'Tournament' ? 1 : 2) }}><img src={edit} />Edit</li></a>
                          <a className="dropdown-item"> <li onClick={() => openDeleteModal(item.id, item.title, 'Archive')}><img src='https://cdn.icon-icons.com/icons2/1744/PNG/512/3643772-archive-archives-document-folder-open_113445.png' alt='Archive icon' />Archive</li></a>
                          <a className="dropdown-item"> <li onClick={() => openDeleteModal(item.id, item.title, "Delete")}><img src={deletes} alt='Delete icon' />Delete</li></a>

                        </ul>
                      </div>


                    </div>

                  </div>

                  </div>

                })}

              </> : <>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col=sm-12'>

                <div className='l-card create-league'>
                  <div className='wrap'>
                    <span> No Data Found</span>
                  </div>

                </div>
                </div>

              </>

              }

            </>
          }

        </div>
      
    </>
  );

};

export { Leagues };