import React, { useState } from 'react';
import { Container, Card, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { Apipost } from '../../helpers/apiCall';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';

const ScheduleCard = ({ venue, date, duration, slots, isFound }) => {
  // State for toggle
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle function
  const toggleCard = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card className="mb-4">
      <Card.Header>
        <div className='d-flex align-item-center justify-content-between'>
          <div>
            <h6>
              {venue}
              <span className='mx-2'>
                {isFound ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                  </svg>
                ) : null}
              </span>
            </h6>
            <medium style={{ fontSize: '14px' }}>{date}</medium>
          </div>

          <div>
            <div onClick={toggleCard} className="float-right">
              {isExpanded ? (
                // Custom Arrow Down Icon
                <svg
                  className="svg-icon"
                  style={{
                    width: '16px',
                    height: '16px',
                    verticalAlign: 'middle',
                    fill: '#f1582a',
                    overflow: 'hidden',
                  }}
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M948.560332 281.179984c-13.765515-13.833053-36.127825-13.833053-49.89334 0L511.991302 668.591431 125.313565 281.179984c-13.763468-13.798261-36.093033-13.798261-49.856501 0-13.799284 13.798261-13.799284 36.161594 0 49.993624l410.857439 411.674037c7.067976 7.085372 16.402575 10.521634 25.675776 10.331299 9.274224 0.191358 18.608823-3.245927 25.677822-10.331299l410.891208-411.708829c6.863315-6.89913 10.331299-15.940041 10.331299-24.979928S955.423647 288.078091 948.560332 281.179984z" />
                </svg>
              ) : (
                // Custom Arrow Right Icon
                <svg
                  className="svg-icon"
                  style={{
                    width: '16px',
                    height: '16px',
                    verticalAlign: 'middle',
                    fill: '#f1582a',
                    overflow: 'hidden',
                  }}
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M595.7 511.1L240.8 866l93.2 93.1 447.4-447.4-0.6-0.6 0.6-0.5L334 63.1l-93.2 93.2z" />
                </svg>
              )}
            </div>



          </div>
        </div>
      </Card.Header>
      {isExpanded && (
        <Card.Body>
          <Row>
            <Col>
              <strong style={{ color: '#f1582a' }}>Duration:</strong> {duration}
            </Col>
            <Col>
              <strong style={{ color: '#f1582a' }}>Slots:</strong> {slots.length}
            </Col>
          </Row>

          <ListGroup variant="flush" className="mt-3">
          <div className='d-flex align-item-center justify-content-between'>
      <div> <strong style={{ fontWeight: '400px;'}} > Time:</strong></div>
      <div> <strong style={{ fontWeight: '400px;'}} > Field</strong></div>
    </div>
            {slots.map((slot, index) => (
              <ListGroup.Item key={index} style={{ padding: '8px 0px' }}>
    


                <div className='d-flex align-item-center justify-content-between'>
                  <div> {slot.Time} </div>



                  <div>   {slot.Field} </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      )}
    </Card>
  );
};

const EventSchedule = ({ eventData, venueList, setRefresh }) => {
  const { sportId } = useParams();
  const [loader, setLoader] = useState(false);
  const handleImport = () => {
    setLoader(true)
    Apipost('/venue/import-timeslots', { eventData, sportId }, 'application/x-www-form-urlencoded', true).then(
      (response) => {
        if (response.data.status) {
          successMessageNodify(response.data.message)
          setLoader(false)
          setRefresh(prev => !prev)
          // Handle success
        } else {
          errorMessageNodify("")
          setLoader(false)
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  return (
    <Container className="mt-4">
      <div style={{ overflowY: 'scroll', height: '550px' }}>
        {eventData?.map((event, index) => {
          const isFound = venueList.filter(item => item.name?.trim().toLowerCase() === event.Venue?.trim().toLowerCase());
          event.venue_id = isFound[0]?.id || 0;
          return (
            <ScheduleCard
              key={index}
              isFound={isFound.length > 0}
              venue={event.Venue}
              date={event.Date}
              duration={event.Duration}
              slots={event.slots} // Now contains the slots with fields
            />
          );
        })}
      </div>
      <Button onClick={handleImport} disabled={loader || !eventData.length}  >{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Import Slots"}</Button>
    </Container>
  );
};

export default EventSchedule;
