import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import {
  errorMessageNodify,
  successMessageNodify,
} from "../../../helpers/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "react-confirm-alert/src/react-confirm-alert.css";
import { State, City } from "country-state-city";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../assets/css/payment.css";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  game_title: Yup.string().required("Please Enter Tournament Name").max(30, 'Tournament name cannot be longer than 30 characters'),
  game_bracket_id: Yup.number().required("Please Enter Tournament Type"),
  selected_State: Yup.object().required("Please Enter State"),
  selected_City: Yup.object().required("Please Enter City"),
  About: Yup.string(),
});
const CreateTournament = ({
  closeModal,
  tournament_id = 0,
  getGames = () => {},
  sports = "",
}) => {
  const navigate = useNavigate();
  const { sportId, type } = useParams();
  const formik = useFormik({
    initialValues: {
      id: 0,
      game_title: "",
      game_bracket_id: 0,
      robin_number: 0,
      selected_State: null,
      selected_City: null,
      About: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = () => {
    const payload = {
      sportId: sportId || sports,
      game_type_id: 1,
      game_title: formik.values.game_title,
      game_bracket_id: formik.values.game_bracket_id,
      robin_number: formik.values.game_bracket_id === 3 ? formik.values.robin_number : 0,
      selected_State: JSON.stringify(formik.values.selected_State),
      selected_City: JSON.stringify(formik.values.selected_City),
      About: formik.values.About,
      id:tournament_id ||0
    };

    const endpoint = tournament_id ? "/games/update-game" : "/games/add-game";
    
    formik.setSubmitting(true);

    Apipost(endpoint, payload, "application/x-www-form-urlencoded", true).then(
      (response) => {
        const result = response.data;
        if (result.status === true) {
          if(tournament_id == 0 ){
            navigate(`/organizer/${sportId}/events/${type}/${result.data.id}`)
           }
          successMessageNodify(result.msg);
          closeModal(false);
          getGames();
        } else {
          errorMessageNodify(result.msg);
        }
        formik.setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    if (tournament_id) {
      ApiGetAuth(`games/game-details/${tournament_id}`).then((data) => {
        if (data.data) {
          if (data.data.About === "") data.data.About = "<p></p>";
          formik.setValues({
            ...data.data,
            selected_State: data.data.selected_State,
            selected_City: data.data.selected_City,
            id:tournament_id
          });
          if (data.data.game_bracket_id === 3) {
            formik.setFieldValue("robin_number", data.data.robin_number);
          }
        }
      });
    } else {
      formik.setValues({
        ...formik.values,
        sportId: sportId || sports,
        game_type_id: 1,
      });
    }
  }, [tournament_id]);

  const AddroundRobin = (value) => {
    const selectedValue = value;
  
    // Convert the selected value to a number
    const bracketId = parseInt(selectedValue, 10);
  
    formik.setFieldValue("game_bracket_id", bracketId);
  
    if (bracketId === 3) {
      formik.setFieldValue("robin_number", 0);
    }
  };

  const titleBasic= <span>Basic <span className="red">*</span></span>;
  
  return (
    <>
    <Formik>
      <div className="col-sm-12 season-popup">
        <div className="create-form-inner newCreateLeague">
          <Tabs
            id="controlled-tab-example"
            activeKey={formik.values.key}
            onSelect={(k) => formik.setFieldValue("key", k)}
            className="mb-3"
          >
            <Tab eventKey="home" title={titleBasic}>
              <div className="form_submit_basketball">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <div className="d-flex">
                        <label className="form-label">Tournament Name</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <input
                    maxLength={30}
                          className={`form-control ${
                            formik.touched.game_title && formik.errors.game_title ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Tournament"
                          name="game_title"
                          value={formik.values.game_title}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.game_title && formik.errors.game_title && (
                          <div className="invalid-feedback">{formik.errors.game_title}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <div className="d-flex">
                        <label className="form-label">Tournament Type</label>
                        {/* <span className="imp_ast">*</span> */}
                      </div>
                      <select
                          className={`form-control ${
                            formik.touched.game_bracket_id && formik.errors.game_bracket_id
                              ? "is-invalid"
                              : ""    
                          }`}
                          placeholder="Enter Tournament Type"
                          name="game_bracket_id"
                          value={formik.values.game_bracket_id}
                          onChange={(e) => {
                            formik.handleChange(e);
                            AddroundRobin(e.target.value);
                          }}
                        >
                        <option value={0}>Enter Tournament Type</option>
                        <option value={3}>Round Robin</option>
                        <option value={1}>Single Elimination</option>
                        <option disabled value={2}>Double Elimination</option>
                      </select>
                      {formik.touched.game_bracket_id && formik.errors.game_bracket_id && (
                        <div className="invalid-feedback">{formik.errors.game_bracket_id}</div>
                      )}
                    </div>
                  </div>
                  {formik.values.game_bracket_id === 3 && (
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <div className="d-flex">
                          <label className="form-label">Number Of Robins</label>
                        </div>
                        <input
                          className={`form-control ${
                            formik.touched.robin_number && formik.errors.robin_number
                              ? "is-invalid"
                              : ""
                          }`}
                          type="number"
                          name="robin_number"
                          value={formik.values.robin_number}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.robin_number && formik.errors.robin_number && (
                          <div className="invalid-feedback">{formik.errors.robin_number}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <div className="d-flex">
                        <label className="form-label">State</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <Select
                          options={State?.getStatesOfCountry("US")}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["name"]}
                          value={formik.values.selected_State}
                          onChange={(item) => formik.setFieldValue("selected_State", item)}
                          className={`formm-control ${
                            formik.touched.selected_State && formik.errors.selected_State ? "is-invalid" : ""
                          }`}
                        />
                        {formik.touched.selected_State && formik.errors.selected_State && (
                        <div className="invalid-feedback">{formik.errors.selected_State}</div>
                      )}
                      </div>
                      
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <div className="d-flex">
                        <label className="form-label">City</label>
                        <span className="imp_ast">*</span>
                      </div>
                      <div className="input-right-icon">
                        <Select
                          options={City.getCitiesOfState(
                            formik.values.selected_State?.countryCode,
                            formik.values.selected_State?.isoCode
                          )}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["name"]}
                          value={formik.values?.selected_City}
                          onChange={(item) => formik.setFieldValue("selected_City", item)}
                          className={`formm-control ${
                            formik.touched.selected_City && formik.errors.selected_City ? "is-invalid" : ""
                          }`}
                        />
                        {formik.touched.selected_City && formik.errors.selected_City && (
                        <div className="invalid-feedback">{formik.errors.selected_City}</div>
                      )}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>  
            </Tab>
            <Tab eventKey="profile" title="About">
              <div className="col-md-12 mb-4">
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formik.values.About ? formik.values.About : "<p></p>"}
                    onReady={(editor) => console.log("Editor is ready to use!", editor)}
                    onChange={(event, editor) => formik.setFieldValue("About", editor.getData())}
                    onBlur={(event, editor) => console.log("Blur.", editor)}
                    onFocus={(event, editor) => console.log("Focus.", editor)}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>

          <div className="schedule-1">
            <div className="create-next submission">
              <button
                className="btn btn-dark green add next-orange-btn"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={ formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : tournament_id ? (
                  " Update"
                ) : (
                  " Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      </Formik>
    </>
  );
};

export default CreateTournament;
