import React, { useState } from 'react';
import Papa from 'papaparse';
import './FileUpload.css'; // for some basic styling
import { errorMessageNodify } from '../../helpers/notify';

const FileUpload = ({ onFileUploaded, requiredKeys, text = 'Drag and drop a Games CSV file here or click to upload a CSV file', sampleFileUrl }) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState(''); // State to store selected file name

  const handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Ensure headers are consistently trimmed
        const headers = results.meta.fields.map(header => header?.trim()); // Trim headers
  
        // Check for missing required keys
        const missingKeys = requiredKeys.filter(key => !headers.includes(key.trim()));
  
        if (missingKeys.length > 0) {
          errorMessageNodify(`Missing required fields: ${missingKeys.join(', ')}`);
          onFileUploaded([]); // Send empty array to parent component
          return;
        }
  
        // Trim data and validate fields
        const jsonResult = results.data.map((row) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key.trim(), typeof value === 'string' ? value.trim() : value])
          );
        });
  
        // Check for any empty fields
        const hasEmptyValues = jsonResult.some(row =>
          Object.values(row).some(value => value === '')
        );
  
        if (hasEmptyValues) {
          errorMessageNodify("Please don't leave any field empty.");
          onFileUploaded([]); // Send empty array to parent component
          return;
        }
  
        // Pass cleaned JSON data to the parent component
        onFileUploaded(jsonResult);
      },
      error: function (error) {
        console.error('Error while parsing CSV:', error);
        errorMessageNodify(`Error while parsing CSV: ${error.message}`);
      },
    });
  };
  

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const uploadedFile = e.dataTransfer.files[0];
      setFileName(uploadedFile.name); // Set the uploaded file name
      handleFileUpload(uploadedFile);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const uploadedFile = e.target.files[0];
      setFileName(uploadedFile.name); // Set the uploaded file name
      handleFileUpload(uploadedFile);
    }
  };

  return (
    <div>
      <div
        className={`file-upload-container ${dragActive ? 'drag-active' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          className="file-input"
          accept=".csv"
          onChange={handleFileChange}
        />
        <label htmlFor="fileInput" className="file-input-label">
          <div className="upload-icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="upload-icon"
            >
              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"></path>
              <polyline points="7 9 12 4 17 9"></polyline>
              <line x1="12" y1="4" x2="12" y2="16"></line>
            </svg>
          </div>
          {fileName ? fileName : text} {/* Display file name if present, otherwise default text */}
        </label>
      </div>

      {/* Sample file download */}
      {sampleFileUrl && (
        <div className="sample-file-container text-center mt-2">
          <a href={sampleFileUrl} download className="sample-file-link">
            Download Sample CSV File
          </a>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
