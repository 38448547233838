import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import {Table, Pagination} from 'react-bootstrap';

import FilterIcon from "../../../assets/images/filter-ico.svg";


const Notification = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	// table
	const columns = [
		{ id: 'notification', label: 'Notification', minWidth: 100 },
		{ id: 'organizer', label: 'Organizer', minWidth: 100 },
		{ id: 'sender', label: 'Sender', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'action', label: '', minWidth: 100 },
	];

	function createData(notification, organizer, sender, date) {
		return { notification, organizer, sender, date };
	}

	const rows = [
		createData('Testing as a coach Subscribe to basic plan', 'Wow Club', 'Testing', '04-12-2023'),
		createData('New as a coach Subscribe to basic plan', 'Wow Club', 'NEw', '04-12-2023'),
	];


	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};


	return (
		<>
			<div className="organizer_events">
				<div className="dashboard-top-btn">
					<div className="search">
						<input type="text" className="form-control" placeholder="Search" />
					</div>
					<div className="table-filter">
						<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={FilterIcon} />Filters</button>
					</div>
				</div>
				
					<Table className='common-table'>
						<Table stickyHeader aria-label="sticky table">
							<thead>
								<track>
									{columns?.map((column) => (
										<td
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</td>
									))}
								</track>
							</thead>
							<tbody>
								{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
									return (
										<tr hover role="checkbox" tabIndex={-1} key={'1'}>
											{columns?.map((column) => {
												const value = row[column.id];
												return (
													<>
														{column.id === 'action' ? (
															<td>
																<button className="btn"><FontAwesomeIcon icon={faEye} /></button><button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
															</td>
														) : (
															<td key={column.id} align={column.align}>
																{column.format && typeof value === 'number' ? column.format(value) : value}
															</td>
														)}
													</>


												);
											})}

										</tr>
									);
								})}
							</tbody>
						</Table>
					</Table>
					<Pagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				
			</div>	
		</>
	);
};

export default Notification;