import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EditorState,convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';
import VideoInput from "../../../components/VideoInput";
import draftToHtml from 'draftjs-to-html';
import { objectValuesNonEmpty, onTextChange } from "../../../helpers/commonFunction";

const CreateContents = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
       navigate(fromPage, { replace: true });
    };
    const [values, setValues] = useState({
        title: "",
        content:'',
        video:'',
    });
    const handleEditorChange = (state) => {
        setEditorState(state);
        values.content = sendContent();
    };
    const sendContent = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    
    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {/* <h4>Add Content</h4> */}
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Title</label>
                                    <input  className="form-control" name="title"  onChange={(e) => onTextChange(e, setValues)} />
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={handleEditorChange}
                                        editorStyle={{ height: '200px' }}
                                    />
                                    <span className="error email-error">This field is required*</span>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                  <div className="form-label my-2">Upload Video</div>
                                    <VideoInput width={400} height={300} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/contents"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit"> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateContents;